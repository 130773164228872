import React, { useEffect, useState } from "react";
import PieChart, {
  Legend,
  Series,
  Tooltip,
  Format,
  Label,
  Connector,
  Export,
} from "devextreme-react/pie-chart";
//import { populationByRegions } from './data.js';
import { Col, Row } from "react-bootstrap";
import _ from "lodash";
import { PerformanceServices } from "../PerformanceServices";
import Swal from "sweetalert2";

function customizeTooltip(arg) {
  return {
    text: `${arg.valueText} - ${(arg.percent * 100).toFixed(2)}%`,
  };
}

const InitialState = {
  Idrol: "0",
  position: "",
  IdUser: "",
  start_date: null,
  end_date: null,
};

const PieGraph = ({ type, title, data, series }) => {
  const [dataForPie, setDataForPie] = useState([])

  const palette = ['#CE4009','#DB5E18','#E98B28','#F1B21E']
  const palette2 = ['#DB5E18','#F1B21E']

  const loadDataByFilter = async (users) => {
    const controller = new AbortController();

    try {
      const result = await PerformanceServices.getDataByFilter(InitialState);

      if (result.task.length > 0) {
        const filterTaskNoComplet = result.task.filter((task) => {
          return task.Avance != "Done" || task.PctAvance != 100;
        });

        const resultFilter = users
          ?.map((user) => {
            const userTask = filterTaskNoComplet.filter(
              (task) => task.IdUsuario == user.IdUsuario
            );
            return {
              ...user,
              task: userTask,
            };
          })
          .filter((us) => us.task.length > 0);
          
          const activeUsers = users.filter((us)=> us.Estado == "Activo")?.length
        
          setDataForPie([{
             role : "Con Actividades Asignadas",
             val : resultFilter.length
          },
          {
            role : "Sin Actividades Asignadas",
            val : activeUsers - resultFilter.length  
         }])

      }
    } catch (error) {
      Swal.fire("Error", error, "error");
    }

    return () => {
      controller.abort();
    };
  };

  const resourcesByRol = _.map(_.countBy(_.filter(data,['Estado','Activo']), "Rol"), (val, role) => ({
    role,
    val,
  }));

  useEffect(() => {
    if (data.length > 0 && type == "pie") {
      loadDataByFilter(data);
    }
  }, [data]);

  return (
    <PieChart
      id="pie"
      type={type}
      height="300px"
      title={title}
      palette={type == 'pie'? palette2: palette}
      dataSource={ type == 'pie' ? dataForPie : resourcesByRol}
    >
      <Series argumentField="role">
        <Label visible={true} /* format="millions" */>
          <Connector visible={true} />
        </Label>
      </Series>
      <Export enabled={false} />
      <Legend margin={0} horizontalAlignment="right" verticalAlignment="top" />
      <Tooltip enabled={true} customizeTooltip={customizeTooltip}>
        {/*  <Format type="millions" /> */}
      </Tooltip>
    </PieChart>
  );
};

export default PieGraph;
