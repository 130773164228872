import moment from "moment";
import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    Col, Form, Modal,
    Row
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import {
    calcularDuracion, getData, inputChange,
    isWeekEnd, saveData
} from "../helpers";
import { useTranslation } from "react-i18next";
import NotesModal from "./NotesModal";
import ReactDatePicker from 'react-datepicker';
import { setHours, setMinutes } from "date-fns";
import { registerLocale } from 'react-datepicker';
import { es } from 'date-fns/locale';
registerLocale('es',es)


const TasksModalEdit = (props) => {

  const [t,i18n] = useTranslation("mytasks")
  const [notesData, setNotesData]= useState([])
  const {
    show,
    handleClose,
    getAllData,
    saveData,
    setSaveInfo,
    onlyForTaskInProject,
    // formatDate,
    // calcularDuracion,
    // inputChange,
    excludedDates,
    // isWeekEnd,
    // saveData,
    taskEditParent,
  } = props;

  const [taskEdit, setTaskEdit] = useState({});
  const [hasDurationHours, setHasDurationHours] = useState(false)

  const formatDate = (date) => {
    if (date) {
      let dateReturn = moment(date, "YYYY-MM-DD")._d;
      return dateReturn;
    } else {
      return null;
    }
  };
  useEffect(() => {
    setTaskEdit(taskEditParent);
    return () => {
      setTaskEdit({});
    };
  }, [taskEditParent]);

  useEffect(()=>{
    if(taskEditParent){
      if((taskEditParent.Metodologia == "Ágil" || taskEditParent.Metodologia == "Agile") && (taskEditParent.duracionAgil == "HORAS" || taskEditParent.duracionAgil == "HOURS")){
        setHasDurationHours(true)


      }
    }

  },[taskEditParent])

  const sizeOfinput= (taskEdit.Metodologia == "Tradicional") ? 4 : 4
  return (
    <React.Fragment>
      <Modal
        show={show}
        onHide={handleClose}
        size="lg"
        contentClassName="border-0 shadow-lg"
      >
        <Form
          onSubmit={(e) => {
           
            e.preventDefault();
            const success = () => {
              getAllData();
              setSaveInfo({})
              setHasDurationHours(false)
              handleClose();
            };
            saveData("my-tasks", taskEdit, success);
            // handleClose();
          }}
        >
          <Modal.Header>
            <Modal.Title className="h5">{t("modal_tasks_title")}</Modal.Title>
            <Button  onClick={()=>{
                props.setSaveInfo({})
                setHasDurationHours(false)
                handleClose()
               }} variant="light">X</Button>
          </Modal.Header>
          <Modal.Body>
            <Card className="mb-3 shadow-sm">
              <Card.Body className="p-3">
                <b className="text-muted">{t("modal_tasks_projectname")}: </b>
                {taskEdit.NombreProyecto}
                <br />
                <b className="text-muted">{t("modal_tasks_manager")}: </b> {taskEdit.Gerente}
                <br />
                <b className="text-muted">{t("modal_tasks_projectype")}: </b> {taskEdit.Metodologia}
                <br />
                <b className="text-primary">{t("modal_tasks_activity")}: </b>
                {taskEdit.NombreActividad}
              </Card.Body>
            </Card>
            <Row>
              <Col md={sizeOfinput} className="mb-3">
                <Form.Label>{t("modal_tasks_plannedstartdate")}</Form.Label>
                     <ReactDatePicker
                        selected={hasDurationHours ? taskEdit.FechaInicioAgilHoras : formatDate(taskEdit.FechaInicioProg)}
                        showTimeSelect = {hasDurationHours? true : false}
                        dateFormat = {hasDurationHours ? "dd-MM-yyyy h:mm aa":"dd/MM/yyyy"}
                        /*timeFormat="h:mm aa"
                        timeCaption="Hora"
                        minTime={ setHours(setMinutes(new Date(),30),7)}
                        maxTime={ setHours(setMinutes(new Date(),0),17)} */
                        locale = "es"
                        //excludeDates={allExcludeDates}
                        //filterDate ={date => date.getDay() !== 6 && date.getDay() !== 0}
                        wrapperClassName="w-100"
                        className="form-control"
                        disabled
                      /*   onChange={(date) => {
                          let e = { target: {} };
                          e.target.name = "startDate";
                          e.target.value = date;
                          dateChanged(e);

                        }} */
                        //disabled={(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? true:false}
                        //isClearable = {(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? false : true}
                        closeOnScroll={true}

                      />
              </Col>
              <Col md={sizeOfinput} className="mb-3">
                <Form.Label>{t("modal_tasks_plannedendtdate")}</Form.Label>
                <DatePicker
                  className="form-control"
                  selected={hasDurationHours ? taskEdit.FechaFinAgilHoras :formatDate(taskEdit.FechaFinProg)}
                  //dateFormat="dd/MM/yyyy"
                  dateFormat = {hasDurationHours ? "dd-MM-yyyy h:mm aa":"dd/MM/yyyy"}
                  todayButton="Hoy"
                  placeholderText="Elegir Fecha"
                  locale={window.localStorage.getItem("idioma") || "es"}
                  disabled
                />
              </Col>
              <Col md={sizeOfinput} className="mb-3">
                <Form.Label>{hasDurationHours ? t("modal_tasks_planneddurationdays.1") : t("modal_tasks_planneddurationdays") }</Form.Label>
                <Form.Control
                  name="DuracionProg"
                  value={taskEdit.DuracionProg || ""}
                  disabled
                />
              </Col>
              {taskEdit.Metodologia !== "Tradicional" && ( <>
                  <Col md={sizeOfinput} className={hasDurationHours ? 'mb-3': 'd-none'}>
                    <Form.Label>{ t("modal_tasks_realdurationdays.2") }</Form.Label>
                    <Form.Control
                      name="HorasEjecutadas"
                      value={taskEdit.HorasEjecutadas || 0}
                      type="number"
                      placeholder="0"
                      step={0.1}
                      min="0"
                   /*    onFocus={(e) => {
                        e.target.select();
                      }} */
                      onChange={(e) => {
                        inputChange(e, setTaskEdit);
                      }}
                      disabled={( props.rol == "Ejecutivo")? true:false}
                    />
                  </Col> 
                 <Col md={{ span: 3 }} className="mb-1">
                   <Form.Label>{t("modal_tasks_status")}</Form.Label>
                   <Form.Control
                     as="select"
                     className="border-info"
                     name="PctCompletado"
                     value={taskEdit.PctCompletado || ""}
                     onClick={(e) => {
                       return e.target.value;
                     }}
                     onChange={(e) => {
                       inputChange(e, setTaskEdit);
                     }}
                   >
                     <option key="0" value="0">
                       To Do
                     </option>
                     <option key="50" value="50">
                       Doing
                     </option>
                     <option key="100" value="100">
                       Done
                     </option>
                   </Form.Control>
                 </Col>
                
              </>)}
            </Row>
            <hr />
            {taskEdit.Metodologia === "Tradicional" && (
              <Row>
                <Col md={3} className="mb-3">
                  <Form.Label>{t("modal_tasks_startdate")}</Form.Label>
                  <DatePicker
                    className="form-control"
                    selected={formatDate(taskEdit.FechaInicioReal)}
                    dateFormat="dd/MM/yyyy"
                    todayButton={t("modal_tasks_today")}
                    excludeDates={excludedDates}
                    placeholderText={t("modal_tasks_choosedate")}
                    /* filterDate={isWeekEnd} */
                    locale="es"
                    onChange={(date) => {
                      let e = {
                        target: {
                          name: "FechaInicioReal",
                          value: date,
                        },
                      };
                      let processedData = {
                        ...taskEdit,
                        [e.target.name]: e.target.value,
                        DuracionReal: calcularDuracion(
                          date,
                          new Date(taskEdit.FechaFinReal)
                        ),
                      };
                      inputChange(e, setTaskEdit, processedData);
                    }}
                    // maxDate={new Date(taskEdit.FechaFinReal || new Date())}
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Form.Label>{t("modal_tasks_endtdate")}</Form.Label>
                  <DatePicker
                    className="form-control"
                    selected={formatDate(taskEdit.FechaFinReal)}
                    dateFormat="dd/MM/yyyy"
                    todayButton={t("modal_tasks_today")}
                    excludeDates={excludedDates}
                    placeholderText={t("modal_tasks_choosedate")}
                /*     filterDate={isWeekEnd} */
                    locale="es"
                    onChange={(date) => {
                      let e = {
                        target: {
                          name: "FechaFinReal",
                          value: date,
                        },
                      };
                      let processedData = {
                        ...taskEdit,
                        [e.target.name]: e.target.value,
                        DuracionReal: calcularDuracion(
                          new Date(taskEdit.FechaInicioReal),
                          date
                        ),
                        PctCompletado: 100,
                      };
                      inputChange(e, setTaskEdit, processedData);
                    }}
                    // minDate={new Date(taskEdit.FechaInicioReal || new Date())}
                  />
                </Col>
                <Col md={3} className="mb-3">
                  <Form.Label>{t("modal_tasks_durationdays")}</Form.Label>
                  <Form.Control
                    name="DuracionReal"
                    value={taskEdit.DuracionReal || ""}
                    disabled
                  />
                </Col>
                <Col md={3}>
                {/* {taskEdit.Metodologia === "Tradicional" && <hr />}  */}
                {taskEdit.Metodologia === "Tradicional" && (
                <Col md={{ span: 12 }} className="mb-1">
                  <Form.Label>{t("modal_tasks_pctcomplete")}</Form.Label>
                  <Form.Control
                    className="border-info"
                    name="PctCompletado"
                    value={taskEdit.PctCompletado || ""}
                    type="number"
                    onClick={(e) => {
                      e.target.select();
                    }}
                    onChange={(e) => {
                      inputChange(e, setTaskEdit);
                    }}
                  />
                </Col>
              )}
             
              {/* {taskEdit.Metodologia !== "Tradicional" && (
                 
                <Col md={{ span: 12 }} className="mb-1">
                  <Form.Label>{t("modal_tasks_status")}</Form.Label>
                  <Form.Control
                    as="select"
                    className="border-info"
                    name="PctCompletado"
                    value={taskEdit.PctCompletado || ""}
                    onClick={(e) => {
                      return e.target.value;
                    }}
                    onChange={(e) => {
                      inputChange(e, setTaskEdit);
                    }}
                  >
                    <option key="0" value="0">
                      To Do
                    </option>
                    <option key="50" value="50">
                      Doing
                    </option>
                    <option key="100" value="100">
                      Done
                    </option>
                  </Form.Control>
                </Col>
              )} */}
                </Col>
              </Row>
            )}
            {/* {taskEdit.Metodologia === "Tradicional" && <hr />} */}
            <Row>
              {/* {taskEdit.Metodologia === "Tradicional" && (
                <Col md={{ span: 3 }} className="mb-1">
                  <Form.Label>{t("modal_tasks_pctcomplete")}</Form.Label>
                  <Form.Control
                    className="border-info"
                    name="PctCompletado"
                    value={taskEdit.PctCompletado || ""}
                    type="number"
                    onClick={(e) => {
                      e.target.select();
                    }}
                    onChange={(e) => {
                      inputChange(e, setTaskEdit);
                    }}
                  />
                </Col>
              )}
              {taskEdit.Metodologia !== "Tradicional" && (
                <Col md={{ span: 3 }} className="mb-1">
                  <Form.Label>{t("modal_tasks_status")}</Form.Label>
                  <Form.Control
                    as="select"
                    className="border-info"
                    name="PctCompletado"
                    value={taskEdit.PctCompletado || ""}
                    onClick={(e) => {
                      return e.target.value;
                    }}
                    onChange={(e) => {
                      inputChange(e, setTaskEdit);
                    }}
                  >
                    <option key="0" value="0">
                      To Do
                    </option>
                    <option key="50" value="50">
                      Doing
                    </option>
                    <option key="100" value="100">
                      Done
                    </option>
                  </Form.Control>
                </Col>
              )} */}
              <Col md={{ span: 12 }} className="mb-4 mt-2">
                {onlyForTaskInProject ? 
                <NotesModal taskEdit = {taskEditParent} />
                :
                <NotesModal  taskEdit={taskEdit} /> 
                }
               {/*  <Form.Label>{t("modal_tasks_progress")}</Form.Label>
                <Form.Control
                  className="border-info"
                  name="Avance"
                  value={taskEdit.Avance || ""}
                  as="textarea"
                  placeholder={t("modal_tasks_putprogress")}
                  style={{ height: "100px" }}
                  onClick={(e) => {
                    e.target.select();
                  }}
                  onChange={(e) => {
                    inputChange(e, setTaskEdit);
                  }}
                /> */}
                {/* <Form.Label>{t("modal_tasks_lastprogress")}</Form.Label>
                <Form.Control
                  className="border-info"
                  name="Avance"
                  value={taskEdit.UltAvance || ""}
                  as="textarea"
                  disabled="true"
                  style={{ height: "100px" }}
                  onClick={(e) => {
                    e.target.select();
                  }}
                /> */}
              </Col>
            </Row>
            <small dangerouslySetInnerHTML={{__html: taskEdit.Seguimiento}}></small>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="dark" 
               onClick={()=>{
                props.setSaveInfo({})
                setHasDurationHours(false)
                handleClose()
               }}
            >
              <i className="fa fa-times mr-2"></i>{t("modal_tasks_buttoncancel")}
            </Button>
            <Button variant="success" type="submit">
              <i className="fa fa-save mr-2"></i>{t("modal_tasks_buttonsave")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </React.Fragment>
  );
};

export default TasksModalEdit;
