import React, { useState, useEffect, Suspense, lazy } from "react";
import { Tab, Row, Col, Nav, Container, Button } from "react-bootstrap";
import { StyledTabs } from "../StyledCommon";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import Rating_Wait from "./Rating_Wait";
import WaitForApproval from "./WaitingForApproval";

const Rating = (props) => {
  const [selectedTab, setSelectedTab] = useState("zero");
   const [t, i18n] = useTranslation("initiatives");
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  let title_i18 = t("iniciativas_title");

  const savePage = (value) => {
    localStorage.setItem('savePage', value);
  };

  const currentPage = localStorage.getItem("savePage") || "zero";

  const change = window.localStorage.getItem("rol");
  const { idViewConfig } = useParams();
  console.log(idViewConfig);


  return (
    <React.Fragment>
        <Container fluid>
          <Tab.Container
            id="left-tabs-example"
            activeKey={idViewConfig ? idViewConfig : selectedTab}
            transition={false}
            onSelect={(e) => setSelectedTab(e)}
          >
            <Row className="py-2">
              <Col>
                <StyledTabs as={Nav}>
                  <Nav.Item  className="px-3" data-tip={(t("iniciativas_lenguaje") === "es") ? "Acá puedes realizar Calificación Auto ó Manual de las Iniciativas" : "Here you can perform Manual/Auto Rating of the Initiatives"}>
                    <Nav.Link as={Button} className="btn-sm" variant="outline-secondary" eventKey="zero" onClick={() => savePage("zero")}>
                      {t("iniciativas_por_calificacion")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="px-3" data-tip={(t("iniciativas_lenguaje") === "es") ? "Acá podrás visualizar Iniciativas calificadas, forzar calificación y enviarlas a Aprobación":  "Here you can View Qualified Initiatives, Force Qualification, and Send to Approval"}>
                    <Nav.Link as={Button} className="btn-sm" variant="outline-secondary" eventKey="one" onClick={() => savePage("one")}>
                      {t("iniciativas_calificadas")}
                    </Nav.Link>
                  </Nav.Item>
                </StyledTabs>
              </Col>
            </Row>
            <hr color="#db526a"/>
            <Row>
              <Col className="pt-3 pb-5 mb-5">
                <Tab.Content>
                  <Tab.Pane
                    eventKey="zero"
                    className="animate__animated animate__fadeIn"
                  >
                    <Rating_Wait 
                    update={props.update}
                    setUpdate={props.setUpdate}
                    
                    />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="one"
                    className="animate__animated animate__fadeIn"
                  >
                    {/* <Rating_Rated /> */}
                    <WaitForApproval/>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
    </React.Fragment>
  );
};

export default Rating;
