import React, { useState, useEffect } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  ListGroup,
  Modal,
  Nav,
  ProgressBar ,
  Row,
  Tab,
  Table,
  Tabs
} from "react-bootstrap";
import TitleCommon from "../components/TitleCommon";
import Layout from "../Layout";
import { StyledTabs } from "../components/StyledCommon";
import { getData, saveData, inputChange, alreadyExists, API_ROUTE, headersRequest, desencry, calcularDuracionMinutes } from "../helpers";
import SuperTable from "../components/SuperTable";
import moment from "moment";
import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import FormProjectGeneral from "../components/FormProjectGeneral";
import Tachometer from "../components/Tachometer";
import ProgressIndicator from "../components/ProgressIndicator";
import styled from "styled-components";
import GanttSchedule from "../components/GanttSchedule/GanttSchedule";
import TabsSprints from "../components/TabsSprints";
import HvActivitiesResume from "../components/HvActivitiesResume";
import ProductBurndown from "../components/ProductBurndown";
import SprintsResume from "../components/SprintsResume";
import NoAccess from "./NoAccess";
import { selectTooltip , selectTooltip_en } from "../utils/selectTooltip";
import PieChart, {
  Series,
  Label,
  Connector,
  SmallValuesGrouping,
  Legend,
  Export,
} from "devextreme-react/pie-chart";
import { Font, Size } from "devextreme-react/circular-gauge";
import axios from "axios";
import { useTranslation } from "react-i18next";

const StyledIndicator = styled.div`
  position: absolute;
  top: 0.5rem;
  right: -0.5rem;
`;



function formatLabel(arg) {
  return `${arg.valueText} (${arg.percentText})`;
}

const HvAgile = () => {

  const ID_ORGANIZACION = "1727EA57-9E8F-44CE-938B-8F377491F135"  // Produccion
  /* const ID_ORGANIZACION = "8CAADED8-A836-4CB3-8822-878440CC4E30" */ //desarrollo

  const access = window.localStorage.getItem("accessToHvAgile")
  const desData = desencry(access,'accessCloudProjectDevHvAgile')
  const [t,i18n] =useTranslation("planning")

  const [mainData, setMainData] = useState({ new: true });
  const [sprintsList, setSprintsList] = useState({});
  const [burndownChart, setBurndownChart] = useState([]);
  const [stories, setStories] = useState([]);
  const [storieTitle, setStorietitle] = useState();
  const [showActivitiesByHistory, setShowActivitiesByHistory] = useState(false);
  const [showProjectResources,setShowProjectResources] = useState(false)
  const [historyActivities, setHistoryActvities] = useState([])
  const [showProjectIndicator, setShowProjectIndicator]= useState(false)
  const [showCostIndicator, setShowCostIndicator]= useState(true)
  const [getAllActivitiesByResources , setGetAllActivitiesByResources] = useState([])
  const [totalHoursProject,setTotalHoursProject]=useState(0)
  
  const handleCloseActivities = () => {
    setShowActivitiesByHistory(false)
  };
  const handleCloseProjectResource = () => {
    setShowProjectResources(false)
  };
  let { uid } = useParams();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getHistoryActivity = async (IdHistoriaUsuario
  ) => {
    try {
      const res = await axios.get(`${API_ROUTE}stories-activities/${IdHistoriaUsuario}`,
        headersRequest()
      );
      setHistoryActvities(res.data)
    }
    catch (error) {
      console.error(error)
    }
  }

  const handleActivitiesByHistory = (storyinfo) => {
    setStorietitle(storyinfo.HistoriaUsuario)
    getHistoryActivity(storyinfo.IdHistoriaUsuario)
    setShowActivitiesByHistory(true)
  }

  const getAllData = () => {
    Promise.all([
      getData(`hv-project-agile/${uid}`, []),
      getData(`hv-project-agile-sprints/${uid}`, []),
      getData(`hv-project-agile-burndown/${uid}`, []),
      getData(`stories/${uid}`, []),
      getData(`product-backlog-resources/${uid}`,[])
    ]).then((values) => {
      setMainData(values[0]);
      setSprintsList(values[1]);
      setBurndownChart(values[2]);
      setStories(values[3]);
      setGetAllActivitiesByResources(values[4])
    });
  };

  useEffect(() => {
    if (uid) {
      getAllData();
    }
  }, []);

  let verde = "#6ab41b";
  let azul = "#29b9d0";
  let gris = "#f3f3f3";

  /* valor.value >10? {color:"red"} : {}; */

  const customize = (pointInfo) => {
    return pointInfo.argument === "ToDo"
      ? { color: "#838383" }
      : pointInfo.argument === "Doing"
      ? { color: "#29b9d0" }
      : pointInfo.argument === "Done"
      ? { color: "#6ab41b" }
      :{ color: "#fdce56" }

  }
  const data = [{
    titulo: "ToDo",
    actividades: mainData.SinIniciar,
  },
  {
    titulo: "Doing",
    actividades: mainData.EnCurso,
  },
  {
    titulo: "Done",
    actividades: mainData.Terminadas,
  },
  {
    titulo: "Otros",
    actividades: mainData.Otros,
  }
  ];
  const columnsActivitiesHistory = [
    {
      selector: "NombreActividad",
      caption: t("planningboard_hu_tablecolumn_activity"),
      customRender: (row) => {
        return (
          <span>{row.data.NombreActividad}</span>
        )
      }
    },
    {
      selector: "FechaInicio",
      caption: t("planningboard_hu_tablecolumn_start"),
      customRender: (row) => {
        return (<>
          <span>{row.data.FechaInicio}</span>
        </>
        )
      },
      align: "center",
      width: 100,
    },
    {
      selector: "FechaFin",
      caption: t("planningboard_hu_tablecolumn_end"),
      customRender: (row) => {
        return (<>
          <span>{row.data.FechaFin}</span>
        </>
        )
      },
      align: "center",
      width: 100,
    },
    {
      selector: "Avance",
      caption: t("planningboard_hu_tablecolumn_advanced"),
      customRender: (row) => {
        return (<>
          {(row.data.Avance === "Done") && <>
            <span className="mr-2">
              <i className="fa fa-circle text-success"></i> {row.data.Avance}
            </span>
          </>}
          {(row.data.Avance === "Doing") && <>
            <span className="mr-2">
              <i className="fa fa-circle text-info"></i> {row.data.Avance}
            </span>
          </>}
          {(row.data.Avance === "To Do") && <>
            <span className="mr-2">
              <i className="fa fa-circle text-light"></i> {row.data.Avance}
            </span>
          </>}
        </>
        )
      },
      align: "center",
      width: 100,
    },
    {
      selector: "FechaModificacion",
      caption: t("planningboard_hu_tablecolumn_last"),
      customRender: (row) => {
        return (<>
          <span>{row.data.FechaModificado}</span>
        </>
        )
      },
      align: "center",
      width: 150,
    },
  ];
  const columnsResources = [
    {
      selector: "resourceName",
      caption: t("hvagile_table_name"),
      customRender: (row) => {
        return (
          <span>{row.data.resourceName}</span>
        )
      }
    },
    {
      selector: "totalHours",
      caption: "total Horas Plan.",
      customRender: (row) => {
        return (<div className="text-center"><span>{row.data.totalHours}</span></div>)

      },
      width:130,
    },
    {
      selector: "totalHoursReal",
      caption: "total Horas Real",
      customRender: (row) => {
        return (<div className="text-center"><span>{row.data.totalHoursReal}</span></div>)

      },
      width:130,
    }]

    const idOrganization = window.localStorage.getItem("OID")

    const dataResources = () =>{

      let groupActivities = _.groupBy(getAllActivitiesByResources,'Recurso')
      const totalHoursByResource = _.map(groupActivities,(activities,resource)=>{
        const total = _.sumBy(activities, activity =>{
          if( activity.FechaInicioHoras && activity.FechaFinHoras){
            return calcularDuracionMinutes( activity.FechaInicioHoras,activity.FechaFinHoras)
          }
          return 0
        })
        const totalComple = _.sumBy(activities, activity =>{
          if( activity.FechaInicioHoras && activity.FechaFinHoras && activity.pctcompletado === 100){
            return calcularDuracionMinutes( activity.FechaInicioHoras,activity.FechaFinHoras) 
          }
          return 0
        })
        const totalReal = _.sumBy(activities, activity =>{
          if( activity.FechaInicioHoras && activity.FechaFinHoras){
            return activity.HorasEjecutadas
          }
          return 0
        })
        return {
          resourceName : resource,
          totalActivities : activities.length,
          totalHours: total,
          totalHoursComple : totalComple,
          totalHoursReal : totalReal,
          totalHoursInProgress : total - totalComple,
          porceProgress  : Math.round( (totalReal / total) * 100 )
        }
      })
      return totalHoursByResource
    }

    const totalHoursByProject = (activitiesByResource)=>{
      //debe llegar las lanes sin el product backlog
      let totalHoursPlan = _.sumBy(activitiesByResource, activity =>{
                if( activity.FechaInicioHoras && activity.FechaFinHoras){
                  return calcularDuracionMinutes( activity.FechaInicioHoras,activity.FechaFinHoras)
                }
                return 0
              })
      
      let totalHoursComplet = _.sumBy(activitiesByResource, activity =>{
        if( activity.FechaInicioHoras && activity.FechaFinHoras && activity.pctcompletado === 100){
          return calcularDuracionMinutes( activity.FechaInicioHoras,activity.FechaFinHoras)
        }
        return 0
      })
      let totalHoursReal = _.sumBy(activitiesByResource, activity =>{
        if( activity.FechaInicioHoras && activity.FechaFinHoras ){
          return activity.HorasEjecutadas
        }
        return 0
      })
      return {totalHoursPlan : totalHoursPlan,
          totalHoursInProgress: totalHoursPlan - totalHoursComplet, 
          totalHoursComplet:totalHoursComplet, 
          totalHoursReal : totalHoursReal
        }
    }

  
    useEffect(()=>{
      if(getAllActivitiesByResources.length > 0){
        let calHours = totalHoursByProject(getAllActivitiesByResources)
        setTotalHoursProject(calHours)
      }
      
    },[getAllActivitiesByResources])

  const hasDurationHours =(mainData?.DuracionAgil == "HORAS" || mainData?.DuracionAgil == "HOURS") ? true : false
    
  return (
    <>
    { (desData ==="true") ? 
    <React.Fragment>
      <Layout>
        <TitleCommon
          title={{
            text: t("hvagile_tittle"),
            icon: "chart-line"
          }}
          color="secondary"
         /*  button={{
            text: "Volver",
            icon: "arrow-left",
            url: "/projects"
          }} */
          goBack={{
            text: t("planningboard_button_back"),
            icon: "arrow-left",
          }}
        />
        <Container fluid className="bg-light">
          <Row>
            <Col className="py-3">
              <h5 className="mb-0">
                <span className="text-primary">{t("hvagile_projectname")} </span>
                <span>{mainData.NombreProyecto}</span>
              </h5>
            </Col>
          </Row>
        </Container>
        <Container className="pt-3">
          <div className="d-flex justify-content-between mb-3">
            <small>
              <i className="fa fa-play mr-2 text-secondary"></i>{t("hvagile_start")}:{" "}
              {mainData.FechaInicio || "-"}
            </small>
            {/* <small className="text-primary">
              ({mainData.CantSprints}) sprints
            </small> */}
            <small>
              <i className="fa fa-flag-checkered mr-2 text-secondary"></i>
              {t("hvagile_end")}: {mainData.FechaFin || "-"}
            </small>
          </div>
          {/* <ProgressBar
            variant="success"
            now={mainData.PctCompletadoProyecto || 0}
            label={
              <small>{`Completado ${
                mainData.PctCompletadoProyecto || 0
              }%`}</small>
            }
            style={{ height: 15 }}
            className="mb-2"
          /> */}
          {/* <div className="d-flex justify-content-between mb-3">
            <small>
              <i className="fa fa-play mr-2 text-secondary"></i>Inicio: 12/12/12
            </small>
            <small className="text-primary">36 días ~ 7 sprints</small>
            <small>
              <i className="fa fa-flag-checkered mr-2 text-secondary"></i>
              Fin: En curso
            </small>
          </div> */}
          <Form.Check
              checked={showProjectIndicator}
              inline
              label={t("hvagile_show_indicator")}
              type="checkbox"
              className="px-0 mb-3"
              onChange={(e)=>{
                setShowProjectIndicator((value) => (value = !value));
               }}
            >
          </Form.Check>
          {(showProjectIndicator) && <>
          <Col
            md={12}
            className="mb-2 mb-md-4 d-flex flex-column justify-content-around "
          >
            <div className="d-flex align-items-center">
              <h6 style={{ width: "180px",fontStyle:"normal"}}>
                <b className="text-dark">{t("hvagile_indicator_expected")} </b> 
                {mainData.PorEsperado || 0} % ({mainData.CantActividadesEsperadas}/{mainData.CantTotalActividades})
              </h6>
              <ProgressBar
                style={{ height: 8, flex: 1 }}
                className="mb-0"
                variant="info"
                now={mainData.PorEsperado || 0}
                // label={
                //   <small>{`Plan. ${
                //     projectData.projectData.PctPlaneadoProyecto || 0
                //   }%`}</small>
                // }
                key={2}
              />
            </div>
            <div className="d-flex align-items-center">
              <h6 style={{ width: "180px" }}>
                <b className="text-dark">{t("hvagile_indicator_actual")}</b> 
                {mainData.PorCompletado || 0} % ({mainData.CantActividadesCompletadas}/{mainData.CantTotalActividades})
              </h6>
              <ProgressBar
                style={{ height: 8, flex: 1 }}
                className="mb-0"
                variant="success"
                now={mainData.PorCompletado || 0}
                // label={
                //   <small>{`Comp. ${
                //     projectData.projectData.PctCompletadoProyecto || 0
                //   }%`}</small>
                // }
                key={1}
              />
            </div>
          </Col>
          </>
          }
          <Card className="bg-light mb-2">
            <Card.Body className="px-2 pt-2 pb-0">
              <Row>
                <Col md={6} lg={2} className="mb-2">
                  <Card>
                    <Card.Body className="py-2 d-flex flex-column">
                      <span className="h6 mb-0">{mainData.CodigoProyecto}</span>
                      <small className="text-primary">{t("hvagile_code")}</small>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={3} className="mb-2">
                  <Card>
                    <Card.Body className="py-2 d-flex flex-column">
                      <span className="h6 mb-0">{mainData.ProductOwner}</span>
                      <small className="text-primary">Product Owner</small>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={2} className="mb-2">
                  <Card className="bg-light border-secondary">
                    <Card.Body className="py-2 d-flex flex-column">
                      <span className="h6 mb-0">
                        <i className="fa fa-rocket mr-2 text-secondary"></i>
                        {mainData.CantSprints}
                      </span>
                      <small className="text-secondary">Sprints</small>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={2} className="mb-2">
                  <Card>
                    <Card.Body className="py-2 d-flex flex-column">
                      <span className="h6 mb-0">{mainData.FechaModificacion}</span>
                      <small className="text-primary">{t("hvagile_modified")}</small>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={3} className="mb-2">
                  <Card>
                    <Card.Body className="py-2 d-flex flex-column">
                      <span className="h6 mb-0">{mainData.ScrumMaster}</span>
                      <small className="text-primary">Scrum master</small>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {(mainData.IdCostosAgil == "SI" || mainData.IdCostosAgil == "YES") && 
           <Form.Check
            checked={showCostIndicator}
            inline
            label={t("hvagile_show_indicator_cost")}
            type="checkbox"
            className="px-0 mb-3"
            onChange={(e)=>{
              setShowCostIndicator((value) => (value = !value));
              }}
            >
            </Form.Check>
          }
          {((mainData.IdCostosAgil == "SI" || mainData.IdCostosAgil == "YES") && showCostIndicator) && <>
          <Row className="mb-3" md={6} lg={12}>
              <Col lg={6}>
                <Card className="mb-0">
                  <Card.Body className="p-4">
                    <Card.Title>{t("planningboard_tooltip_card_14")}</Card.Title>
                    <span className="h2 mb-0 mt-2 d-block">
                      <i className="fa fa-wallet mr-2 text-primary"></i>$
                      {new Intl.NumberFormat().format(
                        mainData.CostoPlaneado || 0
                      )}
                    </span>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg={6}>
              <h5 className="text-muted mb-2">{t("planningboard_tooltip_card_13")}</h5>
                <h6>
                  {t("planningboard_tooltip_card_11")} ($
                  {new Intl.NumberFormat().format(
                    mainData.CostoPlaneado || 0
                  )}) <span className="float-right">100%</span>
                </h6>
              <ProgressBar className="mb-3" variant="info" now={100} style={{ height: "7px" }} />
             {/*  <br /> */}
              <h6>
                {t("planningboard_tooltip_card_12")} ($
                {new Intl.NumberFormat().format(mainData.CostoReal || 0)}){" "}
                <span className="float-right">
                 {
                  ((Math.round((((mainData.CostoReal * 100) / mainData.CostoPlaneado) + Number.EPSILON) * 100 )) / 100) || 0
                 }
                  %
                </span>
              </h6>
              <ProgressBar
                variant="success"
                now={(mainData.CostoReal * 100) / mainData.CostoPlaneado || 0}
                style={{ height: "7px" }}
              />
              </Col>
          </Row>
          </>}
          <Row>
            <Col className="mb-3 p-0" md={4} lg={3}>
              <Card className="border-0 mb-n2">
                <Card.Body className="p-3">
                  <Card.Title> 
                    {/* <i className="fa fa-check-circle text-success mr-2 animate__animated animate__pulse animate__infinite"></i>
                    Al día 
                    */
                    
                    }
                    {(idOrganization == ID_ORGANIZACION )?
                      <> 
                        {(mainData.PorEsperado - mainData.PorCompletado) < 2 && <> 
                          <i title="desv < 2%" style={{color:"#71E21B"}} className="ml-2 fa fa-circle"></i> {t("hvagile_status_4")}</> }
                        {(mainData.PorEsperado - mainData.PorCompletado) >= 10 && <>
                          <i title="desv >= 10%" style={{color:"#231a1a"}}  className="ml-2 fa fa-circle"></i> {t("hvagile_status_7")}</>}
                        {((mainData.PorEsperado - mainData.PorCompletado)  >= 2 &&  (mainData.PorEsperado - mainData.PorCompletado) < 5 ) && <>
                        <i title="desv >= 2 y < 5" style={{color:"#FCE940"}}  className="ml-2 fa fa-circle"></i> {t("hvagile_status_5")}</>}
                        {((mainData.PorEsperado - mainData.PorCompletado)  >= 5 && (mainData.PorEsperado - mainData.PorCompletado) < 10 ) && <>
                        <i title="desv >= 5 y < 10" style={{color:"#FF313A"}} className="ml-2 fa fa-circle"></i> {t("hvagile_status_6")}</> }
                      </> 
                      :
                      <>
                        { (mainData.Indicador === 0 ) && <> <i title="Por iniciar" className="ml-2 fa fa-stop-circle text-muted"></i> {t("hvagile_status_1")}</> }
                        { (mainData.Indicador === 100 ) && <> <i title="Completado" className="ml-2 fa fa-check-circle text-success"></i> {t("hvagile_status_3")} </>  }
                        { (mainData.Indicador >= 1 && mainData.Indicador <= 99 ) && <><i title="En curso" className="ml-2 fa fa-play-circle text-info"></i> {t("hvagile_status_2")} </>}
                      </>
                    }
                    <br />
                    {(idOrganization == ID_ORGANIZACION )? 
                      <>
                       <small 
                        data-tip={(t("planningboard_lenguaje") =="es")? selectTooltip(44): selectTooltip_en(44)} 
                        >{t("hvagile_indicatortext")}
                        </small>
                      </>
                      :
                      <>
                      <small 
                          >{t("hvagile_indicatortext")}
                    </small>
                      </>
                    }
                  
                  </Card.Title>
                </Card.Body>
              </Card>
              <Card className="border-0 mb-n2 pl-3">
                <ListGroup className="mb-2">
                  <ListGroup.Item>
                    <i className="fa fa-tasks text-primary mr-2"></i>
                    {mainData.CantActividades}
                    <small> {t("hvagile_activities")}</small>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
              <Card className="border-0 mb-n2 pl-3">
                <ListGroup className="mb-2 mt-2">
                  <ListGroup.Item >
                    <i className="fa fa-list-ol text-info mr-2"></i>
                    {/* {mainData.Backlog}
                    <small> {t("hvagile_activitiesinbacklog")}</small> */}
                  </ListGroup.Item>
                </ListGroup>
              </Card>
              <div className="pie-char-hv-agile">
              <PieChart
                    id="pie"
                    dataSource={data}
                    title=""
                    customizePoint={customize}
                  >
                    <Series
                      argumentField="titulo"
                      valueField="actividades"
                    >
                      <Label
                        visible={true}
                        customizeText={formatLabel}
                        format="fixedPoint"
                      >
                        <Font size={11} />
                        <Connector visible={true} width={1} />
                      </Label>
                      <SmallValuesGrouping threshold={1} mode="smallValueThreshold" />
                    </Series>
                    <Size width={245} height={220} />
                    <Legend horizontalAlignment="center" verticalAlignment="bottom" />
                    <Export enabled={false} />
                  </PieChart>
              </div>
              <ListGroup className="mb-0 pl-3">
              </ListGroup>
              <Card className="border-0 mt-3 mb-n2 d-flex pl-3">
                <Card.Body className="p-0 mt-0">
                  <Button
                    variant="outline-primary"
                    className="mt-0 mr-2"
                    onClick={handleShow}
                  >
                    <i className="fa fa-plus mr-2"></i>{t("hvagile_button_details")}
                  </Button>
                  <Modal show={show} onHide={handleClose} size="lg">
                    <Modal.Header>
                      <Modal.Title className="h5">
                        {t("hvagile_modal_details_title")}
                      </Modal.Title>
                      <Button onClick={handleClose} variant="light">X</Button>
                    </Modal.Header>
                    <Modal.Body>
                      <h5 className="text-primary">{mainData.Nombre}</h5>
                      <h6>{t("hvagile_modal_details_text1")}:</h6>
                      <p>{mainData.Descripcion || "-"}</p>
                      <h6>{t("hvagile_modal_details_text2")}:</h6>
                      <p>{mainData.Justificacion || "-"}</p>
                      <hr />
                      <h6>{t("hvagile_modal_details_text3")}:</h6>
                      <p>{mainData.ObjGeneral || "-"}</p>
                      <h6>{t("hvagile_modal_details_text4")}:</h6>
                      <p>{mainData.ObjEspecificos || "-"}</p>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="dark" onClick={handleClose}>
                      {t("hvagile_button_close")}  
                      </Button>
                    </Modal.Footer>
                  </Modal>
                  <Link
                    to={`/planning-board/${uid}`}
                    target="_blank"
                    className="btn btn-primary mt-0"
                  >
                    <i className="fa fa-chalkboard-teacher mr-2"></i>Backlog
                  </Link>
                </Card.Body>
              </Card>
            </Col>
            <Col md={8} lg={9} className="mb-3">
              <Card className="border-1 ">
                <Card.Body className="p-3">
                  <Card.Title>Project Burndown</Card.Title>
                  <ProductBurndown data={burndownChart} />
                </Card.Body>
              </Card>
              <Row>
                <Col className="mt-1" md={4} lg={4}>
                  <ListGroup>
                    <ListGroup.Item className="p-2 mt-4 cursor-resources" onClick={()=> setShowProjectResources(true) }>
                      <i className="fa fa-users text-primary mr-2 cursor-icon-resources"></i>
                      {mainData.CantRecursos}
                      <small> {t("hvagile_chartburndown_conventionts_resources")}</small>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col md={4} lg={4}>
                  <ListGroup>
                    <ListGroup.Item className="p-2 mt-4" md={4} lg={3}>
                      <i className="fa fa-business-time text-info mr-2" ></i>
                      {mainData.TotalPuntos}
                      <small className="ml-1">{t("hvagile_chartburndown_conventionts_expected")}</small>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
                <Col md={4} lg={4}>
                  <ListGroup>
                    <ListGroup.Item className="p-2 mt-4" md={4} lg={3}>
                      {/* <i className="fa fa-tachometer-alt text-success mr-2" ></i> */}
                      <i className="fa fa-business-time text-success mr-2" ></i>
                      {mainData.VelocidadSprint}
                      <small className="ml-1">{t("hvagile_chartburndown_conventionts_remaining")}</small>
                    </ListGroup.Item>
                  </ListGroup>
                </Col>
              </Row>

              {/* <Button
                variant="outline-primary"
                className="mt-3 mr-2"
                onClick={handleShow}
              >
                <i className="fa fa-plus mr-2"></i>Detalles
              </Button>
              <Link
                to={`/planning-board/${uid}`}
                target="_blank"
                className="btn btn-primary mt-3"
              >
                <i className="fa fa-chalkboard-teacher mr-2"></i>Backlog
              </Link> */}
              {/*  </Col> */}

            </Col>
          </Row>
          <Card className="my-3 bg-light">
            <Card.Body>
              <Card.Title>
                Sprints <Badge variant="secondary">{sprintsList.length}</Badge>
              </Card.Title>
              <SprintsResume sprintsList={sprintsList} getHistoryActivity = {getHistoryActivity} />
            </Card.Body>
          </Card>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>{t("hvagile_userstories_tittle")}</Card.Title>
              {/* {JSON.stringify(stories)}  */}
              <Table bordered responsive size="sm">
                <thead>
                  <tr>
                    <th className="text-muted text-center">{t("hvagile_userstories_tablecolumn_history")}</th>
                    <th className="text-muted text-center">{t("hvagile_userstories_tablecolumn_activities")}</th>
                    <th className="text-muted text-center">{t("hvagile_userstories_tabletories_tablecolumn_start")}</th>
                    <th className="text-muted text-center">{t("hvagile_userstories_tablecolumn_end")}</th>
                    <th className="text-muted text-center">{t("hvagile_userstories_tablecolumn_advanced")}</th>
                    {(idOrganization != ID_ORGANIZACION ) &&
                    <th className="text-muted text-center">{t("hvagile_userstories_tablecolumn_score")}</th>
                    }
                    {(idOrganization == ID_ORGANIZACION ) && <>
                      <th className="text-muted text-center">Km Meta Total</th>
                      <th className="text-muted text-center">Km Totales Ejecutados</th>
                      <th className="text-muted text-center">Km Totales Restantes</th>
                    </>
                    }
                  </tr>
                </thead>
                <tbody>
                  {stories.map((story) => {
                    const kmRestantes = (story?.TotalKMEjecutados - story?.TotalKM).toFixed(2)
                    const porceKm= ((story?.TotalKM == 0 )? "" : ((story?.TotalKMEjecutados / story?.TotalKM  ) * 100).toFixed(0)) 
                    const porceNow = (idOrganization == ID_ORGANIZACION ) ? porceKm : story.pctcompletado
                    const porceVariant = (idOrganization == ID_ORGANIZACION ) ? ( porceKm == 100)? "success" : "info" : (story.pctcompletado === 100)? "success" : "info"
                    return (
                      <tr>
                        <td>
                          <a href="#" onClick={() => handleActivitiesByHistory(story)} title={story.HistoriaUsuario}>
                            {story.HistoriaUsuario?.slice(0, 50)}
                          </a>
                        </td>
                        <td className="text-center">{story.CantActividades}</td>
                        <td>{story.FechaInicio}</td>
                        <td>{story.FechaFin}</td>
                        <td>
                          <span className="d-flex align-items-center justify-content-between">
                            <ProgressBar
                           /*    variant="success" */
                              animated
                              /* variant = {(story.pctcompletado === 100)? "success" : "info"} */
                              /* now={story.pctcompletado} */
                              /* variant= {porceVariant} */
                              variant= {porceVariant} 
                              /* variant = {(story.pctcompletado === 100)? "success" : "info"} */
                              now= {porceNow}
                              style={{ height: "10px", width: "calc(60%)" }}
                              className="mr-2"
                            />
                            
                            {
                              (idOrganization == ID_ORGANIZACION)? 
                              <small>{porceKm}%</small>
                              :
                              <small>{story.pctcompletado}</small>
                            }
                          </span>
                        </td>
                        {(idOrganization != ID_ORGANIZACION) &&
                         <td className="text-center">{story?.PuntosHistoria}</td>
                        }
                       
                        {(idOrganization == ID_ORGANIZACION) && <>
                        <td className="text-center">{story?.TotalKM}</td>
                        <td className="text-center">{story?.TotalKMEjecutados}</td>
                        <td className={(kmRestantes < 0)? "text-danger text-center": "text-success text-center"}>{kmRestantes}</td>
                        </>
                        }
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
          {hasDurationHours  &&  
          <Card className="mb-5 shadow-sm">
          <Card.Body>
            <Card.Title className="text-primary d-flex align-items-center">
              <i className="fas fa-clock mr-2"></i> Total esfuerzo en Horas:
            </Card.Title>
            <Card className="mb-3 bg-light">
              <Card.Body>
                <div className="d-flex justify-content-between text-secondary font-weight-bold">
                  <span>Total esfuerzo planeado: {totalHoursProject.totalHoursPlan} hrs.</span>
                  {/* <span>Total esfuerzo en progreso: {totalHoursProject.totalHoursInProgress} hrs.</span> */}
                  <span>Total esfuerzo real: {totalHoursProject.totalHoursReal} hrs.</span>
                </div>
              </Card.Body>
            </Card>
     
            <Card.Title className="text-secondary d-flex align-items-center">
              <i className="fas fa-users mr-2"></i> {t("hvagile_resources_tittle")}
            </Card.Title>
     
            <Table bordered responsive size="sm" className="table-striped">
              <thead>
                <tr>
                  <th className="text-center text-primary">Nombre</th>
                  <th className="text-center text-primary">Cant. Actividades</th>
                  <th className="text-center text-primary">Horas total planeadas</th>
                  {/* <th className="text-center text-primary">Horas total en progreso</th> */}
                  <th className="text-center text-primary">Horas total reales</th>
                  <th className="text-center text-primary">% Cumplimiento</th>
                </tr>
              </thead>
              <tbody>
                {dataResources()?.map((resource) => {
                  const porceVariant = resource.porceProgress === 100 ? "#e0301e" : "#ffb600";
                  return (
                    <tr key={resource.resourceName}>
                      <td className="text-center">{resource.resourceName}</td>
                      <td className="text-center">{resource.totalActivities}</td>
                      <td className="text-center">{resource.totalHours}</td>
                      {/* <td className="text-center">{resource.totalHoursInProgress}</td> */}
                      <td className="text-center">{resource.totalHoursReal}</td>
                      <td>
                        <div className="d-flex align-items-center justify-content-between">
                          <ProgressBar
                            animated
                            now={resource.porceProgress}
                            style={{
                              height: "10px",
                              width: "calc(70%)",
                              backgroundColor:"#d7d7e2" ,
                              borderBlockColor:"#fafafa"
                            }}
                            className="mr-2"
                          />
                          <span
                            style={{
                              color: porceVariant,
                              fontWeight: "bold",
                            }}
                          >
                            {resource.porceProgress}%
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
          }
          <Modal
            show={showActivitiesByHistory}
            onHide={handleCloseActivities}
            contentClassName="border-0 shadow-lg"
            centered
            size="lg"
          >
            <Modal.Header >
              <Modal.Title className="h5">{t("hvagile_userstories_list")} {storieTitle} </Modal.Title>
              <Button onClick={handleCloseActivities} variant="light">X</Button>
            </Modal.Header>
            <Modal.Body>
              <SuperTable
                noSearch
                columns={columnsActivitiesHistory}
                data={historyActivities}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="dark" onClick={handleCloseActivities}>
                {t("hvagile_button_close")}
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={showProjectResources}
            onHide={handleCloseProjectResource}
            contentClassName="border-0 shadow-lg"
            centered
            size="lg"
            >
            <Modal.Header>
              <Modal.Title className="h5">{t("hvagile_chartburndown_list_activities")}</Modal.Title>
              <Button onClick={handleCloseProjectResource} variant="light">X</Button>
            </Modal.Header>
            <Modal.Body>
              <SuperTable
                noSearch
                columns={columnsResources}
                //data = { mainData[0] }
                data={dataResources()}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="dark" onClick={handleCloseProjectResource}>
                {t("hvagile_button_close")}
              </Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </Layout>
    </React.Fragment>
      :<>
       <NoAccess />
      </>
      }
     </>
  );
};

export default HvAgile;
