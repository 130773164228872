import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Row,
} from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom";
/* import emptyImg from "../assets/people-1.png"; */
import emptyImg from "../../assets/people-1.png";
import SuperTableV2 from "../SuperTableV2";
import ModalRegCriterios from "./ModalRegCriterios";
import axios from "axios";
import { API_ROUTE, headersRequest, removeData } from "../../helpers";
import swal from "sweetalert";

const ParametersCriterios = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [createCriterio, setcreateCriterio] = useState({
    IdParametro: "",
    NombreCriterio: "",
    Descripcion: "",
    IdTipoCriterio: "",
    CualitativoAlta: null,
    CualitativoMedia: null,
    CualitativoBaja: null,
    CuantitativoAlta: null,
    CuantitativoMedia : null,
    CuantitativoBaja : null,
    IdUnidadMedida: null,
    Pregunta: null,
    new: true,
  });
  const [criterios, setCriterios] = useState([]);
  const [criterioToEdit, setCriterioToEdit] = useState([]);

  const handleOpenModal = (setState) => {
    setState((val) => (val = !val));
  };

  const getAllCriterios = async () => {
    try {
        const res  = await axios.get(`${API_ROUTE}criterios`, headersRequest())
        .then((res)=>{
          setCriterios(res.data)
        })
    } catch (error) {
        console.error(error)
    }
  };

    //CRUD state
    const addCriterios = (payload) => {
        console.log(payload)
        setCriterios((prev) => {
          return [...prev, payload];
        });
        getAllCriterios(); //mientras levis me explica como usar el id fecha y crador que viene de SP para cargar al estado
        props.setUpdate(!props.update);// para actualizar en el panel de confg.
        };             
      
    
      const updateCriterio = (payload) => {
        //const stateObje = payload.Estado == "true" ? true : false
        const objIndex = criterios.findIndex((item) => item.Id == payload.Id);
        const objUpdate = [...criterios];
        objUpdate[objIndex] = {
          ...objUpdate[objIndex],
          NombreCriterio: payload.NombreCriterio,
          Descripcion: payload.Descripcion,
        };
        setCriterios(objUpdate);
        props.setUpdate(!props.update);// para actualizar en el panel de confg.
      };
    
      const deleteCriterio = (uid) => {
        const success = () => {
          swal(
            "¡Hecho!",
            "Los datos del objetivo han sido Eliminados exitosamente.",
            "success"
          ).then((val) => {});
        };
    
        swal({
          title: "¿Seguro?",
          text: "Eliminarás todos los datos asociados al objetivo de manera definitiva. ¿Deseas continuar?",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        }).then((willDelete) => {
          if (willDelete) {
            removeData(`criterios`, uid, success)
            .then((res)=>{
              const dummie = criterios?.filter((item) => item.Id !== uid);
              setCriterios(dummie);
              props.setUpdate(!props.update); // para actualizar en el panel de confg.
            });
          }
        });
      };
    
      useEffect(() => {
        getAllCriterios();
      }, []);

  const columns = [
    {
      caption: "Parametro",
      selector: "Parametro",
      width: 250,
      customRender: (row) => {
        return (
          <div className="d-flex align-items-center dropdown-in-table">
            <Dropdown onClick={(e) => e.stopPropagation()}>
              <Dropdown.Toggle
                variant="outline-primary"
                size="sm"
                className="dropdown-btn-ellipsis"
              >
                <i className="fa fa-ellipsis-h"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={(e) => {
                    setCriterioToEdit(row.data);
                    handleOpenModal(setOpenModalEdit);
                  }}
                >
                  Editar
                </Dropdown.Item>
                <Dropdown.Item onClick={() => deleteCriterio(row.data.Id)}>
                  Eliminar
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <span
              className="ml-2"
              style={{ maxWidth: "300px" }}
              title={row.data.Parametro}
            >
              {row.data.Parametro}
            </span>
          </div>
        );
      }
    },
    {
        caption: "Nombre Criterio",
        selector: "NombreCriterio",
        width: 200
    },
    {
      caption: "Descripción",
      selector: "Descripcion",
      width: 110
    },
    {
      caption: "Tipo", //Si es cualitativa o cuantitativa
      selector: "Tipo",
      width: 110
    },
    {
      caption: "Alta",
      width: 110,
      customRender: (row) => {

        // // Renderiza el valor correcto basado en el tipo
        if (row.data.Tipo === 'Cuantitativo') {
          return row.data.CuantitativoAlta || 'N/A';
        } else {
          return row.data.CualitativoAlta || '';
        }
      }
    },
    {
      caption: "Media",
      width: 110,
      customRender: (row) => {

        // // Renderiza el valor correcto basado en el tipo
        if (row.data.Tipo === 'Cuantitativo') {
          return row.data.CuantitativoMedia || 'N/A';
        } else {
          return row.data.CualitativoMedia || '';
        }
      }
    },
    {
      caption: "Baja",
      width: 110,
      customRender: (row) => {

        // // Renderiza el valor correcto basado en el tipo
        if (row.data.Tipo === 'Cuantitativo') {
          return row.data.CuantitativoBaja || 'N/A';
        } else {
          return row.data.CualitativoBaja || '';
        }
      }
    },
    {
      caption: "Unidad",
      width: 110,
      customRender: (row) => {
        if (row.data.Tipo === 'Cualitativo') {
          return 'N/A';
        } else {
          return row.data.Unidad || '';
        }
      }
    },
    // {
    //   caption: "Fecha de Creación",
    //   selector: "FechaCreacion",
    //   width: 110
    // },
    // {
    //   caption: "Creado Por",
    //   selector: "CreadoPor",
    //   width: 110,
    // },
  ];


  return (
    <>
      <Container fluid>
        <Row>
          <Col className="pt-3 pb-5">
            {criterios?.length > 0 ? (
              <>
                <SuperTableV2 data={criterios} columns={columns} />
              </>
            ) : (
              <>
                <div className="text-center pt-1">
                  <img
                    src={emptyImg}
                    alt="Sin datos"
                    className="img-fluid mb-3 animated pulse slower infinite"
                  />
                  <p>
                    Aún no tienes ningún Parámetro / Criterio, 
                    inicia ahora creando uno.
                  </p>
                  {/* <Button
                    variant="success"
                    onClick={() => handleOpenModal(setOpenModal)}
                  >
                    <i className="fa fa-plus-circle mr-2"></i>Crear Parámetro / Criterio
                  </Button> */}
                </div>
              </>
            )}
            <Button
              variant="success"
              type="submit"
              onClick={()=>handleOpenModal(setOpenModal)}
              style={{ bottom: "1rem", right: "1rem", cursor: "pointer" }}
              className="position-fixed shadow animate__animated animate__fadeInUp animate__delay-2s"
            >
              <i className="fa fa-plus mr-2"></i> Crear Parámetro / Criterio
            </Button>
          </Col>
        </Row>
      </Container>
      <ModalRegCriterios
        openModal={openModal}
        setOpenModal={setOpenModal}
        handleOpenModal={handleOpenModal}
        criterio={createCriterio}
        setCriterio={setcreateCriterio}
        addCriterios={addCriterios}
        setMainState={setCriterios}
        action="create"
      />
      <ModalRegCriterios
        openModal={openModalEdit}
        setOpenModal={setOpenModalEdit}
        handleOpenModal={handleOpenModal}
        criterio={criterioToEdit}
        setCriterio={setCriterioToEdit}
        updateObjetives={updateCriterio}
        setMainState={setCriterios}
        action="edit"
      />
    </>
  );
};

export default ParametersCriterios;
