import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom";
import Layout from '../../Layout';
import TitleCommon from '../TitleCommon';
import { Container, Row, Col, Form, Button, Modal, Table, Card, Fade, Dropdown } from 'react-bootstrap';
import swal from "sweetalert";
import Swal from 'sweetalert2';
import axios from 'axios';
import { API_ROUTE, headersRequest } from '../../helpers';

const ConfigStrategy = (props) => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [showModal2, setShowModal2] = useState(false);
  const [objectives, setObjectives] = useState([]);
  const [criterios, setCriterios] = useState([]);
  const [selectedObjectives, setSelectedObjectives] = useState([]);
  const [selectedCriterios, setSelectedCriterios] = useState([]);
  const [objectiveWeights, setObjectiveWeights] = useState({});
  const [criteriosWeights, setCriteriosWeights] = useState({});
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [isButtonDisabled2, setButtonDisabled2] = useState(true);
  const [isCheckCriterios, setIsCheckCriterios] = useState(false);
  const [isCheckObjective, setIsCheckObjectives] = useState(false);
  
 // Actualizar el estado del botón cuando cambie la cantidad de objetivos seleccionados
 useEffect(() => {
  if (selectedObjectives.length >= 2) {
      setButtonDisabled(false);
  } else {
      setButtonDisabled(true);
  }
  }, [selectedObjectives]);
  useEffect(() => {
    if (selectedCriterios.length >= 2) {
        setButtonDisabled2(false);
    } else {
        setButtonDisabled2(true);
    }
  }, [selectedCriterios]);

  const getAllCriterios = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}criterios`, headersRequest());
      setCriterios(res.data || []);
      //console.log(criterios)
    } catch (error) {
      console.error(error);
    }
  };
  
  const getAllObjetives = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}strategy`, headersRequest());
      setObjectives(res.data || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllCriterios();
    getAllObjetives();
  }, [props.update]);
  

  const handleModalShow = () => setShowModal(true);
  const handleModalShow2 = () => setShowModal2(true);
  // Función para cerrar el modal y limpiar los objetivos seleccionados
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleModalClose2 = () => {
    setShowModal2(false);
  };

  const handleCheckboxChange = (id) => {
    // Verifica si el objetivos ya está seleccionado
    const isSelected = selectedObjectives.includes(id);

    // Actualiza la lista de objetivos seleccionados
    const updatedSelectedObjetivos = isSelected
      ? selectedObjectives.filter(objId => objId !== id)
      : [...selectedObjectives, id];

    setSelectedObjectives(updatedSelectedObjetivos);

    // Actualiza los pesos de los objetivos
    setObjectiveWeights(prevWeights => {
      if (isSelected) {
        const { [id]: _, ...restWeights } = prevWeights;
        return restWeights;
      } else {
        return {
          ...prevWeights,
          [id]: prevWeights[id] !== undefined ? prevWeights[id] : 0
        };
      }
    });
  };
  
  // const handleCheckboxChange2 = (id) => {
  //   //actualizar los criterios seleccionados
  //   const updateSelectedCriterios = selectedCriterios.includes(id)
  //   ? selectedCriterios.filter(objId => objId !== id)
  //   : [...selectedCriterios, id];
  //   setSelectedCriterios(updateSelectedCriterios);

  //     //actualiza los pesos de los criterios
  //     if(updateSelectedCriterios.includes(id)) {
  //       //si el criterio esta siendo marcado, por defendo pondre valor 0 inicial
  //       setCriteriosWeights(prevWeights => ({
  //         ...prevWeights,
  //         [id]:prevWeights[id] || 0
  //       }));
  //     } else {
  //       //si el criterio esta siendo desmarcado, eliminar su peso
  //       setCriteriosWeights(prevWeights => {
  //         const { [id]: _, ...restWeight} = prevWeights;
  //         return restWeight;
  //       });
  //     }
  // };
  const handleCheckboxChange2 = (id) => {
    // Verifica si el criterio ya está seleccionado
    const isSelected = selectedCriterios.includes(id);

    // Actualiza la lista de criterios seleccionados
    const updatedSelectedCriterios = isSelected
      ? selectedCriterios.filter(objId => objId !== id)
      : [...selectedCriterios, id];

    setSelectedCriterios(updatedSelectedCriterios);

    // Actualiza los pesos de los criterios
    setCriteriosWeights(prevWeights => {
      if (isSelected) {
        const { [id]: _, ...restWeights } = prevWeights;
        return restWeights;
      } else {
        return {
          ...prevWeights,
          [id]: prevWeights[id] !== undefined ? prevWeights[id] : 0
        };
      }
    });
  };

  const handleWeightChange = (id, value) => {
    const numValue = Number(value);
    if (numValue >= 1 && numValue <= 99) {
      setObjectiveWeights(prev => ({
        ...prev,
        [id]: numValue
      }));
    }
    if(!selectedObjectives.includes(id)){
      setSelectedObjectives(prev => [...prev,id]);
    }
  };

  const handleWeightChange2 = (id, value) => {
    const numValue = Number(value);
    if (numValue >= 1 && numValue <= 99) {
      setCriteriosWeights(prev => ({
        ...prev,
        [id]: numValue
      }));
    }
    if(!selectedCriterios.includes(id)){
      setSelectedCriterios(prev => [...prev, id]);
    }
  };

  const  handleCancelSelection = () =>{
    setSelectedObjectives([]);
    setObjectiveWeights(0);
    handleModalClose(); // Limpiar los criterios seleccionados
  }
  const  handleCancelSelection2 = () =>{
    setSelectedCriterios([]);
    setCriteriosWeights(0);
    handleModalClose2(); // Limpiar los criterios seleccionados
  }

  const handleConfirmSelection = () => {
    if (selectedObjectives.length >= 2 ) {
      handleModalClose();
    } else {
      swal(
        "¡Selección insuficiente!",
        "Por favor, selecciona al menos 2 objetivos.",
        "warning"
      );
    }
  };
  const handleConfirmSelection2 = () => {
    if (selectedCriterios.length >= 2 ) {
      handleModalClose2();
    } else {
      swal(
        "¡Selección insuficiente!",
        "Por favor, selecciona al menos 2 Criterios.",
        "warning"
      );
    }
  };
  
  const handleSave = async () => {
    const totalWeight = Object.values(objectiveWeights).reduce((acc, weight) => acc + weight, 0);
      if (totalWeight < 100) {
        swal(
          'Peso insuficiente',
          'Faltan por asignar '+ (totalWeight-100)*-1 +'%',
          "warning"
        );
      } else if (totalWeight > 100) {
        swal(
          'Peso excesivo',
          'Sobrepasaste la cantidad en '+ (totalWeight-100)+'%',
          'warning'
        );
      } else {
        swal(
          'Éxito',
          'Perfecto, tus Objetivos Estratégicos han sido configurados correctamente.',
          'success'
        )
        //desestructurar objectiveweights
        const entries = Object.entries(objectiveWeights);
        const dataToSend = entries.map(([id, weight]) =>({
          id,
          weight
        }));
        try {
          const res = await axios.post(`${API_ROUTE}objetivesweights`, dataToSend, headersRequest());
            swal(
              'Operación Exitosa!',
              'Pesos de los Objetivos Estratégicos asignados Correctamente!',
              "success"
            );
            setIsCheckObjectives(false);
            setSelectedObjectives([]);
            setObjectiveWeights(0);
            getAllObjetives();
        } catch (error) {
          console.error(error);
          swal(
            'Error',
            'Hubo un problema con la solicitud. Intente de nuevo, error: ' + error.message,
            'error'
          );
        }
        // .then(() => {
        //   history.push('/initiatives');
        // });
      }
  };

  const handleSave2 = async () => {
    const totalWeight2 = Object.values(criteriosWeights).reduce((acc, weight) => acc + weight, 0);
      if (totalWeight2 < 100) {
        swal(
          'Peso insuficiente',
          'Faltan por asignar '+ (totalWeight2-100)*-1 +'%',
          "warning"
        );
      } else if (totalWeight2 > 100) {
        swal(
          'Peso excesivo',
          'Sobrepasaste la cantidad en '+ (totalWeight2-100)+'%',
          'warning'
        );
      } else {
        swal(
          'Éxito',
          'Perfecto, tus Criterios/Parámetros han sido configurados correctamente.',
          'success'
        )
        //desestructurar criteriosWeights
        const entries = Object.entries(criteriosWeights);
        const dataToSend = entries.map(([id, weight]) =>({
          id,
          weight
        }));
        try {
          const res = await axios.post(`${API_ROUTE}criteriosweights`, dataToSend, headersRequest());
          swal(
            'Operación Exitosa!',
            'Pesos de los Criterios / Parámetros asignados Correctamente!',
            "success"
          );
          setIsCheckCriterios(false);
          setSelectedCriterios([]);
          setCriteriosWeights(0);
          getAllCriterios();
        } catch (error) {
          console.error(error);
          swal(
            'Error',
            'Hubo un problema con la solicitud. Intente de nuevo. '+error,
            'error'
          );
        }
        // .then(() => {
        //   history.push('/initiatives');
        // });
      }
  };

  const handleCheckObjectivesChange = (e) => setIsCheckObjectives(e.target.checked);
  const handleCheckCriteriosChange = (e) => setIsCheckCriterios(e.target.checked);
  
  const EditarPesoCriterios = () => {
    getAllCriterios();
    const filtroCriterios = criterios.filter(c => c.Peso >= 1);
  
    if (filtroCriterios.length > 0) {
      //console.log(JSON.stringify(filtroCriterios));
      filtroCriterios?.forEach(criterio =>{
        const{Id, Peso} = criterio;
        handleWeightChange2(Id, Peso);
        
      });
      setIsCheckCriterios(true);
    } else {
      Swal.fire({
        title: "No hay Criterios / Parámetros Configurados",
        text: "Deseas configurarlos ahora?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#db526a',
        confirmButtonText: 'Si, vamos allá!',
        cancelButtonText: 'No, Cancelar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          setIsCheckCriterios(true);
          setShowModal2(true);
        } else {
          console.log("nada para hacer");
        }
      });
    }
  };
  const EditarPesoObjetivos = () => {
    getAllObjetives();
    const filtroObjetivos = objectives.filter(o => o.Peso >= 1);

    if (filtroObjetivos.length > 0) {
      //console.log(JSON.stringify(filtroObjetivos));
      filtroObjetivos?.forEach(criterio =>{
        const{Id, Peso} = criterio;
        handleWeightChange(Id, Peso);
        
      });
      setIsCheckObjectives(true);
    } else {
      Swal.fire({
        title: "No hay Objetivos Estratégicos Configurados",
        text: "Deseas configurarlos ahora?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#db526a',
        confirmButtonText: 'Si, vamos allá!',
        cancelButtonText: 'No, Cancelar',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          setIsCheckObjectives(true);
          setShowModal(true);
        } else {
          console.log("nada para hacer");
        }
      });
    }
  };

  const PesosCriteriosCero = async () => {
    if(criterios.filter(cri => cri.Peso>0)?.length>0) {
      try {
        const result = await Swal.fire({
          title: "Eliminar Configuración",
          text: "¿Estás seguro de que deseas eliminar toda la configuración?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: '#db526a',
          confirmButtonText: 'Sí, vamos allá!',
          cancelButtonText: 'No, Cancelar',
          reverseButtons: true
        });
    
        if (result.isConfirmed) {
          // Ejecuta la solicitud de eliminación aquí
          await axios.delete(`${API_ROUTE}zero/123`, headersRequest());
          
          // Mostrar mensaje de éxito
          await Swal.fire({
            title: 'Éxito!',
            text: 'Eliminación de configuración exitosa.',
            icon: 'success',
            timer: 2000, // Duración del mensaje en milisegundos
            showConfirmButton: false // No mostrar botón de confirmación
          });
    
          // Llamar al método para obtener todos los objetivos
          setIsCheckCriterios(false);
          setCriteriosWeights([]);
          setSelectedCriterios([]);
          getAllCriterios();
        } else {
          console.log("Nada para hacer");
        }
      } catch (error) {
        // Mostrar mensaje de error
        await Swal.fire({
          title: 'Error!',
          text: 'Hubo un error al restablecer la configuración.',
          icon: 'error',
          timer: 2000, // Duración del mensaje en milisegundos
          showConfirmButton: false // No mostrar botón de confirmación
        });
        console.error("Hubo un problema con la solicitud:", error);
      }
    }else{
      await Swal.fire({
        title: 'Uups!',
        text: 'No hay Configuración para eliminar',
        icon: 'error',
        timer: 2000, // Duración del mensaje en milisegundos
        showConfirmButton: false // No mostrar botón de confirmación
      });
    }
  };
  const PesosObjetivosCero = async () => {
    if(objectives.filter(obj => obj.Peso>0)?.length>0) {
      try {
        const result = await Swal.fire({
          title: "Eliminar Configuración",
          text: "¿Estás seguro de que deseas eliminar toda la configuración?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: '#db526a',
          confirmButtonText: 'Sí, vamos allá!',
          cancelButtonText: 'No, Cancelar',
          reverseButtons: true
        });
    
        if (result.isConfirmed) {
          // Ejecuta la solicitud de eliminación aquí
          await axios.delete(`${API_ROUTE}zero/456`, headersRequest());
          
          // Mostrar mensaje de éxito
          await Swal.fire({
            title: 'Éxito!',
            text: 'Eliminación de configuración exitosa.',
            icon: 'success',
            timer: 2000, // Duración del mensaje en milisegundos
            showConfirmButton: false // No mostrar botón de confirmación
          });
    
          // Llamar al método para obtener todos los objetivos
          setIsCheckObjectives(false);
          setObjectiveWeights([]);
          setSelectedObjectives([]);
          getAllObjetives();
        } else {
          console.log("Nada para hacer");
        }
      } catch (error) {
        // Mostrar mensaje de error
        await Swal.fire({
          title: 'Error!',
          text: 'Hubo un error al restablecer la configuración.',
          icon: 'error',
          timer: 2000, // Duración del mensaje en milisegundos
          showConfirmButton: false // No mostrar botón de confirmación
        });
        console.error("Hubo un problema con la solicitud:", error);
      }
    }else{
      await Swal.fire({
        title: 'Uups!',
        text: 'No hay Configuración para eliminar',
        icon: 'error',
        timer: 2000, // Duración del mensaje en milisegundos
        showConfirmButton: false // No mostrar botón de confirmación
      });
    }
  };




  // Filtrar criterios para solo los que están en criteriosWeights
  const filteredCriterios = criterios.filter(criterio => criteriosWeights[criterio.Id] !== undefined);
  const filteredObjetives = objectives.filter(obj => objectiveWeights[obj.Id] !== undefined);

  return (
    <>
        <Container fluid>
          <Row className='d-flex align-items-center justify-content-start'>
            <Col sm={3}>
              <Card className="bg-light my-4">
                <Card.Title className='d-flex justify-content-between align-items-center px-2 pt-2 m-0'>
                    <div className="d-flex justify-content-center align-items-center ">
                      <i className="fa fa-bullseye dashboard-icons mr-2"></i>
                      <span className="h5 mb-0">Criterios / Parámetros</span>
                    </div>
                    <Dropdown onClick={(e) => e.stopPropagation()}>
                      <Dropdown.Toggle
                        variant="outline-primary"
                        size="sm"
                        className="dropdown-btn-ellipsis"
                      >
                        <i className="fa fa-cogs"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => {
                            EditarPesoCriterios();
                          } }
                        >
                          Editar Pesos
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => { PesosCriteriosCero() }
                          }
                        >
                          Eliminar Configuración
                        </Dropdown.Item>
                        
                      </Dropdown.Menu>
                    </Dropdown>
                </Card.Title>
                <Card.Body className="py-2 px-2 d-flex flex-column justify-content-between align-items-center" >
                  <h2 className="my-0">{criterios?.length}</h2>
                  <div className="d-flex">
                    <div className="d-flex flex-column align-items-center mr-2">
                      <small className="text-dark"><i class="fa fa-circle mr-2" style={{color:"#c5c5c5"}}></i>Configurados</small>
                      <small className="text-primary">( {criterios.filter(filtro => filtro.Peso>0)?.length} )</small>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={3}>
              <Card className="bg-light my-4">
              <Card.Title className='d-flex justify-content-between align-items-center px-2 pt-2 m-0'>
                    <div className="d-flex justify-content-center align-items-center ">
                      <i className="fa fa-flag-checkered dashboard-icons mr-2"></i>
                      <span className="h5 mb-0">Objetivos Estratégicos</span>
                    </div>
                    <Dropdown onClick={(e) => e.stopPropagation()}>
                      <Dropdown.Toggle
                        variant="outline-primary"
                        size="sm"
                        className="dropdown-btn-ellipsis"
                      >
                        <i className="fa fa-cogs"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          onClick={() => EditarPesoObjetivos() }
                        >
                          Editar Pesos
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() => { PesosObjetivosCero() }
                          }
                        >
                          Eliminar Configuración
                        </Dropdown.Item>
                        
                      </Dropdown.Menu>
                    </Dropdown>
                </Card.Title>
                <Card.Body className="py-2 px-2 d-flex flex-column justify-content-between align-items-center">
                  <h2 className="my-0">{objectives?.length}</h2>
                  <div className="d-flex">
                    <div className="d-flex flex-column align-items-center mr-2">
                      <small className="text-dark"><i class="fa fa-circle mr-2" style={{color:"#c5c5c5"}}></i>Configurados</small>
                      <small className="text-primary">( {objectives.filter( obj => obj.Peso>0)?.length } )</small>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Card className="bg-light my-4"
          style={{display:'none'}}
          >
            <Card.Body className="p-0">
              <Row className='d-flex align-items-center my-3'>
                <Col sm={3} className='text-center'>
                  <Form.Group className='m-0'>
                    <Form.Check
                      type='checkbox'
                      label='Incluir Parámetros/Criterios'
                      checked={isCheckCriterios}
                      onChange={handleCheckCriteriosChange}
                    />
                  </Form.Group>
                </Col>
                <Col sm={3} className='text-center'>
                  <Form.Group className='m-0'>
                    <Form.Check
                      type='checkbox'
                      label='Incluir Objetivos Estratégicos'
                      checked={isCheckObjective}
                      onChange={handleCheckObjectivesChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          {isCheckObjective && (
            <Card className="my-3 bg-light">
              <Card.Body>
                <Card.Title className='m-0'>
                  <Row className='d-flex align-items-center'>
                    <Col sm={6}>
                      Objetivos Estratégicos
                    </Col>
                    <Col sm={6} className='text-right'>
                      <Button variant="secondary" onClick={handleModalShow}>Seleccionar Objetivos</Button>
                    </Col>
                  </Row>
                </Card.Title>
                {filteredObjetives.length >= 1 && (
                  <Table striped bordered hover className='mt-5'>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Nombre del Objetivo</th>
                        <th>Descripción</th>
                        <th>Impacto<br/>
                            Nulo | Bajo | Moderado | Crítico | Extremo
                        </th>
                        <th>Peso % <small> entre 1 y 99</small></th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredObjetives.map((objective, index) => {
                         const { Id } = objective;
                        return (
                          <tr key={Id}>
                            <td>{index + 1}</td>
                            <td>{objective.NombreObjetivo}</td>
                            <td>{objective.Descripcion}</td>
                            <td>
                              {objective.ImpactoNulo} | {objective.ImpactoBajo} | {objective.ImpactoModerado} | {objective.ImpactoCritico} | {objective.ImpactoExtremo}
                            </td>
                            <td className=''>
                              <Form.Control
                                type="number"
                                className='text-center'
                                value={objectiveWeights[objective.Id] || ''}
                                required
                                onChange={(e) => handleWeightChange(objective.Id, e.target.value)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                <hr color="#db526a"/>
                <Row>
                  <Col sm={12} className='text-right'>
                        <Button 
                          variant="dark" 
                          id='btn-cancel2' 
                          onClick={handleCheckObjectivesChange}
                          style={{ marginRight: '10px' }}
                        >
                          Cancelar
                        </Button>
                        
                        <Button 
                          className=''
                          variant="success" 
                          id='btn-save' 
                          onClick={handleSave}
                          disabled={isButtonDisabled}
                        >
                          Guardar
                        </Button>
                      </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
          {isCheckCriterios && (
            <Card className="my-3 bg-light">
              <Card.Body>
                <Card.Title className='m-0'>
                  <Row className='d-flex align-items-center'>
                    <Col sm={6}>
                      Parámetros / Criterios
                    </Col>
                    <Col sm={6} className='text-right'>
                      <Button variant="secondary" onClick={handleModalShow2}>Seleccionar Parámetros</Button>
                    </Col>
                  </Row>
                </Card.Title>
                {filteredCriterios?.length >= 1 && (
                  <Table striped hover className='mt-5'>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Párametro</th>
                        <th>Criterio</th>
                        <th>Descripción</th>
                        <th>Tipo</th>
                        <th>Impacto<br/>
                            Bajo | Medio | Alto
                        </th>
                        <th>Peso % <small> entre 1 y 99</small></th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredCriterios.map((criterio, index) => {
                        const { Id } = criterio;
                        return (
                          <tr key={Id}>
                            <td>{index + 1}</td>
                            <td>{criterio.Parametro}</td>
                            <td>{criterio.NombreCriterio}</td>
                            <td width={300}>{criterio.Descripcion}</td>
                            <td>{criterio.Tipo}</td>
                            <td>
                              {criterio.Tipo === 'Cuantitativo' ? (
                                criterio.CuantitativoAlta +' | '+ criterio.CuantitativoMedia +' | '+ criterio.CuantitativoBaja
                              ) : (
                                criterio.CualitativoAlta +' | '+ criterio.CualitativoMedia +' | '+ criterio.CualitativoBaja
                              )}
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                className='text-center'
                                value={criteriosWeights[criterio.Id] || ''}
                                required
                                onChange={(e) => handleWeightChange2(criterio.Id, e.target.value)}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                <hr color="#db526a"/>
                <Row>
                <Col sm={12} className='text-right'>
                      <Button   
                        variant="dark" 
                        id='btn-cancel1' 
                       onClick={handleCheckCriteriosChange}
                       style={{ marginRight: '10px' }}
                      >
                        Cancelar
                      </Button>

                      <Button 
                        variant="success" 
                        id='btn-save2' 
                        onClick={handleSave2}
                        disabled={isButtonDisabled2}
                      >
                        Guardar
                      </Button>
                    </Col>
                </Row>
              </Card.Body>
            </Card>
          )}
        </Container>

      <Modal 
        id="modalObjetivos"
        show={showModal} 
        onHide={handleModalClose}
        backdrop="static"
        keyboard={false}
        size='xl'
        >
        <Modal.Header closeButton>
          <Modal.Title>Seleccionar Objetivos</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped hover className='text-center'>
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre del Objetivo</th>
                <th>Descripción</th>
                <th>Seleccionar</th>
              </tr>
            </thead>
            <tbody>
              {objectives.map((objective, index) => (
                <tr key={objective.Id}>
                  <td>{index + 1}</td>
                  <td>{objective.NombreObjetivo}</td>
                  <td>{objective.Descripcion}</td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={selectedObjectives.includes(objective.Id)}
                      onChange={() => handleCheckboxChange(objective.Id)}
                      disabled={!objective.Estado}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleCancelSelection}>Cancelar</Button>
          <Button variant="primary" onClick={handleConfirmSelection}>Confirmar</Button>
        </Modal.Footer>
      </Modal>

      <Modal 
        id="modalCriterios"
        show={showModal2} 
        onHide={handleModalClose2}
        backdrop="static"
        keyboard={false}
        size='xl'
        >
        <Modal.Header closeButton>
          <Modal.Title>Seleccionar Criterios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped hover className='text-center'>
            <thead>
              <tr>
                <th>#</th>
                <th>Nombre del Parámetro</th>
                <th>Nombre del Criterio</th>
                <th>Descripción</th>
              </tr>
            </thead>
            <tbody>
              {criterios.map((criterio, index) => (
                <tr key={criterio.Id}>
                  <td>{index + 1}</td>
                  <td>{criterio.Parametro}</td>
                  <td>{criterio.NombreCriterio}</td>
                  <td>{criterio.Descripcion}</td>
                  <td>
                    <Form.Check
                      type="checkbox"
                      checked={selectedCriterios.includes(criterio.Id)}
                      onChange={() => handleCheckboxChange2(criterio.Id)}
                      //disabled={!criterio.Peso > 0}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={handleCancelSelection2}>Cancelar</Button>
          <Button variant="primary" onClick={handleConfirmSelection2}>Confirmar</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfigStrategy;