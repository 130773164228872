import React from "react";
import {
  Chart,
  CommonSeriesSettings,
  Font,
  Legend,
  Series,
  Title,
  Tooltip,
} from "devextreme-react/chart";
import _ from "lodash";

const CharBarForPosition = ({ data, title, type }) => {
  const resourcesByPosition = _.map(
    _.countBy(data, "ProfesionCargo"),
    (val, position) => ({
      position,
      val,
    })
  );

  const textForCustomTooltip = (arg) => {
    return {
      text: `${arg.argumentText}
            Cantidad:${arg.valueText}`,
    };
  };

  const formatTooltipRvA = (e) => {
    return {
      text: `${e.argument}\n${e.seriesName}\n (${e.valueText})`,
    };
  };

  const colorPvsA = ["#c5c5c5", "#29b9d0", "#6ab41b"];

  {
  }
  return (
    <Chart
      id="chart"
      height="300px"
      dataSource={type == "tasks" ? data : resourcesByPosition}
      //title={title}

      palette={type == "tasks" ? colorPvsA : "Pastel"}
    >
      <Title text={title}>
        <Font family="apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, Liberation Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji" />
      </Title>
      {type == "tasks" && (
        <CommonSeriesSettings argumentField="resource" type="stackedBar" />
      )}
      {type == "tasks" && <Series valueField="PorIniciar" name="Por Iniciar" />}
      {type == "tasks" && <Series valueField="EnCurso" name="En Progreso" />}
      {type == "tasks" && <Series valueField="terminadas" name="terminadas" />}

      {type == "tasks" ? (
        <Legend
          horizontalAlignment="right"
          verticalAlignment="top"
          visible={true}
          itemTextPosition="right"
        />
      ) : (
        <Series
          valueField="val" //oranger
          argumentField="position" //
          name="Cantidad"
          type="bar"
          color="#ffaa66"
        />
      )}

      <Tooltip
        enabled
        location="center"
        customizeTooltip={
          type == "tasks" ? formatTooltipRvA : textForCustomTooltip
        }
      />
    </Chart>
  );
};

export default CharBarForPosition;
