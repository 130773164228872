import React, { useEffect, useState } from "react";
import Gantt, {
  Tasks,
  Dependencies,
  Resources,
  ResourceAssignments,
  Column,
  Editing,
  Toolbar,
  Item,
  Validation,
} from "devextreme-react/gantt";
import axios from "axios";
import { API_ROUTE, headersRequest } from "../../helpers";
import _ from "lodash";


const GanttView = (props) => {
  const [taskForGantt, setTaskForGantt] = useState([]);
  const [huForGantt, setHuForGantt] = useState([]);
  const [resourceForGantt, setResourceForGantt] = useState([]);

  const getTaskForGantt = async (uid) => {
    try {
      const res = await axios.get(
        `${API_ROUTE}product-backlog-gantt-task/${uid}`,
        headersRequest()
      );
      setTaskForGantt(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getHUForGantt = async (uid) => {
    try {
      const res = await axios.get(
        `${API_ROUTE}product-backlog-gantt-hu/${uid}`,
        headersRequest()
      );
      setHuForGantt(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getResourceForGantt = async (uid) => {
    try {
      const res = await axios.get(
        `${API_ROUTE}product-backlog-gantt-resource/${uid}`,
        headersRequest()
      );
      setResourceForGantt(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const actions = [
      {condition : props.dataTask, action : getTaskForGantt},
      {condition : props.dataHu, action : getHUForGantt},
      {condition : props.dataResource, action : getResourceForGantt}
    ]
     actions.forEach(({condition,action})=>{
      if(condition){
        action(props.uid)
      }
     })
/*     if (props.dataTask) {
      getTaskForGantt(props.uid);
    } 
     if(props.dataHu){
      getHUForGantt(props.uid);
    }
    if(props.dataResource){
      getResourceForGantt(props.uid)
    } */
  }, [props.view]);

  const custom_progress_test = (cellInfo) => {
    const getValueProgress = (cellInfo) => {
      console.log(cellInfo);
      switch (cellInfo.value) {
        case 0:
          return "To Do";
        case 100:
          return "Done";
        default:
          return "Doing";
      }
    };

    return getValueProgress(cellInfo);
  };
  return (
    <>
      <h5 className="ml-5">
        {" "}
        Timeline {props.dataTask ? "Sprints" :props.dataHu ? " Historias de Usuario" : "Recursos"} :{" "}
      </h5>
      <Gantt
        taskListWidth={500}
        scaleType="weeks"
        height={500}
        onTaskDblClick={() => {
          return console.log("clickOnTask");
        }}
      >
        <Tasks dataSource={props.dataTask ? taskForGantt : props.dataHu? huForGantt: resourceForGantt } />
        {/*  <Dependencies dataSource={dependencies} />
          <Resources dataSource={resources} />
          <ResourceAssignments dataSource={resourceAssignments} /> */}

        <Toolbar>
          {/* <Item name="undo" />
            <Item name="redo" /> */}
          {/*  <Item name="separator" /> */}
          <Item name="collapseAll" />
          <Item name="expandAll" />
          <Item name="separator" />
          {/*    <Item name="addTask" />
            <Item name="deleteTask" />
            <Item name="separator" /> */}
          <Item name="zoomIn" />
          <Item name="zoomOut" />
        </Toolbar>

        <Column dataField="title" caption="Nombre Actividad" width={300} />
        <Column dataField="start" caption="Fecha Inicio" minWidth={100} />
        <Column dataField="end" caption="Fecha Fin" minWidth={100} />
        <Column
          dataField="progress"
          caption="Estado"
          width={60}
          customizeText={custom_progress_test}
        />

        <Validation autoUpdateParentTasks />
        <Editing disabled />
      </Gantt>
    </>
  );
};

export default GanttView;
