import axios from "axios";
import _ from "lodash";
import moment from "moment";
import CryptoJS from "crypto-js";
import { parse, differenceInMinutes , getDay } from "date-fns";

//export const API_ROUTE = "http://localhost:4000/apiTM/";
// NO usar export const API_ROUTE = "https://timelinemanager.co.pwc.com/api_timelinemanager/apiTM/";
// export const API_ROUTE = "https://projects.co.pwc.com/api_timelinemanager/apiTM/";
// export const API_ROUTE =
//   process.env.NODE_ENV === "production"
//     ? process.env.REACT_APP_API_PRODUCTION
//     : process.env.REACT_APP_API_DEVELOPMENT;

export const API_ROUTE = process.env.REACT_APP_API;

export const headersRequest = () => {
  return {
    headers: {
      "cache-control": "no-cache",
      Accept: "application/json",
      "Content-Type": "application/json",
      tokenuserid: localStorage.getItem("tokenuserid"),
      user_lg : localStorage.getItem("idioma")
    },
  };
};

export const getData = async (api, defaultReturn) => {
  let result = await fetch(`${API_ROUTE}${api}`, {
    method: "GET",
    headers: {
      "cache-control": "no-cache",
      Accept: "application/json",
      "Content-Type": "application/json",
      tokenuserid: localStorage.getItem("tokenuserid"),
      user_lg : localStorage.getItem("idioma")
    },
  })
    .then((response) => response.json())
    .then((data) => {
      return data;
    })
    .catch((err) => {
      return defaultReturn;
    });
  return result;
  //
};

export const settingLocalStorage = (access) => {
  window.localStorage.setItem("accessToBoards", "false");
  window.localStorage.setItem("accessToTasks", "false");
  window.localStorage.setItem("accessToProductivity", "false");
  window.localStorage.setItem("accessToKanbanBoard", "false");
  window.localStorage.setItem("accessToProject", "false");
  window.localStorage.setItem("accessToPlanningBoard", "false");
  window.localStorage.setItem("accessToSprintBoard", "false");
  window.localStorage.setItem("accessToHvAgile", "false");
  window.localStorage.setItem("accessToHvProject", "false");
  window.localStorage.setItem("accessToSchedule", "false");

  const accesBoards = access?.filter((val) => val.DirURL == "/boards");
  const accesTask = access?.filter((val) => val.DirURL == "/tasks");
  const accesProductivity = access?.filter(
    (val) => val.DirURL == "/productivity"
  );
  const accesUser = access?.filter((val) => val.DirURL == "/resources");
  const accesKanbanBoard = access?.filter(
    (val) => val.DirURL == "/kanban-board"
  );
  const accesProject = access?.filter((val) => val.DirURL == "/project");
  const accesPlanningBoard = access?.filter(
    (val) => val.DirURL == "/planning-board"
  );
  const accesSprintBoard = access?.filter(
    (val) => val.DirURL == "/sprint-board"
  );
  const accesHvAgile = access?.filter((val) => val.DirURL == "/hv-agile");
  const accesHvProject = access?.filter((val) => val.DirURL == "/hv-project");
  const accesSchedule = access?.filter((val) => val.DirURL == "/schedule");

  /*  setting key localstorage*/
  window.localStorage.setItem("accessToBoards", JSON.stringify(accesBoards));
  window.localStorage.setItem("accessToTasks", JSON.stringify(accesTask));
  window.localStorage.setItem(
    "accessToProductivity",
    JSON.stringify(accesProductivity)
  );
  window.localStorage.setItem("accessToUsers", JSON.stringify(accesUser));
  window.localStorage.setItem(
    "accessToKanbanBoard",
    JSON.stringify(accesKanbanBoard)
  );
  window.localStorage.setItem("accessToProject", JSON.stringify(accesProject));
  window.localStorage.setItem(
    "accessToPlanningBoard",
    JSON.stringify(accesPlanningBoard)
  );
  window.localStorage.setItem(
    "accessToSprintBoard",
    JSON.stringify(accesSprintBoard)
  );
  window.localStorage.setItem("accessToHvAgile", JSON.stringify(accesHvAgile));
  window.localStorage.setItem(
    "accessToHvProject",
    JSON.stringify(accesHvProject)
  );
  window.localStorage.setItem(
    "accessToSchedule",
    JSON.stringify(accesSchedule)
  );
};

export const settingAccessLocalStorage = (access) => {
  const accesBoards = access?.find((val) => val.DirURL == "/boards");

  const encryDataBoards = CryptoJS.AES.encrypt(
    accesBoards.Acceso.toString(),
    "accessCloudProjectDevBoard"
  ).toString();
  /* const encryDataRol = CryptoJS.AES.encrypt(accesBoards.Rol,'accessCloudProjectDevRol').toString(); */

  const accesTask = access?.find((val) => val.DirURL == "/tasks");
  const encryDataBTask = CryptoJS.AES.encrypt(
    accesTask.Acceso.toString(),
    "accessCloudProjectDevTask"
  ).toString();

  const accesProductivity = access?.find(
    (val) => val.DirURL == "/productivity"
  );
  const encryDataProductivity = CryptoJS.AES.encrypt(
    accesProductivity.Acceso.toString(),
    "accessCloudProjectDevProductivity"
  ).toString();

  const accesUser = access?.find((val) => val.DirURL == "/resources");
  const encryDataUser = CryptoJS.AES.encrypt(
    accesUser.Acceso.toString(),
    "accessCloudProjectDevUser"
  ).toString();

  const accesKanbanBoard = access?.find((val) => val.DirURL == "/kanban-board");
  const encryDataKanbanBoard = CryptoJS.AES.encrypt(
    accesKanbanBoard.Acceso.toString(),
    "accessCloudProjectDevKanbanBoard"
  ).toString();

  const accesProject = access?.find((val) => val.DirURL == "/project");
  const encryDataProject = CryptoJS.AES.encrypt(
    accesProject.Acceso.toString(),
    "accessCloudProjectDevProject"
  ).toString();

  const accesPlanningBoard = access?.find(
    (val) => val.DirURL == "/planning-board"
  );
  const encryDataPlanningBoard = CryptoJS.AES.encrypt(
    accesPlanningBoard.Acceso.toString(),
    "accessCloudProjectDevPlanningBoard"
  ).toString();

  const accesSprintBoard = access?.find((val) => val.DirURL == "/sprint-board");
  const encryDataSprintBoard = CryptoJS.AES.encrypt(
    accesSprintBoard.Acceso.toString(),
    "accessCloudProjectDevSprintBoard"
  ).toString();

  const accesHvAgile = access?.find((val) => val.DirURL == "/hv-agile");
  const encryDataHvAgile = CryptoJS.AES.encrypt(
    accesHvAgile.Acceso.toString(),
    "accessCloudProjectDevHvAgile"
  ).toString();

  const accesHvProject = access?.find((val) => val.DirURL == "/hv-project");
  const encryDataHvProject = CryptoJS.AES.encrypt(
    accesHvProject.Acceso.toString(),
    "accessCloudProjectDevHvProjecte"
  ).toString();

  const accesSchedule = access?.find((val) => val.DirURL == "/schedule");
  const encryDataSchedule = CryptoJS.AES.encrypt(
    accesSchedule.Acceso.toString(),
    "accessCloudProjectDevSchedule"
  ).toString();

  /*  setting key localstorage*/
  /* window.localStorage.setItem('accessToBoards', JSON.stringify(accesBoards)) */

  window.localStorage.setItem("rol", accesBoards.Rol);
  window.localStorage.setItem("accessToBoards", encryDataBoards);
  window.localStorage.setItem("accessToTasks", encryDataBTask);
  window.localStorage.setItem("accessToProductivity", encryDataProductivity);
  window.localStorage.setItem("accessToUsers", encryDataUser);
  window.localStorage.setItem("accessToKanbanBoard", encryDataKanbanBoard);
  window.localStorage.setItem("accessToProject", encryDataProject);
  window.localStorage.setItem("accessToPlanningBoard", encryDataPlanningBoard);
  window.localStorage.setItem("accessToSprintBoard", encryDataSprintBoard);
  window.localStorage.setItem("accessToHvAgile", encryDataHvAgile);
  window.localStorage.setItem("accessToHvProject", encryDataHvProject);
  window.localStorage.setItem("accessToSchedule", encryDataSchedule);
};

export const saveData = async (api, dataSave, success) => {
  let result = await fetch(`${API_ROUTE}${api}`, {
    method: dataSave.new ? "POST" : "PUT",
    headers: {
      "cache-control": "no-cache",
      Accept: "application/json",
      "Content-Type": "application/json",
      tokenuserid: localStorage.getItem("tokenuserid"),
    },
    body: JSON.stringify(dataSave),
  })
    .then((response) => {
      if (response.status == 200) {
        success();
      } else {
        console.log(response);
        alert(`Error al guardar: ${response.status}`);
      }
    })
    .catch((err) => {
      console.warn(api, err);
      alert("Error al guardar, revisa tu conexión.");
    });
  //
};

export const removeData = async (api, id, success) => {
  let result = await fetch(`${API_ROUTE}${api}/${id}`, {
    method: "DELETE",
    headers: {
      "cache-control": "no-cache",
      Accept: "application/json",
      "Content-Type": "application/json",
      tokenuserid: localStorage.getItem("tokenuserid"),
    },
  })
    .then((response) => {
      if (response.status == 200) {
        success();
      } else {
        console.log(response);
        alert(`Error al eliminar: ${response.status}`);
      }
    })
    .catch((err) => {
      console.warn(api, err);
      alert("Error al eliminar, revisa tu conexión.");
    });
  //
};

export const removeDataBaseline = async (api, id, baseline, uid) => {
  let result = await fetch(`${API_ROUTE}${api}/${id}/${baseline}/${uid}`, {
    method: "DELETE",
    headers: {
      "cache-control": "no-cache",
      Accept: "application/json",
      "Content-Type": "application/json",
      tokenuserid: localStorage.getItem("tokenuserid"),
    },
  })
    .then((response) => {
      if (response.status == 200) {
        /*  success(); */
      } else {
        console.log(response);
        alert(`Error al eliminar: ${response.status}`);
      }
    })
    .catch((err) => {
      console.warn(api, err);
      alert("Error al eliminar, revisa tu conexión.");
    });
  //
};

export const Login = async (credentials, success, warning) => {
  let result = await fetch(`${API_ROUTE}login`, {
    method: "POST",
    headers: {
      "cache-control": "no-cache",
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(credentials),
  })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      success(data);
    })
    .catch((err) => {
      console.warn("login", err);
      warning();
    });
  //
};

export const Organizations = async (email, setState) => {
  const data = {
    correo: email,
  };
  let result = await fetch(`${API_ROUTE}organizations`, {
    method: "GET",
    headers: {
      "cache-control": "no-cache",
      Accept: "application/json",
      "Content-Type": "application/json",
      email: email,
    },
  })
    .then((response) => response.json())
    .then((data) => {
      setState(data);
    })
    .catch((err) => {
      console.warn("login", err);
    });
  //
};

export const saveLocalStorage = async (saveData, id) => {
  let result = await fetch(`${API_ROUTE}user/access`, {
    method: "GET",
    headers: {
      "cache-control": "no-cache",
      Accept: "application/json",
      "Content-Type": "application/json",
      tokenuserid: localStorage.getItem("tokenuserid"),
    },
  })
    .then((response) => response.json())
    .then((data) => {
      saveData(data);
    })
    .catch((err) => {
      console.warn("saveLocalStorage/helper", err);
    });
  //
};
export const alreadyExists = (arr, filter) => {
  if (_.filter(arr, filter).length > 0) {
    return true;
  }
  return false;
};

export const inputChange = (e, toSet, processedData) => {
  if (processedData) {
    toSet(processedData);
  } else {
    toSet((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  }
};

/* export const isWeekEnd = (date) => {
  

  const day = getDay(date);

  return day !== 0 && day !== 6;
}; */

export const excludedDates = [
  "2012/01/09",
  "2012/03/19",
  "2012/04/05",
  "2012/04/06",
  "2012/05/01",
  "2012/05/21",
  "2012/06/11",
  "2012/06/18",
  "2012/07/02",
  "2012/07/20",
  "2012/08/07",
  "2012/08/20",
  "2012/10/15",
  "2012/11/05",
  "2012/11/12",
  "2012/12/25",
  "2013/01/01",
  "2013/01/07",
  "2013/03/25",
  "2013/03/28",
  "2013/03/29",
  "2013/05/01",
  "2013/05/13",
  "2013/06/03",
  "2013/06/10",
  "2013/07/01",
  "2013/08/07",
  "2013/08/19",
  "2013/10/14",
  "2013/11/04",
  "2013/11/11",
  "2013/12/25",
  "2014/01/01",
  "2014/01/06",
  "2014/03/24",
  "2014/04/17",
  "2014/04/18",
  "2014/05/01",
  "2014/06/02",
  "2014/06/23",
  "2014/06/30",
  "2014/07/20",
  "2014/08/07",
  "2014/08/18",
  "2014/10/13",
  "2014/11/03",
  "2014/11/17",
  "2014/12/08",
  "2014/12/25",
  "2015/01/01",
  "2015/01/12",
  "2015/03/23",
  "2015/04/02",
  "2015/04/03",
  "2015/05/01",
  "2015/05/18",
  "2015/06/08",
  "2015/06/15",
  "2015/06/29",
  "2015/07/20",
  "2015/08/07",
  "2015/08/17",
  "2015/10/12",
  "2015/11/02",
  "2015/11/16",
  "2015/12/08",
  "2015/12/25",
  "2016/01/01",
  "2016/01/11",
  "2016/03/21",
  "2016/03/24",
  "2016/03/25",
  "2016/05/09",
  "2016/05/30",
  "2016/06/06",
  "2016/07/04",
  "2016/07/20",
  "2016/08/15",
  "2016/10/17",
  "2016/11/07",
  "2016/11/14",
  "2016/12/08",
  "2017/01/09",
  "2017/03/20",
  "2017/04/13",
  "2017/04/14",
  "2017/05/01",
  "2017/05/29",
  "2017/06/19",
  "2017/06/26",
  "2017/07/03",
  "2017/07/20",
  "2017/08/07",
  "2017/08/21",
  "2017/10/16",
  "2017/11/06",
  "2017/11/13",
  "2017/12/08",
  "2017/12/25",
  "2018/01/01",
  "2018/01/08",
  "2018/03/19",
  "2018/03/29",
  "2018/03/30",
  "2018/05/01",
  "2018/05/14",
  "2018/06/04",
  "2018/06/11",
  "2018/07/02",
  "2018/07/20",
  "2018/08/07",
  "2018/08/20",
  "2018/10/15",
  "2018/11/05",
  "2018/11/12",
  "2018/12/25",
  "2019/01/01",
  "2019/01/07",
  "2019/03/25",
  "2019/04/18",
  "2019/04/19",
  "2019/05/01",
  "2019/06/03",
  "2019/06/24",
  "2019/07/01",
  "2019/08/07",
  "2019/08/19",
  "2019/10/14",
  "2019/11/04",
  "2019/11/11",
  "2019/12/25",
  "2020/01/01",
  "2020/01/06",
  "2020/03/23",
  "2020/04/09",
  "2020/04/10",
  "2020/05/01",
  "2020/05/25",
  "2020/06/15",
  "2020/06/22",
  "2020/06/29",
  "2020/07/20",
  "2020/08/17",
  "2020/10/12",
  "2020/11/02",
  "2020/11/16",
  "2020/12/08",
  "2020/12/25",
  "2021/01/01",
  "2021/01/11",
  "2021/03/22",
  "2021/04/01",
  "2021/04/02",
  "2021/05/17",
  "2021/06/07",
  "2021/06/14",
  "2021/07/05",
  "2021/07/20",
  "2021/08/16",
  "2021/10/18",
  "2021/11/01",
  "2021/11/15",
  "2021/12/08",
  "2022/01/10",
  "2022/01/10",
  "2022/03/21",
  "2022/04/14",
  "2022/04/15",
  "2022/05/30",
  "2022/06/20",
  "2022/06/27",
  "2022/07/04",
  "2022/07/20",
  "2022/08/15",
  "2022/10/17",
  "2022/11/07",
  "2022/11/14",
  "2022/12/08",
];

export const calcularDuracion = (fechaA, fechaB) => {
  // duracion en dias habiles a calcular partiendo de dos fechas

  let a = moment(fechaA, "DD/MM/YYYY");
  let b = moment(fechaB, "DD/MM/YYYY");
  let diaSemanaInicio = moment(fechaA, "DD/MM/YYYY").isoWeekday();
  let result = b.diff(a, "days") + 1;
  if ((result >= 7 && diaSemanaInicio == 1) || diaSemanaInicio == 2) {
    //- // console.log('la fecha inicio es de un lunes, martes o miercoles');
    //- // console.log('DIAS DE DIFERENCIA CALENDARIO: ' + result);
    let diasFines = Math.floor(result / 7) * 2;
    //- // console.log('diasFinesdiasFines: ' + diasFines);
    result = result - diasFines;
  } else if (result >= 7) {
    //- // console.log('DIAS DE DIFERENCIA CALENDARIO: ' + result);
    let diasFines = Math.round(result / 7) * 2;
    //- // console.log('diasFinesdiasFines: ' + diasFines);
    result = result - diasFines;
  } else if (result < 4) {
    //- // console.log('result < 4');
    result = result;
  } else {
    let diaSemana = moment(fechaB, "DD/MM/YYYY").isoWeekday();
    if (diaSemana < 4) {
      result = result - 2;
      if (result == 0 || result == -1) {
        result = 1;
      }
    }
  }
  let diasFestivosEncontrados = 0;
  for (let i = 0; i < excludedDates.length; i++) {
    if (
      moment(excludedDates[i], "YYYY/MM/DD").isBetween(
        moment(fechaA, "DD/MM/YYYY"),
        moment(fechaB, "DD/MM/YYYY")
      )
    ) {
      diasFestivosEncontrados++;
    }
  }
  result = result - diasFestivosEncontrados;
  if (isNaN(result)) {
    return "";
  } else {
    // if(result > 0){
    //     return result + 1;
    // } else {
    //     return result
    // }
    return result;
  }
};

// calculo de feriados y fines de semana para duracion en minutos agile
const excepcionesCalendarioHora = [
  "2022-01-10",
  "2022-01-10",
  "2022-03-21",
  "2022-04-14",
  "2022-04-15",
  "2022-05-30",
  "2022-06-20",
  "2022-06-27",
  "2022-07-04",
  "2022-07-20",
  "2022-08-15",
  "2022-10-17",
  "2022-11-07",
  "2022-11-14",
  "2022-12-08",
  "2023-01-01",
  "2023-01-09",
  "2023-03-20",
  "2023-04-02",
  "2023-04-06",
  "2023-04-07",
  "2023-04-09",
  "2023-05-01",
  "2023-05-22",
  "2023-06-12",
  "2023-06-19",
  "2023-07-03",
  "2023-07-20",
  "2023-08-07",
  "2023-08-21",
  "2023-10-16",
  "2023-11-06",
  "2023-11-13",
  "2023-12-08",
  "2023-12-25",
  "2024-01-01",
  "2024-01-08",
  "2024-03-24",
  "2024-03-25",
  "2024-03-28",
  "2024-03-29",
  "2024-03-31",
  "2024-05-01",
  "2024-05-13",
  "2024-06-03",
  "2024-06-10",
  "2024-07-01",
  "2024-07-20",
  "2024-08-07",
  "2024-08-19",
  "2024-10-14",
  "2024-11-04",
  "2024-11-11",
  "2024-12-08",
  "2024-12-25",
  "2025-01-01",
  "2025-01-06",
  "2025-03-24",
  "2025-04-13",
  "2025-04-17",
  "2025-04-18",
  "2025-04-20",
  "2025-05-01",
  "2025-06-02",
  "2025-06-23",
  "2025-06-30",
  "2025-06-30",
  "2025-07-20",
  "2025-08-07",
  "2025-08-18",
  "2025-10-13",
  "2025-11-03",
  "2025-11-17",
  "2025-12-08",
  "2025-12-25",
  "2026-01-01",
  "2026-01-12",
  "2026-03-23",
  "2026-03-29",
  "2026-04-02",
  "2026-04-03",
  "2026-04-05",
  "2026-05-01",
  "2026-05-18",
  "2026-06-08",
  "2026-06-15",
  "2026-06-29",
  "2026-07-20",
  "2026-08-07",
  "2026-08-17",
  "2026-10-12",
  "2026-11-02",
  "2026-11-16",
  "2026-12-08",
  "2026-12-25",
  "2027-01-01",
  "2027-01-11",
  "2027-03-21",
  "2027-03-22",
  "2027-03-25",
  "2027-03-26",
  "2027-03-28",
  "2027-05-01",
  "2027-05-10",
  "2027-05-31",
  "2027-06-07",
  "2027-07-05",
  "2027-07-20",
  "2027-08-07",
  "2027-08-16",
  "2027-10-18",
  "2027-11-01",
  "2027-11-15",
  "2027-12-08",
  "2027-12-25",
  "2028-01-01",
  "2028-01-10",
  "2028-03-20",
  "2028-04-09",
  "2028-04-13",
  "2028-04-14",
  "2028-04-16",
  "2028-05-01",
  "2028-05-29",
  "2028-06-19",
  "2028-06-26",
  "2028-07-03",
  "2028-07-20",
  "2028-08-07",
  "2028-08-21",
  "2028-10-16",
  "2028-11-06",
  "2028-11-13",
  "2028-12-08",
  "2028-12-25",
  "2029-01-01",
  "2029-01-08",
  "2029-03-19",
  "2029-03-25",
  "2029-03-29",
  "2029-03-30",
  "2029-04-01",
  "2029-05-01",
  "2029-05-14",
  "2029-06-04",
  "2029-06-11",
  "2029-07-02",
  "2029-07-20",
  "2029-08-07",
  "2029-08-20",
  "2029-10-15",
  "2029-11-05",
  "2029-11-12",
  "2029-12-08",
  "2029-12-25",
  "2030-01-01",
  "2030-01-07",
  "2030-03-25",
  "2030-04-14",
  "2030-04-18",
  "2030-04-19",
  "2030-04-21",
  "2030-05-01",
  "2030-06-03",
  "2030-06-24",
  "2030-07-01",
  "2030-07-01",
  "2030-07-20",
  "2030-08-07",
  "2030-08-19",
  "2030-10-14",
  "2030-11-04",
  "2030-11-11",
  "2030-12-08",
  "2030-12-25",
];
const WORK_START = 8;
const WORK_END = 17;

const isHoliday = (date) => {
  const formattedDate = new Date().toISOString().split("T")[0];
  return excepcionesCalendarioHora.includes(formattedDate);
};
const isWeekEnd= (date) => {
  const day = date.getDay();
  return day === 6 || day === 0;
};
export const calcularDuracionMinutes = (fechaA, fechaB) => {
  const start = new Date(fechaA);
  const end = new Date(fechaB);
  // duracion en dias habiles a calcular partiendo de dos fechas
  let totalHours = 0;

  for (
    let currentDate = new Date(start); currentDate <= end; currentDate.setDate(currentDate.getDate() + 1)) {
    currentDate.setHours(0, 0, 0, 0);

    if (!isWeekEnd(currentDate) && !isHoliday(currentDate)) {
      
      const startHour =
        currentDate.getDate() === start.getDate()
          ? Math.max(WORK_START, start.getHours() + start.getMinutes() / 60)
          : WORK_START;
      const endtHour =
        currentDate.getDate() === end.getDate()
          ? Math.min(WORK_END, end.getHours() + end.getMinutes() / 60)
          : WORK_END;
          if(startHour < endtHour){
            totalHours += endtHour - startHour
          }
    }
  }
  return totalHours;
};

export const calcularDuracionV2 = (fechaA, fechaB, excludedDates) => {
  // duracion en dias habiles a calcular partiendo de dos fechas

  let a = moment(fechaA, "DD/MM/YYYY");
  let b = moment(fechaB, "DD/MM/YYYY");
  let diaSemanaInicio = moment(fechaA, "DD/MM/YYYY").isoWeekday();
  let result = b.diff(a, "days") + 1;
  if ((result >= 7 && diaSemanaInicio == 1) || diaSemanaInicio == 2) {
    //- // console.log('la fecha inicio es de un lunes, martes o miercoles');
    //- // console.log('DIAS DE DIFERENCIA CALENDARIO: ' + result);
    let diasFines = Math.floor(result / 7) * 2;
    //- // console.log('diasFinesdiasFines: ' + diasFines);
    result = result - diasFines;
  } else if (result >= 7) {
    //- // console.log('DIAS DE DIFERENCIA CALENDARIO: ' + result);
    let diasFines = Math.round(result / 7) * 2;
    //- // console.log('diasFinesdiasFines: ' + diasFines);
    result = result - diasFines;
  } else if (result < 4) {
    //- // console.log('result < 4');
    result = result;
  } else {
    let diaSemana = moment(fechaB, "DD/MM/YYYY").isoWeekday();
    if (diaSemana < 4) {
      result = result - 2;
      if (result == 0 || result == -1) {
        result = 1;
      }
    }
  }
  let diasFestivosEncontrados = 0;
  for (let i = 0; i < excludedDates.length; i++) {
    if (
      moment(excludedDates[i], "YYYY/MM/DD").isBetween(
        moment(fechaA, "DD/MM/YYYY"),
        moment(fechaB, "DD/MM/YYYY")
      )
    ) {
      diasFestivosEncontrados++;
    }
  }
  result = result - diasFestivosEncontrados;
  if (isNaN(result)) {
    return "";
  } else {
    // if(result > 0){
    //     return result + 1;
    // } else {
    //     return result
    // }
    return result;
  }
};

export const saveDataScrum = (route, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(API_ROUTE + route, data, headersRequest())
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateDataScrum = (route, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(API_ROUTE + route, data, headersRequest())
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteDataScrum = (route, data) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(API_ROUTE + route, { ...headersRequest() })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateLanes = (scrum, data) => {
  let dummie = { ...scrum.backlog.data };
  let lanePos = _.findIndex(scrum.backlog.data.lanes, function (o) {
    return o.id === data.laneId;
  });
  let cardPos = _.findIndex(
    scrum.backlog.data.lanes[lanePos].cards,
    function (o) {
      return o.id === data.id;
    }
  );
  if (cardPos != -1) {
    dummie.lanes[lanePos].cards[cardPos] = { ...data, update: true };
    return dummie;
  }
};
export const encry = (data) => {
  const encryData = CryptoJS.AES.encrypt(
    data,
    "accessCloudProjectDev"
  ).toString();
  return encryData;
};
export const desencry = (data, key) => {
  const bytes = CryptoJS.AES.decrypt(data, key);
  const dataDesencry = bytes.toString(CryptoJS.enc.Utf8);
  return dataDesencry;
};
export const getFileNamesInput = (fileList) => {
  if (!fileList.length) return "";
  let names = [];
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList.item([i]);
    names.push(file.name);
  }
  return names.join(", ");
};

export const getFilesSize = (fileList) => {
  if (!fileList.length) return 0;
  let sizes = [];
  for (let i = 0; i < fileList.length; i++) {
    const file = fileList.item([i]);
    sizes.push(file.size);
  }
  return sizes.reduce((prev, current) => prev + current);
};

export const checkDirectoryName = (allDirectory, newDirectory) => {
  //valores del nuevo directorio //
  let newDirectoryName = newDirectory.name;
  let newDirectoryParentId = newDirectory.parentDirectory.dataItem
    ? newDirectory.parentDirectory.dataItem.id
    : null;
  //filtrar de todos los directorios los que esten en la raiz con el parentId //

  if (!newDirectory.parentDirectory.dataItem) {
    let filterByParentRoof = allDirectory.filter((root) => {
      return root.isDirectory == true && root.parentId == newDirectoryParentId;
    });
    let checkNameinFilter = filterByParentRoof.filter(
      (rootName) => rootName.name === newDirectoryName
    );
    return checkNameinFilter;
  } else {
    if (newDirectory.parentDirectory.pathKeys.length == 1) {
      let filterByParentIdlvl1 = allDirectory.filter((lvl1) => {
        return lvl1.isDirectory == true && lvl1.id === newDirectoryParentId;
      })[0].items;
      let checkNameinFilterlvl1 = filterByParentIdlvl1.filter(
        (lvl1Name) => lvl1Name.name === newDirectoryName
      );
      return checkNameinFilterlvl1;
    } else if (newDirectory.parentDirectory.pathKeys.length == 2) {
      let rootId = newDirectory.parentDirectory.dataItem.raizId;
      let filterByParentIdlvl1 = allDirectory.filter(
        (root) => root.isDirectory == true && root.id == rootId
      )[0].items;
      let filterByParentIdlvl2 = filterByParentIdlvl1.filter(
        (lvl1) => lvl1.isDirectory == true && lvl1.id == newDirectoryParentId
      )[0].items; /* .filter((ftname)=> ftname.name === newDirectoryName) */
      let checkNameinFilterlvl2 = filterByParentIdlvl2.filter(
        (lvl2Name) => lvl2Name.name === newDirectoryName
      );
      return checkNameinFilterlvl2;
    } else if (newDirectory.parentDirectory.pathKeys.length == 3) {
      let rootId = newDirectory.parentDirectory.dataItem.raizId;
      let lvl1Id = newDirectory.parentDirectory.dataItem.nivel1Id;

      let filterByParentIdlvl1 = allDirectory.filter(
        (root) => root.isDirectory == true && root.id == rootId
      )[0].items;
      let filterByParentIdlvl2 = filterByParentIdlvl1.filter(
        (lvl1) => lvl1.isDirectory == true && lvl1.id == lvl1Id
      )[0].items;
      let filterByParentIdlvl3 = filterByParentIdlvl2.filter(
        (lvl2) => lvl2.isDirectory == true && lvl2.id == newDirectoryParentId
      )[0].items;
      let checkNameinFilterlvl3 = filterByParentIdlvl3.filter(
        (lvl3Name) => lvl3Name.name === newDirectoryName
      );
      return checkNameinFilterlvl3;
    } else if (newDirectory.parentDirectory.pathKeys.length == 4) {
      let rootId = newDirectory.parentDirectory.dataItem.raizId;
      let lvl1Id = newDirectory.parentDirectory.dataItem.nivel1Id;
      let lvl2Id = newDirectory.parentDirectory.dataItem.nivel2Id;

      let filterByParentIdlvl1 = allDirectory.filter(
        (root) => root.isDirectory == true && root.id == rootId
      )[0].items;
      let filterByParentIdlvl2 = filterByParentIdlvl1.filter(
        (lvl1) => lvl1.isDirectory == true && lvl1.id == lvl1Id
      )[0].items;
      let filterByParentIdlvl3 = filterByParentIdlvl2.filter(
        (lvl2) => lvl2.isDirectory == true && lvl2.id == lvl2Id
      )[0].items;
      let filterByParentIdlvl4 = filterByParentIdlvl3.filter(
        (lvl3) => lvl3.isDirectory == true && lvl3.id == newDirectoryParentId
      )[0].items;
      let checkNameinFilterlvl4 = filterByParentIdlvl4.filter(
        (lvl4Name) => lvl4Name.name === newDirectoryName
      );
      return checkNameinFilterlvl4;
    } else if (newDirectory.parentDirectory.pathKeys.length == 5) {
      let rootId = newDirectory.parentDirectory.dataItem.raizId;
      let lvl1Id = newDirectory.parentDirectory.dataItem.nivel1Id;
      let lvl2Id = newDirectory.parentDirectory.dataItem.nivel2Id;
      let lvl3Id = newDirectory.parentDirectory.dataItem.nivel3Id;

      let filterByParentIdlvl1 = allDirectory.filter(
        (root) => root.isDirectory == true && root.id == rootId
      )[0].items;
      let filterByParentIdlvl2 = filterByParentIdlvl1.filter(
        (lvl1) => lvl1.isDirectory == true && lvl1.id == lvl1Id
      )[0].items;
      let filterByParentIdlvl3 = filterByParentIdlvl2.filter(
        (lvl2) => lvl2.isDirectory == true && lvl2.id == lvl2Id
      )[0].items;
      let filterByParentIdlvl4 = filterByParentIdlvl3.filter(
        (lvl3) => lvl3.isDirectory == true && lvl3.id == lvl3Id
      )[0].items;
      let filterByParentIdlvl5 = filterByParentIdlvl4.filter(
        (lvl4) => lvl4.isDirectory == true && lvl4.id == newDirectoryParentId
      )[0].items;
      let checkNameinFilterlvl5 = filterByParentIdlvl5.filter(
        (lvl4Name) => lvl4Name.name === newDirectoryName
      );
      return checkNameinFilterlvl5;
    }
  }
};

export const getResourceNameById = (resList, resItm) => {
  //console.log("resname:"+JSON.stringify(resItm));
  for (let itm of resList) {
    if (resItm == itm.key) {
      return itm.label;
    }
  }
  return "X";
};

export const getResourceNames = (resList, resKeys) => {
  let assigned = "";
  if (resKeys != null && resKeys.length > 0) {
    for (let key of resKeys) {
      if (assigned.length == 0)
        assigned += getResourceNameById(resList, key.resource_id);
      else assigned += "/" + getResourceNameById(resList, key.resource_id);
    }
  }
  //console.log("assigned:"+JSON.stringify(assigned));
  return assigned;
};
