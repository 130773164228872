import React from "react";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { selectTooltip , selectTooltip_en } from "../utils/selectTooltip";
import { useTranslation } from "react-i18next";

const StyledGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media (max-width: 991px) {
    grid-template-columns: 1fr 1fr;
  }
  grid-gap: 1rem;
  .cursor-pointer {
    cursor: pointer;
  }
  .card {
    height: 115px;
    transition: all ease 0.3s;
    &:hover {
      box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
    }
    h6 {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

/* const arrColors = [
  "#0079bf",
  "#d29034",
  "#519839",
  "#b04632",
  "#89609e",
  "#cd5a91",
  "#4bbf6b",
  "#00aecc",
  "#838c91",
]; */

const BoardsPanel = (props) => {
  const [t,i18n] =useTranslation("boards")
  return (
    <StyledGrid>
      {(props.addCard) &&
      <Card
        className={`border-0 rounded bg-light text-center ${
          props.data.length < 100 ? "cursor-pointer" : "text-muted"
        }`}
        onClick={() => {
          if (props.data.length < 100) {
            props.handleShow();
          }
        }}
      >
        <Card.Body className="p-3" data-tip={(t("boards_lenguaje") == "es")? selectTooltip(28): selectTooltip_en(28)}>
          <i
            className={`fa ${
              props.data.length < 100 ? "fa-plus-circle" : "fa-minus-circle"
            }`}
          ></i>
          <h6 className="mb-0">{t("boards_buttoncreate")}</h6>
          {props.data.length === 100 ? (
            <small>{t("boards_maxboards")}</small>
          ) : (
            <small> {t("boards_textremainder")} ( {props.data.length} ) {t("boards_textremainder_1")}</small>
          )}
        </Card.Body>
      </Card>
      }
      {props.data.map((element,idx) => {
        return (
          <Card
          key={idx}
            // as={Link}
            // to={`/planning-board/${element.IdProyecto}`}
            className="rounded border-0 text-white"
            // style={{ background: arrColors[Math.round(Math.random() * 8)] }}
            style={{ background: element.KanbanColor || "#cd5a91" }}
          >
            <Card.Body className="px-3 py-2 shadow-sm">
              <Link
                to={`/kanban-board/${element.IdProyecto}/${element.SprintId}`}
                className="text-white"
              >
                <h6 className="mb-0 text-white">{element.Nombre}</h6>
                <small className="text-white">
                  {element.CantidadActividades || 0}{" "}
                  {element.CantidadActividades === 1
                    ? t("boards_textactivity")
                    : t("boards_textactivities")}
                </small>
                {(element.PorCompletado === 100) &&
                <>
                  <span className="mr-2 d-block" >
                    <i className="fa fa-square text-success"></i> Done
                  </span>
                </>
                }
                {(element.PorCompletado === 0 ||element.PorCompletado == null) && 
                <>
                 <span className="mr-2 d-block">
                  <i className="fa fa-square text-light"></i> To Do
                  </span>
                </>
                }
                {(element.PorCompletado > 0 && element.PorCompletado < 100) && 
                <>
                 <span className="mr-2 d-block">
                  <i className="fa fa-square text-info"></i> Doing
                  </span>
                </>}
              </Link>
              {/* <Link to={`/planning-board/${element.IdProyecto}`}>Ver más</Link> */}
              {/* <br /> */}
              { (element.CreadoPorUsuario === 1) && 
                <div className="d-flex justify-content-between mt-2 w-100">
                  <i
                  className="fa fa-edit cursor-pointer"
                  onClick={() => {
                    props.selectBoardToEdit(element);
                  }}
                  ></i>
                  <i
                    className="fa fa-trash cursor-pointer"
                    onClick={() => {
                      props.deleteBoard(element);
                    }}
                  ></i>
                </div>
              }
            </Card.Body>
          </Card>
        );
      })}
    </StyledGrid>
  );
};

export default BoardsPanel;
