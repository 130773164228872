import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Button,
  Card,
  Col,
  Container,
  Dropdown,
  Form,
  Modal,
  Nav,
  ProgressBar ,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import TitleCommon from "../components/TitleCommon";
import Layout from "../Layout";
import { StyledTabs } from "../components/StyledCommon";
import { getData, saveData, inputChange, alreadyExists, desencry, calcularDuracionV2 } from "../helpers";
import SuperTable from "../components/SuperTable";
import moment from "moment";
import _ from "lodash";
import { Link, useParams } from "react-router-dom";
import swal from "sweetalert";
import FormProjectGeneral from "../components/FormProjectGeneral";
import Tachometer from "../components/Tachometer";
import ProgressIndicator from "../components/ProgressIndicator";
import styled from "styled-components";
import GanttScheduleRO from "../components/GanttScheduleRO";
import HvActivitiesResume from "../components/HvActivitiesResume";
import NoAccess from "./NoAccess";
import GanttDx from "../components/GanttDx/GanttDx";
import { useTranslation } from "react-i18next";
import { CurvaSCosto } from "../components/HvTraditionalReport/CurvaSCosto";

const StyledIndicator = styled.div`
  position: absolute;
  top: 0.5rem;
  right: -0.5rem;
`;

const HvProject = () => {
  const [t,i18n] =useTranslation("schedule")
  const access = window.localStorage.getItem("accessToHvProject")
  const desData = desencry(access,'accessCloudProjectDevHvProjecte')

  const [mainData, setMainData] = useState({ new: true });
  const [activitiesList, setActivitiesList] = useState([]);
  const [successByResource, setSuccessByResource] = useState([]);
  const [excludeDates, setExcludeDates]= useState([])
  // const [users, setUsers] = useState([]);
  let { uid } = useParams();

  const getAllData = () => {
    Promise.all([
      getData(`projects/${uid}`, []),
      getData(`hv-project-activities/${uid}`, []),
      getData(`hv-project-header/${uid}`, {}),
      getData(`hv-project-success-by-resource/${uid}`, {}),
      getData(`excluded-dates`,{})
      // getData(`users`, []),
    ]).then((values) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setMainData({ ...values[0], ...values[2] });
        setActivitiesList(values[1]);
        setSuccessByResource(values[3]);
        setExcludeDates(values[4])
        // setUsers(values[3]);
      });
    });
  };

  useEffect(() => {
    if (uid) {
      getAllData();
    }
  }, []);

  let durationAllProject = () => {
    let startDate= mainData.FechaInicioProg 
    let endDate= mainData.FechaFinProg
    return calcularDuracionV2(startDate,endDate,excludeDates)
  }

  const idOrganization1ITS = '40200F8F-15A6-42C0-BE58-16EDBADA514C'
  const idOrganization2PAS = '8CAADED8-A836-4CB3-8822-878440CC4E30'
  const idOrganization = window.localStorage.getItem('OID')

  const showTac = (idOrganization == idOrganization1ITS || idOrganization == idOrganization2PAS) ? true  : false
  return (<>
    { (desData ==="true" ) ? 
    <React.Fragment>
      <Layout>
        <TitleCommon
          title={{
            text: t("hvproject_tittle"),
            icon: "tachometer-alt",
          }}
          color="secondary"
         /*  button={{
            text: "Volver",
            icon: "arrow-left",
            url: "/projects",
          }} */
          goBack={{
            text: t("schedule_back"),
            icon: "arrow-left",
          }}
        />
        <Container fluid className="bg-light">
          <Row>
            <Col className="py-3">
              <h5 className="mb-0">
                <span className="text-primary">{t("hvproject_nameproject")} </span>
                <span>{mainData.Nombre}</span>
              </h5>
              {/* <div className="d-flex justify-content-between">
                <small>
                  <i className="fa fa-play mr-2 text-secondary"></i>Inicio:
                  12/12/12
                </small>
                <small>
                  <i className="fa fa-flag-checkered mr-2 text-secondary"></i>
                  Fin: 12/12/12
                </small>
              </div> */}
              {/* <hr className="mb-0" /> */}
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex justify-content-between mb-2">
                <small>
                  <i className="fa fa-play mr-2 text-secondary"></i>{t("hvproject_start")}{" "}
                  {mainData.FechaInicioProg || "-"}
                </small>
                <small>
                  <i className="fa fa-flag-checkered mr-2 text-secondary"></i>
                  {t("hvproject_end")} {mainData.FechaFinProg || "-"}
                </small>
              </div>
              {/* <ProgressBar
                style={{ height: 10 }}
                className="mb-1"
                variant="info"
                now={mainData.PctPlaneadoProyecto || 0}
                label={
                  <small>{`Plan. ${
                    mainData.PctPlaneadoProyecto || 0
                  }%`}</small>
                }
                key={2}
              />
              <ProgressBar
                style={{ height: 10 }}
                className="mb-2"
                variant="success"
                now={mainData.PctCompletadoProyecto || 0}
                label={
                  <small>{`Comp. ${
                    mainData.PctCompletadoProyecto || 0
                  }%`}</small>
                }
                key={1}
              /> */}
            </Col>
          </Row>
        </Container>
       {/*  <GanttDx uid={uid}/> */}
        <Container className="pt-2">
          <Card className="bg-white border-0 position-relative mb-3 shadow-sm">
            <Card.Body className="px-2 pt-2 pb-0">
              <Row>
                <Col md={6} lg={2} className="mb-2">
                  <Card className="carrrrrd">
                    <Card.Body className="py-2 d-flex flex-column">
                      <span className="h6 mb-0">
                        {mainData.CODProyecto || "-"}
                      </span>
                      <small className="text-primary">{t("hvproject_code")}</small>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={3} className="mb-2">
                  <Card className="carrrrrd">
                    <Card.Body className="py-2 d-flex flex-column">
                      <span className="h6 mb-0">
                        {mainData.Propietario || "-"}
                      </span>
                      <small className="text-primary">{t("hvproject_owner")}</small>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={2} className="mb-2">
                  <Card className="carrrrrd">
                    <Card.Body className="py-2 d-flex flex-column">
                      <span className="h6 mb-0">
                        {mainData.FechaModificado || "-"}
                      </span>
                      <small className="text-primary">{t("hvproject_modified")}</small>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={3} className="mb-2">
                  <Card className="carrrrrd">
                    <Card.Body className="py-2 d-flex flex-column">
                      <span className="h6 mb-0">
                        { uid !=='7CF05F10-236D-49E8-BB3B-2ABAC1F777E1'? (durationAllProject() || "-" ):"109 d" }
                        {/* {durationAllProject() || "-"} d */}
                      
                      </span>
                      <small className="text-primary">{t("schedule_duration")}</small>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={2} className="mb-2">
                  <Card className="carrrrrd">
                    <Card.Body className="py-2 d-flex flex-column">
                      <span className="h6 mb-0">{mainData.Gerente || "-"}</span>
                      <small className="text-primary">{t("hvproject_manager")}</small>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
             {/*  <Row>
              <Col md={6} lg={4} className="mb-2">
                  <Card className="carrrrrd">
                    <Card.Body className="py-2 d-flex flex-column">
                      <span className="h6 mb-0">{mainData.Gerente || "-"}</span>
                      <small className="text-primary">{t("hvproject_manager")}</small>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={4} className="mb-2">
                  <Card className="carrrrrd">
                    <Card.Body className="py-2 d-flex flex-column">
                      <span className="h6 mb-0">{mainData.Gerente || "-"}</span>
                      <small className="text-primary">{t("hvproject_manager")}</small>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={4} className="mb-2">
                  <Card className="carrrrrd">
                    <Card.Body className="py-2 d-flex flex-column">
                      <span className="h6 mb-0">{mainData.Gerente || "-"}</span>
                      <small className="text-primary">{t("hvproject_manager")}</small>
                    </Card.Body>
                  </Card>
                </Col>
              </Row> */}
            </Card.Body>
          </Card>
          <Row>
            <Col className="mb-3" md={12} lg={6}>
              <Card className="mb-3">
                <Card.Body className="p-3">
                  <Card.Title>{t("hvproject_assigned")}</Card.Title>
                  <span className="h2 mb-0 mt-2 d-block">
                    <i className="fa fa-wallet mr-2 text-primary"></i>$
                    {new Intl.NumberFormat().format(
                      mainData.CostoPlaneado || 0
                    )}
                  </span>
                </Card.Body>
              </Card>
              <h5 className="text-muted mb-3">{t("hvproject_summarycost")}</h5>
              <h6>
                {t("hvproject_chartbar_plannedcost")} ($
                {new Intl.NumberFormat().format(
                  mainData.CostoPlaneado || 0
                )}) <span className="float-right">100%</span>
              </h6>
              <ProgressBar variant="info" now={100} style={{ height: "7px" }} />
              <br />
              <h6>
                {t("hvproject_chartbar_completedcost")} ($
                {new Intl.NumberFormat().format(mainData.CostoReal || 0)}){" "}
                <span className="float-right">
                 {
                  ((Math.round((((mainData.CostoReal * 100) / mainData.CostoPlaneado) + Number.EPSILON) * 100 )) / 100) || 0
                 }
                  %
                </span>
              </h6>
              <ProgressBar
                variant="success"
                now={(mainData.CostoReal * 100) / mainData.CostoPlaneado || 0}
                style={{ height: "7px" }}
              />
            </Col>
            <Col className="mb-3" md={12} lg={6}>
                <div className="d-flex flex-column">
                  <div className="d-flex">
                  <Col md={6} lg={6}  className={`mb-3 ${showTac ? "d-none":""}`}   >
                  <Card>
                    <Card.Body>
                      <Card.Subtitle className="text-muted">
                        {t("hvproject_tachometer1_tittle")}
                      </Card.Subtitle>
                      <div style={{ maxWidth: "210px", margin: "0 auto" }}>
                        <Tachometer
                          value={Math.round(
                            +mainData.PctCompletado - +mainData.PctPlaneado
                          )}
                        />
                        <StyledIndicator className="rounded py-1 px-2 bg-white shadow-sm border">
                          <small className="font-weight-bold">
                            <i
                              className={`fa fa-circle mr-2 ${(() => {
                                let desv = Math.round(
                                  +mainData.PctCompletado - +mainData.PctPlaneado 
                                );
                                if (desv < -25) {
                                  return "text-danger";
                                }
                                if (desv < 0) {
                                  return "text-warning";
                                }
                                return "text-success";
                              })()}`}
                            ></i>
                            {Math.round(
                              +mainData.PctCompletado - +mainData.PctPlaneado
                            )}
                            %
                          </small>
                        </StyledIndicator>
                        {/* <h6 className="text-center"></h6> */}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={showTac? 12 : 6} className="mb-3">
                  <Card>
                    <Card.Body>
                      <Card.Subtitle className="text-muted">
                        {t("hvproject_tachometer2_tittle")}
                      </Card.Subtitle>
                      <div style={{ maxWidth: "230px", margin: "0 auto" }}>
                        <ProgressIndicator
                          data={[+mainData.PctPlaneado, +mainData.PctCompletado]}
                          labels={[t("hvproject_tachometer2_convention_planned"), t("hvproject_tachometer2_convention_completed")]}
                        />
                        {/* <h6 className="text-center">25% (Al día)</h6> */}
                      </div>
                    </Card.Body>
                  </Card>
                </Col>

                  </div>
              { (idOrganization == idOrganization1ITS || idOrganization == idOrganization2PAS) && 
            
              <div className="d-flex">
                <Col md={6} lg={4} className="mb-2">
                  <Card className="carrrrrd">
                    <Card.Body className="py-2 d-flex flex-column align-items-center">
                      <span className="h6 mb-0">{/* <b>86.1 %</b> */}<b>{mainData.IndicDesempProy} %</b></span>
                      <small className="text-dark text-center">Indice desempeño planeación</small>
                    </Card.Body>
                  </Card>
                  
                </Col>
                <Col md={6} lg={4} className="mb-2">
                  <Card className="carrrrrd">
                    <Card.Body className="py-2 d-flex flex-column align-items-center">
                      <span className="h6 mb-0">{/* <b>13.86 %</b> */}<b>{mainData.IneficienciaPlaneacion} %</b></span>
                      <small className="text-dark text-center">Ineficiencia planeación</small>
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={6} lg={4} className="mb-2">
                  <Card className="carrrrrd">
                    <Card.Body className="py-2 d-flex flex-column align-items-center">
                      <span className="h6 mb-0">{/* <b>8.5 días</b> */}<b>{mainData.DiasImpactoCierreProyecto} días</b></span>
                      <small className="text-dark text-center">Impacto en el cierre del proyecto</small>
                    </Card.Body>
                  </Card>
                </Col>
                </div>
                }
              </div>
            </Col>
          </Row>
          {/*  <Card className="mb-3">
            <Card.Body>
              <CurvaSCosto/>
            </Card.Body>
          </Card> */}
          <Card className="mb-3">
            <Card.Body>
              <Card.Title className="h6 text-muted">
                {t("hvproject_summaryactivities")}
              </Card.Title>
              <HvActivitiesResume title_search = {t("schedule_modal_resources_searchbox")} data={activitiesList || []} />
            </Card.Body>
          </Card>
          {activitiesList && (
            <Card className="mb-3">
              <Card.Body>
                <Card.Title className="h6 text-muted">Gantt</Card.Title>
                {/* <ActivitiesGantt activitiesList={activitiesList} /> */}
                <GanttScheduleRO uid={uid} />
              </Card.Body>
            </Card>
          )}
          <Card className="mb-5">
            <Card.Body>
              <Card.Title className="h6 text-muted">
                {t("schedule_modal_resources_button_complianc")}
              </Card.Title>
              {/* <HvActivitiesResume data={activitiesList || []} /> */}
              <SuperTable
                columns={[
                  {
                    caption: t("schedule_modal_resources_compliance_tablecolumn_resource"),
                    selector: "Recurso",
                  },
                  {
                    caption: "Hrs. Plan.",
                    selector: "HorasxRecursoPlan",
                    customRender: (row) => (
                      <span>{Math.round(row.data.HorasxRecursoPlan)} Hrs.</span>
                    ),
                  },
                  {
                    caption: "Hrs. Comp.",
                    selector: "HorasxRecursoEjec",
                    customRender: (row) => (
                      <span>{Math.round(row.data.HorasxRecursoEjec)} Hrs.</span>
                    ),
                  },
                  {
                    caption: t("schedule_modal_resources_compliance_tablecolumn_compliance"),
                    customRender: (row) => (
                      <span className="d-flex align-items-center justify-content-end">
                        <ProgressBar
                          variant="success"
                          now={Math.round(
                            (row.data.HorasxRecursoEjec * 100) /
                              row.data.HorasxRecursoPlan
                          )}
                          style={{ height: "10px", width: "calc(100%)" }}
                          className="mr-2"
                        />
                        <b>
                          {Math.round(
                            (row.data.HorasxRecursoEjec * 100) /
                              row.data.HorasxRecursoPlan
                          )}
                          %
                        </b>
                      </span>
                    ),
                  },
                ]}
                data={successByResource}
                title_search = {t("schedule_modal_resources_searchbox")}
              />
            </Card.Body>
          </Card>
        </Container>
      </Layout>
    </React.Fragment>
     :<>
     <NoAccess />
     </>
     }
    </>
  );
};

export default HvProject;
