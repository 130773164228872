import React, { useState, useEffect } from "react";
import { MapContainer, TileLayer, Marker, useMap,Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import Select from "react-select";
import project  from '../../assets/iconFolders/proyecto.png'
import initiative from '../../assets/iconFolders/iniciativa.png'
import axios from "axios";
import { API_ROUTE, headersRequest } from "../../helpers";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

// Función para crear íconos personalizados basados en la ubicación
const getIcon = (cor) => {

    const iconUrl = cor.tipo = "initiativa" ? initiative : project
    return new L.Icon({
        iconUrl,
        iconSize: [30, 30], // Tamaño del ícono
        iconAnchor: [15, 45],
        popupAnchor: [0, -45],
        shadowUrl: "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
        shadowSize: [10,24 ],
        shadowAnchor: [4, 62]
      });
}

// Coordenadas predeterminadas (Colombia)
const defaultCoordinates = [
    { lat: 4.60971, lon: -74.08175, label: "Bogotá" },
    { lat: 6.25184, lon: -75.56359, label: "Medellín" },
    { lat: 3.43722, lon: -76.5225, label: "Cali" },
    { lat: 11.24079, lon: -74.21102, label: "Barranquilla" }
  ];

  const defaultCoordinates_2 = [
    { lat: 4.60971, lon: -74.08175, label: "Bogotá" },
    { lat: 6.25184, lon: -75.56359, label: "Medellín" },
    { lat: 3.43722, lon: -76.5225, label: "Cali" },
    { lat: 11.24079, lon: -74.21102, label: "Barranquilla" }
  ];

  const coor_3 = [ 
    {IdIniciativa: '980E8C3E-0775-EF11-9C35-000D3A9E8DB0', Departamento: 'ANTIOQUIA', Municipio: 'MEDELLÍN', lat: 6.258455, lon: -75.61115, tipo: "iniciativa" },
    {IdIniciativa: '0BE92A10-0875-EF11-9C35-000D3A9E8DB0', Departamento: 'ANTIOQUIA', Municipio: 'MEDELLÍN', lat: 6.258455, lon: -75.61115, tipo: "iniciativa"  },
    {IdIniciativa: '45A59C63-0975-EF11-9C35-000D3A9E8DB0', Departamento: 'ANTIOQUIA', Municipio: 'MEDELLÍN', lat: 6.258455, lon: -75.61115, tipo: "iniciativa"  },
    {IdIniciativa: 'BA66FCBB-F679-EF11-9C35-00224821AE2D', Departamento: 'BOLÍVAR', Municipio: 'CARTAGENA DE INDIAS', lat: 10.44315, lon: -75.46431, tipo: "iniciativa" },
    {IdIniciativa: '8F127E2D-527B-EF11-8473-0022482503F1', Departamento: 'BOLÍVAR', Municipio: 'CARTAGENA DE INDIAS', lat: 10.44315, lon: -75.46431, tipo: "iniciativa" },
    {IdIniciativa: '149BD145-527B-EF11-8473-0022482503F1', Departamento: 'LA GUAJIRA', Municipio: 'MAICAO', lat: 11.38082, lon: -72.29556, tipo: "iniciativa" },
    {IdIniciativa: 'D33B1833-9581-EF11-8473-00224831DE34', Departamento: 'LA GUAJIRA', Municipio: 'MAICAO', lat: 11.38082, lon: -72.29556, tipo: "iniciativa" },
    {IdProyecto: 'C03F9478-83B0-4D43-8741-2D31A613614C', Departamento: 'ANTIOQUIA', Municipio: 'MEDELLÍN', lat: 6.258455, lon: -75.61115, tipo: "proyecto" }]

  function FitBounds(coordinates) {
    const map = useMap();
  
    useEffect(() => {
      if (coordinates.length > 0) {
        const bounds = coordinates.map(coord => [coord.lat, coord.lon]);
        map.fitBounds(bounds, { padding: [50, 50] });
      }
    }, [coordinates, map]);
  
    return null;
  }
  

const InitiativesMap = () => {
   /*  const [coordinates, setCoordinates] = useState([{ lat: 4.60971, lon: -74.08175, label: "Bogotá" }]); */
    const [coordinates, setCoordinates] = useState([5.6278979, -72.8268617]);
    const [coordinates_2, setCoordinates_2] = useState([8.07761875, -72.46890019811272]);
    const [ coor, setCoor] = useState(defaultCoordinates_2)
    const [allCoord, setAllCoord] = useState([])
    
    /* const [coordinates, setCoordinates] = useState(defaultCoordinates); */ // Coordenadas iniciales (Bogotá)
     // Coordenadas iniciales (Bogotá)
    const [locationOptions, setLocationOptions] = useState([]);

  const getDashboardInitiatives = async()=>{ 
    try {
        const res = await axios.get(`${API_ROUTE}dashboard-initiatives`, headersRequest())
        console.log(res.data)
        setAllCoord(res.data)
    } catch (error) {
      
    }
  }
      // Función para obtener sugerencias de Nominatim
  const fetchLocationOptions = async (inputValue) => {
    if (!inputValue) return [];

    const url = `https://nominatim.openstreetmap.org/search?format=json&q=${inputValue}&countrycodes=co&limit=5`;
    const response = await fetch(url);
    const data = await response.json();

    // Mapeamos las opciones para que React Select las entienda correctamente
    return data.map((item) => ({
      value: [parseFloat(item.lat), parseFloat(item.lon)],
      label: `${item.display_name}`,
    }));
  };

  const handleInputChange = (inputValue) => {
    if (inputValue.length > 2) {
      // Cargar las sugerencias solo cuando hay más de 2 caracteres para evitar muchas peticiones
      fetchLocationOptions(inputValue).then((options) => {
        setLocationOptions(options);
      });
    }
  };

    // Función para manejar la selección de ubicación
  const handleSelectChange = (selectedOption) => {
    setCoordinates(selectedOption.value);
  };

  useEffect(()=>{
    getDashboardInitiatives()
  },[])

  
  return (
    <>
        {/* Componente de Autocompletado usando Mapbox */}
        {/* Dropdown de autocompletado usando React Select */}
        {/* <Select
            className="mb-4 w-60"
            onInputChange={handleInputChange}
            onChange={handleSelectChange}
            options={locationOptions}
            placeholder="Ingresa ciudad o municipio"
        /> */}

        {/* Muestra las coordenadas seleccionadas */}

        {/* Mapa */}
        <MapContainer center={[4.5709, -74.2973]} zoom={5} style={{ height: "500px", width: '90%' , borderRadius:'30px'}}>
        <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        
        {allCoord?.map((cor,idx)=>{
          return <Marker
          key={idx}
          position={[cor.lat,cor.lon]}
          icon={getIcon(cor)} 
          title={cor.label}>
            <Popup>
              <span>{cor.label}</span> 
            </Popup>
          </Marker>
        })}
       {/*  <Marker position={coordinates}></Marker> */}
        {/* <Marker position={coordinates_2}></Marker> */}
          <FitBounds coordinates={coordinates_2} />
        </MapContainer>
{/* 
    <MapContainer center={[4.60971, -74.08175]} zoom={6} style={{ height: "400px", width: "100%" }}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        
        {coordinates.map((coord, index) => (
          <Marker 
            key={index} 
            position={[coord.lat, coord.lon]} 
            icon={getIcon(coord.label)} 
            title={coord.label}>
            <Popup>
              <span>coord.label</span> 
            </Popup>
          </Marker>
        ))}

        <FitBounds coordinates={coordinates} />
      </MapContainer> */}
    </>
  )
}

export default InitiativesMap