import React, { useState, useEffect} from "react";
import { Button, Col, Container, Dropdown, Form, Row } from "react-bootstrap";
import SuperTableV2 from "../SuperTableV2";
import { formatDateToInput } from "./helpers_MGI";
import axios from "axios";
import { API_ROUTE, headersRequest } from "../../helpers";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import ModalForceRating from "./ModalForceRating";

const Rating_Wait = (props) =>{
    const [openModal, setOpenModal] = useState(false);
    const [tipoCalificacion, setTipoCalificaion] = useState("Automatico");
    const [initiatives, setInitiatives] = useState([]);
    const [initiative, setInitiative] = useState([]);
    const [cbxSelected, setCbxSelected] = useState([]);
    const [t,i18n] = useTranslation("initiatives");
    const [update, setUpdate] = useState(true);

    const handleOpenModal = (setState) => {
      setState((val) => (val = !val));
    };

    useEffect(() => {
        console.log(tipoCalificacion);
    },[tipoCalificacion]);

    const handleCheckboxChange = (rowId) => {
      setCbxSelected((prevSelectedIds) => {
        if (!Array.isArray(prevSelectedIds)) {
            console.error('El estado cbxSelected no es un array:', prevSelectedIds);
            return [rowId];
        }
        // Alternar el estado del checkbox
        const newSelectedIds = prevSelectedIds.includes(rowId)
            ? prevSelectedIds.filter(id => id !== rowId) // Eliminar si est seleccionado
            : [...prevSelectedIds, rowId]; // Agregar si no esta seleccionado

        console.log(`Checkbox toggled for row: ${rowId}`, newSelectedIds); // print ids selected
        return newSelectedIds;
    });
    };

    const SaveInitiativesForRating = async (data) =>{
      console.log(data)
      try {
          const res = await axios.post(`${API_ROUTE}auto_rating`,{data}, headersRequest());
          /* setCbxSelected([]) */
          props.setUpdate(!props.update)
      } catch (error) {
          console.log(error);
      }

  };
    
    const handleDropdownSelect = async (Id, option) => {
      const iniciativa = initiatives?.filter((item) => item.Id === Id)[0];
      setInitiative(iniciativa);
      console.log(iniciativa)
      const nombre = iniciativa.NombreIniciativa;
      if(option === 'go'){
        const result = await Swal.fire({
          title: 'Forzar Calificación',
          text: `¿Está seguro de forzar la calificación de esta Iniciativa : [${nombre}] ?`,
          icon: 'question',
          confirmButtonText: "Si, Estoy seguro!",
          cancelButtonText: "No, Cancelar",
          showCancelButton: true,
          confirmButtonColor: '#db526a',
        });
  
        if(result.isConfirmed){
          console.log("confirmo forzar ",Id)
          handleOpenModal(setOpenModal);
        } else{
          console.log("cancelar")
        }
      } if(option === 'back'){
        const result2 = await Swal.fire({
          title: 'Devolver a Estructuración',
          text: `¿Está seguro desea devolver a Estructuración esta Iniciativa : [${nombre}] ?`,
          icon: 'question',
          confirmButtonText: "Si, Estoy seguro!",
          cancelButtonText: "No, Cancelar",
          showCancelButton: true,
          confirmButtonColor: '#db526a',
        });
  
        if(result2.isConfirmed){
          console.log("confirmo devolver ",Id)
        } else{
          console.log("cancelar")
        }
      }
    };

    const idsToFilter = [
      '3AEDC141-1E04-4411-8FC9-71A6CA13BEC4', //En Calificación
    ];
  
    const getAllInitiatives = async () => {
      try {
        const res = await axios.get(`${API_ROUTE}listariniciativas`, headersRequest());
        const formatDates = res.data.map(initiative => ({
          ...initiative,
          FechaIdentificacion : formatDateToInput(initiative.FechaIdentificacion),
          FechaCreacion : formatDateToInput(initiative.FechaCreacion),
        }));
  
        const filteredInitiatives = formatDates.filter(d => idsToFilter.includes(d.IdEstado));
        console.log(filteredInitiatives);
        setInitiatives(filteredInitiatives);
      } catch (error) {
        console.error(error);
      }
    };

    useEffect(() => {
      getAllInitiatives();
    },[props.update]);

    const columns = [
        {
          caption: "Nombre",
          selector: "NombreIniciativa",
          width: 250,
          customRender: (row) => {
            return (
              <div className="d-flex align-items-center dropdown-in-table">
                {tipoCalificacion === "Automatico" ? (
                  <Form.Control
                    type="checkbox"
                    style={{width:'17px', height:'17px'}}
                    onChange={() => handleCheckboxChange(row.data.Id)}
                  />
                ) : tipoCalificacion === "Manual" ? (
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="outline-primary"
                      size="sm"
                      className="dropdown-btn-ellipsis"
                    >
                      <i className="fa fa-ellipsis-h"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => handleDropdownSelect(row.data.Id, "go")}
                        title={(t("iniciativas_lenguaje") == "es")? "La Iniciativa no pasará el proceso normal de priorización, deberás Justificar la razón" : "The Initiative will not pass the normal prioritization process, you must Justify the reason"}
                      >
                        Forzar Calificación
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => handleDropdownSelect(row.data.Id, "back")}
                        title={(t("iniciativas_lenguaje") == "es")? "La Iniciativa será devuelta a Estructuración" : "The Initiative will be returned to Structuring"}
                      >
                        Devolver a Estructuración
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                ) : null}
                <span
                    className="ml-2"
                    style={{ maxWidth: "340px" }}
                    title={row.data.NombreIniciativa}
                >
                    {row.data.NombreIniciativa}
                </span>
              </div>
            );
          },
        },
        {
          caption: "Descripción",
          selector: "Descripcion",
          width: 250,
        },
        {
          caption: "Fecha Prevista de Inicio",
          selector: "FechaIdentificacion",
          width: 110,
        },
        {
          caption: "Fecha de Creación",
          selector: "FechaCreacion",
          width: 110,
        },
        {
          caption: "Responsable",
          selector: "CreadoPor",
          width: 110,
        },
        {
          caption: "Estado",
          selector: "Estado",
          width: 110,
        },
    ];

    useEffect(()=>{
      getAllInitiatives();
    },[]);

    return (
        <>
            <Container fluid>
                <Row>
                    <Col md={2}>
                        <Form.Label style={{ fontWeight: "500" }}>Tipo de Calificación</Form.Label>
                        <Form.Control 
                            as="select" 
                            value={tipoCalificacion} 
                            onChange={(e) => setTipoCalificaion(e.target.value)}
                        >
                            <option selected disabled value="">Seleccione una opción</option>
                            <option value="Automatico">Automático</option>
                            <option value="Manual">Manual</option>
                        </Form.Control>
                    </Col>
                    <Col md={2} className="d-flex align-items-end">
                        {tipoCalificacion === "Automatico" ? (
                            <>
                                <Button
                                    className="btn btn-primary btn-sm ml-2"
                                    onClick={() => SaveInitiativesForRating(cbxSelected)}
                                >
                                    Ir a Calificar
                                </Button>
                            </>
                            ): null
                        }
                    </Col>
                </Row>
                <hr color="#db526a"
                    style={{opacity:0.5}}
                    className="my-3"
                />
                <Row>
                    <Col md={12}>
                        <SuperTableV2
                            columns={columns}
                            data={initiatives}
                        />
                    </Col>
                </Row>
            </Container>
            <ModalForceRating
              openModal={openModal}
              setOpenModal={setOpenModal}
              handleOpenModal={handleOpenModal}
              initiative={initiative}
              setInitiative={setInitiative}
              update={update}
              setUpdate={setUpdate}
            />
        </>
    );
};
export default Rating_Wait