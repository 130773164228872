import moment from "moment";

import React, { useState, useEffect, useMemo, useRef, lazy, Suspense } from "react";
import { Modal, Button, Form, Row, Col, Table, ModalHeader } from "react-bootstrap";
/* import ReactDatePicker from "react-datepicker";
import { registerLocale } from 'react-datepicker'; */
import 'react-datepicker/dist/react-datepicker.css';
import ModalSelectResources from "../ModalSelectResources";
import { useSelector, useDispatch } from "react-redux";
import {
  calcularDuracion,
  getFileNamesInput,
  getFilesSize,
  getData,
  inputChange,
  saveDataScrum,
  updateDataScrum,
  deleteDataScrum,
  calcularDuracionMinutes
} from "../../../helpers";
import { selectTooltip ,selectTooltip_en } from "../../../utils/selectTooltip";
import ReactTooltip from "react-tooltip";
import SelectInModal from "../SelectInModal";
import { downloadAttachment, deleteAttachments, getAttachments, uploadAttachments } from "../../../services/tasks.services";
import swal from "sweetalert";
import useTaskFiles from "./hooks/useTaskFiles";
import { getTaskFilesAdapter } from "../../../adapters/tasks.adapters";
import ModalSelectTags from "../ModalSelectTags";
import { useTranslation } from "react-i18next";

import ReactDatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import { es } from 'date-fns/locale';
import 'react-datepicker/dist/react-datepicker.css';
import { format, getHours, getMinutes, setHours, setMinutes } from 'date-fns'
import { toZonedTime } from "date-fns-tz"
registerLocale('es',es)

const ModalAvancedOptions = lazy(()=> import('../ModalAvancedOptions'))

// moment().tz("America/Los_Angeles");



const ModalEditTask = (props) => {
  const ID_ORGANIZACION = "1727EA57-9E8F-44CE-938B-8F377491F135"  // Produccion
  /* const ID_ORGANIZACION = "8CAADED8-A836-4CB3-8822-878440CC4E30"  *///desarrollo
  const [t,i18n] =useTranslation("planning")
  const prevLaneId = useMemo(() => props.data.current.laneId);
  const [taskData, setTaskData] = useState({});
  const [showResources, setShowResources] = useState(false);
  const [showTags, setShowTags] = useState(false);
  const [showSelectHus, setShowSelectHus] = useState(false);
  const [selectedResources, setSelectedResources] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [filesName, setFilesName] = useState("");
  const [uploadingFiles, setUploadingFiles] = useState(false);
  const [files, dispatchFiles] = useTaskFiles();
  const [excludeDates, setExcludeDates] = useState([]);
  const [showTrack, setShowTrack]= useState(false)
  
  const [getTrack, setGetTrack] = useState("")
  const [showNotes, setShowNotes]= useState(false)
  const [notesData, setNotesData]= useState([])
  const [ newNote, setNewNote]=useState("")
  const [editNotes, setEditNotes] = useState(false)
  const [enableEdit, setEnableEdit]=useState()
  const [noteForEdit,setNoteForEdit]= useState([])
  const [customFields,setCustomFields]= useState([])
  const [showAvancedOptions, setShowAvancedOptions] = useState(false);
 /*  const [minTimeStart,setMinTimeStart] = useState(new Date().setHours(7,30)) */
  const scrum = useSelector((state) => state.scrum);
  const userStories = scrum.ddlStories.map((story, index) => {
    return {
      value: story.id,
      label: story.TituloHistoriaUsuario
    };
  });

  const handleShowAcvancedOptions =(e) =>{
   /*  e.stopPropagation(); */
    setShowAvancedOptions((val)=> val =! val)
  
  }

  const _getValue = (selector) => {
    // fn to return a valid value from state
    if (
      typeof taskData.metadata != "undefined" &&
      typeof taskData.metadata[selector] != "undefined"
    ) {
      let val = taskData.metadata[selector];
      // if (typeof val === 'string') {
      //   val = val.replace("000Z", "000-05:00");
      // }
      return val;
    } else {
      return null;
    }
  };

  const getValueKm = (data) =>{
    const valoresSeguimiento = [Number(data?.custom_Seguimiento1),Number(data?.custom_Seguimiento2),Number(data?.custom_Seguimiento3),Number(data?.custom_Seguimiento4),Number(data?.custom_Seguimiento5)]
    const initialValue = 0
    const TotalkmS = valoresSeguimiento?.reduce(
      (acum,current) => acum + current,
      initialValue)
    
    return TotalkmS.toFixed(2)
  }

  const getValueKmDesvi = (data) =>{

    const valoresSeguimiento = [Number(data?.custom_Seguimiento1),Number(data?.custom_Seguimiento2),Number(data?.custom_Seguimiento3),Number(data?.custom_Seguimiento4),Number(data?.custom_Seguimiento5)]
    const initialValue = 0
    const TotalkmS = valoresSeguimiento?.reduce(
      (acum,current) => acum + current,
      initialValue)
    
    return (TotalkmS.toFixed(2) - Number(data?.custom_KmPlaneados ).toFixed(2)).toFixed(2)

  }
  const dateChanged = (e) => {
    let dates = {
      startDate:
        e.target.name === "startDate"
          ? e.target.value
          : moment(taskData.metadata.startDate)._d,
      endDate:
        e.target.name === "endDate"
          ? e.target.value
          : moment(taskData.metadata.endDate)._d
    };
    if(!props.hasDurationHours){
      let duration = calcularDuracion(dates.startDate, dates.endDate);
      setTaskData((prev) => {
        return {
          ...prev,
          metadata: {
            ...prev.metadata,
            [e.target.name]: e.target.value,
            duration: duration,
            HorasEjecutadas: duration * 8
          }
        };
      });

    }else{
      let durationInMinutes= calcularDuracionMinutes(dates.startDate, dates.endDate)
      setTaskData((prev) => {
        return {
          ...prev,
          metadata: {
            ...prev.metadata,
            [e.target.name]: e.target.value,
            duration: durationInMinutes,
          }
        };
      });
    }


    //condicionar props.hasDurationHours
  
  };

  const _changeInput = (e) => {
    const __returnZeroIfNumber = (type, value) => {
      if (type === "number" && value === "") {
        return 0;
      } else {
        return value;
      }
    };
    if (e.target.name === "title") {
      setTaskData((prev) => {
        // alert(e.target.name)
        return {
          ...prev,
          [e.target.name]: e.target.value
        };
      });
    } else {
      setTaskData((prev) => {
        return {
          ...prev,
          metadata: {
            ...prev.metadata,
            [e.target.name]: __returnZeroIfNumber(e.target.type, e.target.value)
          }
        };
      });
    }
    // setTimeout(() => {
    //   dateChanged();
    // }, 1000);
  };
  const _changeSprint = (e) => {
    setTaskData((prev) => {
      return {
        ...prev,
        [e.target.name]: e.target.value
      };
    });
  };
  const handleForm = (e) =>{ 
    e.preventDefault();
  }
  const _saveTask = (data) => {

    const arrayOne = Object.entries(data.metadata)
    const arrayTwo = arrayOne.filter((aone)=> {
      return aone[0].includes("custom_")
    })

    const arraythree = arrayTwo.map((atwo)=>{
      return {
        name : atwo[0],
        value : atwo[1]
      }
    })
    const formCustomField = 
    [
      {
        name : "custom_KmPlaneados",
        value : data.metadata?.custom_KmPlaneados
      },
      {
        name : "custom_Seguimiento1",
        value : data.metadata?.custom_Seguimiento1
      },
      {
        name : "custom_Seguimiento2",
        value : data.metadata?.custom_Seguimiento2
        
      },
      {
        name : "custom_Seguimiento3",
        value : data.metadata?.custom_Seguimiento3
      },
      {
        name : "custom_Seguimiento4",
        value : data.metadata?.custom_Seguimiento4
        
      },
      {
        name : "custom_Seguimiento5",
        value : data.metadata?.custom_Seguimiento5
        
      }

    ]
    const customfieldUpdateInfo = props.customFields?.map((field)=>{
      return {
        IdActividad : data.id,
        IdCampoPersonalizado : field.IdCampoPersonalizado,
        fieldValue : formCustomField.filter((cust)=>{
            return cust.name == field.name
        })[0].value
      }
    })

    data.customFieldValues = customfieldUpdateInfo
    let dummie = data;
    dummie.metadata.resources = selectedResources;
    dummie.metadata.tags = selectedTags;
    let adjuntos = [];
    for (let i = 0; i < filesInput.current.files.length; i++) {
      const file = filesInput.current.files.item([i]);
      adjuntos.push(file);
    }
    dummie.adjuntos = adjuntos;
    if (data.laneId !== prevLaneId) {
      dummie = {
        ...dummie,
        prevLaneId: prevLaneId
      };
    }
    props.update(dummie);
    props.setShow(false);
  };

  const handleUploadFiles = () => {
    setUploadingFiles(true);
    let attachments = [];
    for (let i = 0; i < filesInput.current.files.length; i++) {
      const file = filesInput.current.files.item([i]);
      attachments.push(file);
    }
    let payload = {
      id: taskData.id,
      projectId: taskData.metadata.ProyectoId,
      files: attachments
    };
    uploadAttachments(payload)
      .then((res) => {
        setUploadingFiles(false);
        setFilesName("");
        swal(t("modal_creationactivities_done"), t("modal_creationactivities_upload"), "success").then(() =>
          //window.location.reload() //---
          getAttachedFiles(props.data.current.id)
        );
      })
      .catch((err) => {
        alert(`Ups, ${t("modal_creationactivities_erroupload")}`);
        console.log(err);
        setUploadingFiles(false);
      });
  };

  const formTask = useRef();
  const filesInput = useRef();
  const noteref = useRef();

  const handleInputFileChange = (e) => {
    if (getFilesSize(e.target.files) > 20971520) {
      alert(
        t("modal_creationactivities_maxload")
      );
      e.target.value = "";
      setFilesName("");
      return;
    }
    setFilesName(getFileNamesInput(e.target.files));
  };

  const getAttachedFiles = (taskId) => {
    dispatchFiles({
      type: "LOADING"
    });
    getAttachments(taskId).then((res) => {
      dispatchFiles({
        type: "SET_FILES",
        payload: getTaskFilesAdapter(res.data) 
      });
    });
  };

  const downloadFile = (filePath) => {
    downloadAttachment(filePath).then((res) => {
      var file = window.URL.createObjectURL(res.data);
      window.open(file);
    });
  };

  const deleteFile =(fileId, fileurl) => {
    swal({
      title: t("modal_creationactivities_deletefile"),
      text: t("modal_creationactivities_deletefile_2"),
      icon: "warning",
      buttons: true,
      buttons: [t("modal_creationactivities_deletefile_3"), t("modal_creationactivities_deletefile_4")],
      dangerMode: true 
    }).then((willDelete) => {
      if (willDelete) {
        /* deleteAttachments(fileId) */
        let dataForDelete = {
          filId:fileId,
          filurl : fileurl 
        }
        deleteAttachments(dataForDelete)
        .then((res)=> {
          swal(t("modal_creationactivities_done"), t("modal_creationactivities_deletefile_5"), "success").then(() =>
          window.location.reload()
        );
        }
        )
      }
    });
  }

  useEffect(() => {
    if (!props.show) {
      dispatchFiles({ type: "RESET" });
      setFilesName("");
      return;
    }
    if (props.data.current.metadata) {
      setTaskData(props.data.current);
      setSelectedResources(props.data.current.metadata.resources || []);
      setSelectedTags(props.data.current.metadata?.tags|| [])
      setCustomFields(props.data.current.metadata?.customField)
      getAttachedFiles(props.data.current.id);
      getAllNotes(props.data.current.id)

      if(props.data.current.metadata?.ProyectoId){ 
        getTaskTracking(props.data.current.metadata?.ProyectoId, props.data.current.id)
      }
    }
  }, [props.show]);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  const getExcludedDates = () => {
    Promise.all([
      getData(`excluded-dates/agile`, {})
    ]).then((values) => {
      setExcludeDates(values[0])
    });
  };

  const getTaskTracking = (projectId, taskId) => {
   Promise.all([
    getData(`agile-task-tracking/${projectId}/${taskId}`,{}),
   ]).then((values)=>{
    setGetTrack(values[0])
   })
  }

  const getAllNotes = (taskId) => {
    Promise.all([
     getData(`agile-task-notes/${taskId}`,{}),
    ]).then((values)=>{
      setNotesData(values[0])
    })
   }

  useEffect(() => {
    getExcludedDates()
  }, []);
  const sizeModal = (props.titleColumn.current ==="Product Backlog") ? "md":"xl"
  const allExcludeDates = excludeDates.map((date) => new Date(date));

  const addFiles = t("modal_creationactivities_textbox1")
  
  const handleOnChangeForNotes = (e,id) => {

   if(e.target.name == "text")
    setNotesData((prev)=> {
      prev.map((itprev)=>{
        if(itprev.idComment == id){
          itprev.text = e.target.value
        }
        return itprev
      })
      return [...prev]
    })
    
  }
 
  const handleEditNote = (id) =>{
    setEnableEdit(id)
  }
  const createNewNote = (e) => {
    let newNoteData = {
     /*  projUID: props.data.current.metadata.ProyectoId, */
      taskId: props.data.current.id,
      noteText: newNote.newComment,
    };
    saveDataScrum("agile-task-notes", newNoteData)
      .then((res) => {
        getAllNotes(newNoteData.taskId)
        noteref.current.value = ""
      })
      .catch((error) => {
        swal("¡Ups!", "Hubo un error al crear la nota", "error");
    });
  };

  const editNote = (id)=>{
    let noteToEdit  = notesData.filter((it)=> it.idComment == id)
    const noteEdit ={
      id : noteToEdit[0].idComment,
      text : noteToEdit[0].text,
      idProject : noteToEdit[0].idProject
    }
    updateDataScrum(`agile-task-notes/${noteEdit.id}`, noteEdit)
        .then((res) => {
          getAllNotes( props.data.current.id)
          setEnableEdit()
        })
        .catch((err) =>
          swal(
            "¡Ups!",
            "Hubo un error al actualizar los datos la nota, por favor refresca la página.",
            "error"
          )
      );
  }

  const deleteNote = (id)=>{
    swal({
      title: t("modal_creationactivities_deletenote"),
      text: t("modal_creationactivities_deletenote_2"),
      icon: "warning",
      buttons: true,
      buttons: [t("modal_creationactivities_deletefile_3"), t("modal_creationactivities_deletefile_4")],
      dangerMode: true 
    }).then((willDelete) => {
      if (willDelete) {
        let noteToEdit  = notesData.filter((it)=> it.idComment == id)
        deleteDataScrum(`agile-task-notes-delete/${noteToEdit[0].idComment}`)
        .then((res)=> {
          swal(t("modal_creationactivities_done"), t("modal_creationactivities_deletenote_5"), "success").catch((err) =>
          swal(
            "¡Ups!",
            "Hubo un error al eliminar la actividad, por favor refresca la página.",
            "error"
          ))
          getAllNotes( props.data.current.id)
        }
        )
      }
    });
  }

  const commentsToShow = () => { 
    if(notesData.length > 3 && showNotes == false ){
      let notesSlice= notesData.slice(0,3)
      return notesSlice
    }else
    return notesData
  }

  const numberOfnotes = ()=> {
    if(notesData){
      return notesData.length
    }
  } 

  const idProfileRol = (window.localStorage.getItem("cm9sX3VzdWFyaW8=") == 108 )? true : false

  const idOrganization = localStorage.getItem('OID')
  
  const validationHasSprintNameOrUndefined = (taskdata) => {
    /* console.log("validacion1") */
    if (taskdata.metadata.startDate && taskdata.metadata.endDate) {
      /* console.log("validacion1 dentro de if") */
      _saveTask(taskdata);
      setTaskData({})
      if (props.getAllDataAct) {
     /*    props.getAllDataAct(); */
      }
    }
    else
    {
      console.log("esta sin fechas")
      return
    }
  };

  const validationSprintNameIsNull = (taskdata) => {
    /* console.log("validacion2") */
    _saveTask(taskdata);
    setTaskData({})
    if (props.getAllDataAct) {
      console.log("se guarda")
      /* props.getAllDataAct(); */
    }
  };

  const handleValidations = (taskdata) => {
    /* console.log(taskdata.metadata.SprintNombre) */
    switch (taskdata.metadata.SprintNombre) {
      case undefined:
        return validationHasSprintNameOrUndefined(taskdata);
      case null:
        return validationSprintNameIsNull(taskdata);
      default:
        return validationHasSprintNameOrUndefined(taskdata);
    }
  };
  const getMinTimeWork = ()=>{
   let startComp = _getValue("startDate") && moment(_getValue("startDate")).add(props.hasDurationHours ? 0 : 5, "hours")._d
   let endComp = _getValue("endDate") && moment(_getValue("endDate")).add(props.hasDurationHours ? 0 : 5, "hours")._d
   if( startComp?.toDateString() ===  endComp?.toDateString()){
    let hours = getHours(startComp)
    let minutes = getMinutes(startComp)
    return setHours(setMinutes(new Date(),minutes),hours)
   }else{
    return setHours(setMinutes(new Date(),30),7)
   }
  }

  const DurationHours = ()=> {
    return calcularDuracionMinutes( taskData.metadata?.FechaInicio, taskData.metadata?.FechaFin)
  }

  return (
    <>
      <Modal

        show={props.show}
        onHide={() => {
          props.setShow(false);
          setTaskData({});
        }}
        size={sizeModal}
        contentClassName="border-0 shadow-lg"
        // dialogClassName="p-0 m-0 mx-md-auto mt-md-3"
      >
        <Form 
          ref={formTask}
          id="form-comple"
          className={(idProfileRol)? "disable-product-backlog" : ""}
          >
          <div
            className="progress rounded"
            title={`${taskData.metadata?.progress}% Completado`}
            style={{
              height: "8px",
              borderRadius: 0
            }}
          >
            <div
              className={`progress-bar ${
                taskData.metadata?.progress < 100 ? "bg-info" : "bg-success"
              }`}
              role="progressbar"
              style={{ width: `${taskData.metadata?.progress}%` }}
            ></div>
          </div>
          <Modal.Header>
            <Modal.Title className="h5 w-100">
              {/* <i className="fa fa-check-circle mr-3 text-muted"></i> */}
              {/* <Button><i className="fa fa-arrow-left"></i></Button> */}
              <input
                type="text"
                placeholder="Nombre del item"
                name="title"
                disabled={(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? true:false}
                value={taskData.title || ""}
                className="border-0"
                style={{ width: "100%" }}
                onChange={(e) => {
                  _changeInput(e);
                }}
              />
            </Modal.Title>
              <Button onClick={(e) => {
                props.setShow(false);
              }} variant="light">X</Button>
          </Modal.Header >
          {(props.titleColumn.current !=="Product Backlog") ? 
          <Modal.Body>
            {/* {JSON.stringify(props.data.current)} */}
           {/*  <hr/> */}
              <div className="d-flex justify-content-end">
                <h6 className="mr-2">Opciones avanzadas</h6>
                <button
                  type="button"
                  className="btn btn-sm btn-outline-info"
                  onClick={(e)=>handleShowAcvancedOptions(e)}
                /*  disabled = {(rol == "Ejecutivo")? true : false} */
                >
                  <i className="fa fa-cog"></i>
                </button>
              </div>
           {/*    <hr/> */}
            <div
              className="mb-3 d-inline-block"
              onClick={() =>{
                if(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo"){
                  return
                }else{
                  setShowResources(true)
                }
              }}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-user-plus text-muted mr-2"></i>
              {selectedResources.length > 0 ? (
                selectedResources.map((item) => {
                  return (
                    <span
                      key={item.id}
                      className="badge bg-info text-white mr-1"
                    >
                      {item.name}
                    </span>
                  );
                })
              ) : (
                <span className="text-primary">{t("modal_creationactivities_text1")}</span>
              )}
            </div>
            <br/>
            {!props.hideTags &&
            <div
              className="mb-3 d-inline-block"
              onClick={() =>{
                if(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo"){
                  return
                }else{
                  setShowTags(true)
                }
                }}
              style={{ cursor: "pointer" }}
            >
              <i className="fa fa-tags text-muted mr-2"></i>
              {selectedTags.length > 0 ? (
                selectedTags.map((item) => {
                  
                  return (
                    <span
                      style = {{backgroundColor: item.Color}}
                      key={item.Id}
                      className="badge text-white mr-1"
                    >
                      {item.NombreEtiqueta}
                    </span>
                  );
                })
              ) : (
                <span className="text-primary">{t("modal_creationactivities_text2")}</span>
              )}
            </div>
            }
            <Row>
              <Col>
                <h6>{t("modal_creationactivities_text3")}</h6>
                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>{t("modal_creationactivities_text4")}</Form.Label>
                      <br />
                      <ReactDatePicker
                        selected={
                          _getValue("startDate") &&
                          moment(_getValue("startDate")).add(props.hasDurationHours ? 0 : 5, "hours")._d
                        }
                        showTimeSelect = {props.hasDurationHours? true : false}
                        dateFormat = {props.hasDurationHours ? "dd-MM-yyyy h:mm aa":"dd/MM/yyyy"}
                        timeFormat="h:mm aa"
                        timeCaption="Hora"
                        minTime={ setHours(setMinutes(new Date(),30),7)}
                        maxTime={ setHours(setMinutes(new Date(),0),17)}
                        locale = "es"
                        /* excludeDates={excludeDates} */
                        excludeDates={allExcludeDates}
                        filterDate ={date => date.getDay() !== 6 && date.getDay() !== 0}
                       /*  highlightDates={allExcludeDates} */
                        wrapperClassName="w-100"
                        className="form-control"
                        onChange={(date) => {
                          let e = { target: {} };
                          e.target.name = "startDate";
                          e.target.value = date;
                          dateChanged(e);
                          // _changeInput(e);
                        }}
                        disabled={(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? true:false}
                        isClearable = {(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? false : true}
                        closeOnScroll={true}
                        required
                        form="external-form"
                      />
                      {/* <Form.Control
                        type="date"
                        name="startDate"
                        value={_getValue("startDate")}
                        onChange={(e) => {
                          _changeInput(e);
                        }}
                      /> */}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>{t("modal_creationactivities_text5")}</Form.Label>
                      <br />
                      <ReactDatePicker
                        selected={
                          _getValue("endDate") &&
                          moment(_getValue("endDate")).add(props.hasDurationHours ? 0 : 5, "hours")._d
                        }
                        showTimeSelect = {props.hasDurationHours? true : false}
                        locale = "es"
                        dateFormat = {props.hasDurationHours ? "dd-MM-yyyy h:mm aa":"dd/MM/yyyy"}
                        timeFormat="h:mm aa"
                        timeCaption="Hora"
                        minTime={ getMinTimeWork()}
                        maxTime={ setHours(setMinutes(new Date(),0),17)}
                        excludeDates={allExcludeDates}
                        disabled={_getValue("startDate") ? false : true}
                        filterDate ={date => date.getDay() !== 6 && date.getDay() !== 0}
                        minDate={ _getValue("startDate") &&
                        moment(_getValue("startDate")).add( props.hasDurationHours ? 0 : 5, "hours")._d}  
                        /* highlightDates={[{"react-datepicker__day--highlighted-custom-1":allExcludeDates}]} */
                        wrapperClassName="w-100"
                        className="form-control" 
                        onChange={(date) => {
                          let e = { target: {} };
                          e.target.name = "endDate";
                          e.target.value = date;
                          dateChanged(e);
                          // _changeInput(e);
                        }}
                        readOnly={(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? true : false}
                        isClearable = {(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? false : true}
                        closeOnScroll={true}
                        required
                        form="external-form"
                      />
                      {/* <Form.Control
                        type="date"
                        name="endDate"
                        value={_getValue("endDate")}
                        onChange={(e) => {
                          _changeInput(e);
                        }}
                      /> */}
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>
                        {t("modal_creationactivities_text6")} <small>{t("modal_creationactivities_text7")}</small>
                      </Form.Label>
                      <br />
                      <ReactDatePicker
                        wrapperClassName="w-100"
                        selected={
                          _getValue("expirationDate") &&
                          moment(_getValue("expirationDate")).add( props.hasDurationHours ? 0 : 5, "hours")._d
                        }
                        dateFormat = {props.hasDurationHours ? "dd-MM-yyyy h:mm aa":"dd/MM/yyyy"}
                        timeFormat="h:mm aa"
                        timeCaption="Hora"
                        showTimeSelect = {props.hasDurationHours? true : false}
                        locale = "es"
                        className="form-control"
                        excludeDates={allExcludeDates}
                        filterDate ={date => date.getDay() !== 6 && date.getDay() !== 0}
                        disabled={_getValue("startDate") ? false : true}
                        minDate={ _getValue("endDate") &&
                        moment(_getValue("endDate")).add(props.hasDurationHours ? 0 : 5, "hours")._d}  
                        /* dayClassName={(date) => date.getDate() === 10 ? "text-success":"text-error" } */
                        onChange={(date) => {
                          let e = { target: {} };
                          e.target.name = "expirationDate";
                          e.target.value = date;
                          _changeInput(e);
                        }}
                        readOnly={(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo" || props.conditionManagerAdvisory)? true : false}
                        isClearable = {(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo" )? false : true}
                        closeOnScroll={true}
                        required
                        form="external-form"
                      />
                      {/* <Form.Control
                        type="date"
                        name="expirationDate"
                        value={new Date(moment(_getValue("expirationDate")._d))}
                        onChange={(e) => {
                          _changeInput(e);
                        }}
                      /> */}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>
                        {t("modal_creationactivities_text8")} <small>{props.hasDurationHours? t("modal_creationactivities_text9.1") : t("modal_creationactivities_text9")} </small>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        min={0.1}
                        placeholder="0"
                        name="duration"
                        value={taskData.metadata?.duration}
                      /*   onFocus={(e) => {
                          e.target.select();
                        }} */
                      /*   onChange={(e) => {
                          _changeInput(e);
                        }} */
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label
                        data-tip={(t("planningboard_lenguaje") == "es")? selectTooltip(34): selectTooltip_en(34)}
                        data-class="pwc-tooltip shadow-lg py-3 tip-to-front"
                      >
                        {t("modal_creationactivities_text12")}<small>{t("modal_creationactivities_text9.1")}</small>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        step={0.1}
                        min="1"
                        name="HorasEjecutadas"
                        value={_getValue("HorasEjecutadas")}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) => {
                          _changeInput(e);
                        }}
                        disabled={(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? true:false}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label
                        className={
                          _getValue("progress") < 100
                            ? "text-info"
                            : "text-success"
                        }
                      >
                        {t("modal_creationactivities_text10")} 
                      </Form.Label>
                    {/*   <Form.Control
                        type="number"
                        placeholder="0"
                        name="progress"
                        max="100"
                        min="0"
                        value={_getValue("progress")}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) => {
                          if (e.target.value > 100) {
                            e.target.value = 100;
                          }
                          _changeInput(e);
                        }}
                      /> */}
                      <Form.Control
                        as="select"
                        className="border-info"
                        name="progress"
                        value={_getValue("progress")}
                        onClick={(e) => {
                          return e.target.value;
                        }}
                        onChange={(e) => {
                          if (e.target.value > 100) {
                            e.target.value = 100;
                          }
                          _changeInput(e);
                        }}
                        disabled={( props.rol == "Ejecutivo")? true : false}
                      >
                        <option key="0" value="0">
                          To Do
                        </option>
                        <option key="50" value="50">
                          Doing
                        </option>
                        <option key="100" value="100">
                          Done
                        </option>
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  {!props.hideStories && (
                    <Col md={4}>
                      <Form.Group>
                        <Form.Label>{t("modal_creationactivities_text11")}</Form.Label>
                        <Form.Control
                          as="select"
                          name="HistoriaUsuarioId"
                          value={_getValue("HistoriaUsuarioId")}
                          onClick={(e) => {
                            setShowSelectHus(true);
                          }}
                          onChange={(e) => {
                            _changeInput(e);
                          }}
                          disabled={(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? true:false}
                        >
                          <option value="">{t("modal_editsprint_text2_1")}...</option>
                          {userStories !== undefined &&
                            userStories.map((item, index) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                        </Form.Control>
                        <SelectInModal
                          title={t("modal_creationactivities_text11_1")}
                          noneLabel={t("modal_creationactivities_text11_6")}
                          show={showSelectHus}
                          value={_getValue("HistoriaUsuarioId")}
                          onClose={() => {
                            setShowSelectHus(false);
                          }}
                          items={userStories} // all hus
                          name="HistoriaUsuarioId"
                          onChange={(e) => _changeInput(e)}
                        />
                      </Form.Group>
                    </Col>
                  )}
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label
                      data-tip={(t("planningboard_lenguaje") == "es")? selectTooltip(40): selectTooltip_en(40)}
                      data-class="pwc-tooltip shadow-lg py-3 tip-to-front"
                      >
                        {t("modal_creationactivities_text13")} <small>(1 - 100)</small>
                      </Form.Label>
                      <Form.Control
                        /* isValid={(_getValue("poValue") >= 0 && _getValue("poValue") <= 100 )? true : false} */
                        type="number"
                        placeholder="0"
                        min="1"
                        max="100"
                        maxLength={3}
                        name="poValue"
                        value={_getValue("poValue") || 0}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) => {
                         if(e.target.value > 100){
                          return 
                         }else{
                          _changeInput(e);
                         }
                        /*   _changeInput(e); */
                        }}
                        disabled={(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? true:false}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Sprint</Form.Label>
                      <Form.Control
                        as="select"
                        name="laneId"
                        value={taskData.laneId}
                        onChange={(e) => {
                          _changeSprint(e);
                        }}
                        disabled
                      >
                        {props.sprints.map((item, index) => {
                          return index === 0 ? (
                            <option key={item.id} value={item.id}>
                              {item.title}
                            </option>
                          ) : (
                            <option key={item.id} value={item.id}>
                             {/*  Sprint {index} */}
                             {item.title}
                            </option>
                          );
                        })}
                      </Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
                {props.enableCost && <>
                <hr/>
                <h6>{t("modal_creationactivities_text3_1")}</h6>
                <Row>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label
                     /*  data-tip={selectTooltip(40)} */
                      data-class="pwc-tooltip shadow-lg py-3 tip-to-front"
                      >
                        {t("planningboard_tooltip_card_11")}
                      </Form.Label>
                      <Form.Control
                        /* isValid={(_getValue("poValue") >= 0 && _getValue("poValue") <= 100 )? true : false} */
                        type="number"
                        placeholder="0"
                        min="0"
                        max="99999999999"
                        step="1000"
                        value={_getValue("CostoPlaneado") || 0}
                        name="CostoPlaneado"
                       
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) => {
                          
                          _changeInput(e);
                        }}
                        disabled={(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? true:false}
                      />        
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label
                     /*  data-tip={selectTooltip(40)} */
                      data-class="pwc-tooltip shadow-lg py-3 tip-to-front"
                      >
                        {t("planningboard_tooltip_card_12")}
                      </Form.Label>
                      <Form.Control
                        /* isValid={(_getValue("poValue") >= 0 && _getValue("poValue") <= 100 )? true : false} */
                        type="number"
                        placeholder="0"
                        min="0"
                        max="99999999999"
                        step={1000}
                        name="CostoReal"
                        value={_getValue("CostoReal") || 0}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) => {
                          
                          _changeInput(e);
                        }}
                        disabled={(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? true:false}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                </>
                }
                <>
                {(idOrganization == ID_ORGANIZACION ) && 
                <> 
                <hr/>
                <h6>Campos Personalizados</h6>
                <div className="d-flex flex-wrap w-80">
                  { props.customFields?.map((field,idx)=>{
                    return <Col key={idx} md={(idx == 0)? 8 : field.size} className="mx-2 p-0">
                            <Form.Group>
                              <Form.Label
                              data-class="pwc-tooltip shadow-lg py-3 tip-to-front"
                              >
                                {field.title}
                              </Form.Label>
                              <Form.Control
                              type={field.Type}
                              placeholder={field.ItemPlaceHolder}
                              min={field.minValue}
                              max={field.maxValue}
                              step={(field.step == 2)? "0.01": "1"}
                              name={field.name}
                              /* value={(idx == 1)? getValueKm( taskData.metadata ) : _getValue(field.name) } */
                              value= {_getValue(field.name) || 0}
                              readOnly={field?.readonly || false}
                              onFocus={(e)=>{
                                e.target.select()
                              }}
                              onChange={(e)=>{
                                _changeInput(e)
                              }}
                              disabled={(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? true:false}  
                              >
                              </Form.Control>
                            </Form.Group>
                          </Col>
                  })

                  }
                  <Col md={8} className="mx-2 p-0">
                    <Form.Group>
                      <Form.Label
                      data-class="pwc-tooltip shadow-lg py-3 tip-to-front"
                      >
                        Km Ejecutados
                      </Form.Label>
                      <Form.Control
                      type="number"
                      placeholder="0"
                      step={1}
                      name="kmejecutados"
                      value= {getValueKm( taskData.metadata) || 0}
                      readOnly={true}
                      onFocus={(e)=>{
                        e.target.select()
                      }}
                      disabled={(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? true:false}  
                      >
                      </Form.Control>
                    </Form.Group>
                  </Col>
                  <Col md={3} className="mx-2 p-0">
                    <Form.Group>
                      <Form.Label
                      data-class="pwc-tooltip shadow-lg py-3 tip-to-front"
                      >
                        Desviación
                      </Form.Label>
                      <Form.Control
                      type="number"
                      placeholder="0"
                      className= { 
                        (getValueKmDesvi( taskData.metadata) < 0 ) ? " font-weight-bold text-secondary border-danger" : " font-weight-bold text-success border-success"
                      }
                      step={1}
                      name="kmdesviacion"
                      value= {getValueKmDesvi( taskData.metadata)  || 0} 
                      readOnly={true}
                      onFocus={(e)=>{
                        e.target.select()
                      }}
                      disabled={(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? true:false}  
                      >
                      </Form.Control>
                    </Form.Group>
                  </Col>
                 </div>
                </> 
                }
                
                </>
                <hr />
                <Row>
                  <Col lg={6}>
                    <Form.Label>{t("modal_creationactivities_text14")}</Form.Label>
                    <div className="d-flex">
                      <Form.Control
                        type="file"
                        ref={filesInput}
                        id="add-task-file"
                        label={filesName || addFiles}
                        data-browse={t("modal_creationactivities_button_search")}
                        accept="image/png, image/jpeg, .pdf, .doc, .docx, .xls"
                        custom
                        multiple
                        onChange={handleInputFileChange}
                        disabled={( props.rol == "Ejecutivo")? true : false}
                      />
                      {filesName && (
                        <Button
                          title={
                            uploadingFiles
                              ? t("modal_creationactivities_button_upload")
                              : t("modal_creationactivities_button_upload_1")
                          }
                          onClick={handleUploadFiles}
                          variant="info"
                          className="ml-3 animate__animated animate__fadeIn animate__repeat-1"
                          disabled={uploadingFiles ? true : false}
                        >
                          {uploadingFiles ? (
                            <i className="fa fa-spinner animate__animated animate__rotateOut animate__infinite"></i>
                          ) : (
                            <i className="fa fa-upload"></i>
                          )}
                        </Button>
                      )}
                    </div>
                    <small className="text-danger">
                      {t("modal_creationactivities_text17")}
                    </small>
                    <br/>
                    <small className="text-danger">
                      {t("modal_creationactivities_text18")}
                    </small>
                  </Col>
                  <Col lg={6}>
                    <Form.Label>{t("modal_creationactivities_text15")} ( {files?.files?.length} )</Form.Label>
                    <br />
                    <div style={{ maxHeight: "75px", overflow: "auto" }}>
                      {files?.loading ? (
                        <i className="fa fa-spinner animate__animated animate__rotateOut animate__infinite"></i>
                      ) : files?.error ? (
                        <small className="text-danger">
                          {t("modal_creationactivities_text15_1")}
                        </small>
                      ) : files?.files?.length ?
                        <small className ="table-responsive">
                        <table className="table table-striped">
                          {/* <thead>
                            <tr>
                              <th scope="col">Nombre Archivo</th>
                              <th scope="col">Eliminar</th>
                            </tr>
                          </thead> */}
                          <tbody>
                            {files.files.map((file,idx) => {
                              return <tr key={idx}>
                                  <th><a href="#" onClick={() => {downloadFile(file.url);}}>{file.fileName}</a></th>
                                  <th>
                                  <i
                                    style={{cursor:"pointer"}}
                                    onClick={()=> deleteFile(file.fileId, file.url)}
                                    className="fa fa-trash-alt ml-2 text-danger">
                                  </i>
                                  </th> 
                              </tr>
                            })}
                          </tbody>
                        </table>
                        </small>
                       : (
                        <small className="text-muted">
                          {t("modal_creationactivities_text16")}
                        </small>
                      )}
                    </div>
                    <br />
                  </Col>
                </Row>
                <hr />
                {(notesData?.length > 3) &&
                  <Button
                    variant="outline-info"
                    size ="sm"
                    className="mt-0 mb-2"
                    onClick={()=> {
                      setShowNotes(value => value = !value)
                    }}
                    >
                        {(!showNotes)? <>
                          {t("modal_creationactivities_notes_4")}
                        ( {numberOfnotes()} )
                          </>
                          : <>{t("modal_creationactivities_notes_5")}( 3 )</>
                        }
                  </Button>
                }
                <Row className="px-1 mb-0" style={{backgroundColor:"#e9ecef",borderTopLeftRadius:"5px",borderTopRightRadius:"5px", maxHeight:"365px", overflowY:"auto"}}>
                {(notesData.length == 0 ) && 
                <Form.Label className="mb-1 mt-2"><b>{t("modal_creationactivities_text19")}</b></Form.Label>
                }
                {(notesData.length >= 1) && 
                <Form.Label className="mb-1">{t("modal_creationactivities_notes_6")}</Form.Label>
                }
                <Col lg={12}  >
                  {(commentsToShow()) ? 
                  <>
                  {  commentsToShow()?.map((item,idx)=>{
                    return <Col lg={12} key={idx} className="mb-0 pb-0 d-flex border-bottom rounded border-2 border-light" style={{backgroundColor:"#e9ecef"}}>
                            <div style={{width:"100%"}} className="mr-2 " >
                            <small className="mr-2">{item.name}</small>
                            <small>{t("modal_creationactivities_notes_1")} {item.createDate}</small>
                            <Form.Control
                            key={idx}
                            as="textarea"
                            className="mb-1 pb-0"
                            style={{border:"none"}}
                            name="text"
                            value={item.text}
                            disabled = {(idx == enableEdit )? false:true}
                            onChange={(e)=> {
                              handleOnChangeForNotes(e,item.idComment)
                            }}
                            />
                            </div>
                            <div className="d-flex justify-content-around align-items-center">
                            {(idx != enableEdit)? <>
                              <i className="text-info fa fa-edit mr-2" 
                                  style={{cursor:"pointer"}}
                                  onClick={(e)=>{
                                    if(props.rol != "Ejecutivo"){
                                      handleEditNote(idx)
                                    }else {
                                      return
                                    }
                                  }}                               
                                  >
                                  
                              </i>
                              <i className="text-danger fas fa-trash"
                                  style={{cursor:"pointer"}}
                                  onClick={(e)=>{
                                    if(props.rol != "Ejecutivo"){
                                      deleteNote(item.idComment)
                                    }else {
                                      return
                                    }
                                  }}
                              ></i>
                            </> :""
                            }
                            {(idx == enableEdit)? 
                            <>
                            <div className="d-flex flex-column">
                            <Button
                              type="button"
                              variant="info"
                              size="sm"
                              className="p-1 mb-1"
                              onClick={(e)=> {
                                editNote(item.idComment)
                              }}
                            >
                            {t("modal_creationactivities_button_save")}
                            </Button>
                            <Button
                              type="button"
                              variant="dark"
                              size="sm"
                              className="p-1 m-0"
                              onClick={(e)=> {
                                setEnableEdit()
                                setEditNotes(false)
                                getAllNotes(props.data.current.id)
                              }}
                            
                            >
                            {t("modal_creationactivities_button_cancel")}
                            </Button>
                            </div>
                            </> :
                            
                            "" }
                            </div>
                            </Col>
                  })}
                  
                  </>
                  :""
                  }
                </Col>
                </Row>
                <Row>
                  <Form
                  style={{backgroundColor:"#e9ecef", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}
                  className="w-100 mb-2 px-1"
                  > 
                  {(notesData?.length >= 1 ) && 
                  
                    <Form.Label><b>{t("modal_creationactivities_notes_2")}</b></Form.Label>
                  }
                    <Col lg={12} md={12}>
                 
                      <Form.Control
                      ref={noteref}
                      as="textarea"
                      className="mb-2"
                      
                      name="newComment"
                      placeholder={t("modal_creationactivities_text20")}
                      onChange={(e) => {
                        inputChange(e,setNewNote);
                      }}
                      />
                      <Button
                      type="button"
                      variant="success"
                      size="sm"
                      className="p-1 mb-2"
                      onClick={(e)=> {
                        createNewNote(e)}
                      }
                      disabled={(props.rol == "Ejecutivo")? true : false}
                    >
                     <icon class="fa fa-sticky-note mr-2"/>{t("modal_creationactivities_notes_3")}
                    </Button>
                    </Col>

                 {/*  <Col lg={6}>
                    <Form.Label>{t("modal_creationactivities_text19")}</Form.Label>
                    <Form.Control
                      className="border-info mb-3"
                      name="Avance"
                      as="textarea"
                      placeholder={t("modal_creationactivities_text20")}
                      style={{ height: "100px" }}
                      // value={_getValue("Avance")}
                      onFocus={(e) => {
                        e.target.select();
                      }}
                      onChange={(e) => {
                        _changeInput(e);
                      }}
                    />
                  </Col> */}
                  {/* {_getValue("UltAvance") ? (
                  
                    <Col lg={6}>
                      <Form.Label>
                        <i className="fa fa-sticky-note mr-2 text-info"></i>
                        {t("modal_creationactivities_text21")} 
                      </Form.Label>
                      <Form.Control
                        className="border-info"
                        name="Avance"
                        value={_getValue("UltAvance") || ""}
                        as="textarea"
                        disabled="true"
                        style={{ height: "100px" }} 
                        onClick={(e) => {
                          e.target.select();
                        }}
                      />
                    </Col>
                    
                  ) : null } */}
                  </Form>
                  </Row>
               {/*  </Row> */}
                <small
                  dangerouslySetInnerHTML={{ __html: _getValue("Seguimiento") }}
                ></small>
                <br/>
                 <Button
                    className="mt-2 mb-2"
                    type="button"
                    variant="outline-info"
                    onClick={() => {
                      setShowTrack(value => value = !value)
                    }}
                  >
                  <i className={(showTrack)?"fa fa-times mr-2":""}></i>{t("modal_creationactivities_button_traceability")}
                </Button>
                <div className={(showTrack)? "d-block":"d-none"}>
                  <small className="table table-striped"
                    dangerouslySetInnerHTML={{ __html: getTrack[0]?.Trazabilidad}}
                  >
                  </small>
                </div>
                
              </Col>
              {/* <Col className="border-left">
              <h6>Notas</h6>
              <Form.Group>
                <Form.Label>Añadir nota</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  placeholder="Escribe alguna anotación sobre la tarea."
                />
              </Form.Group>
              <Button variant="outline-primary" size="sm" block className="rounded-0">Nueva nota</Button>
            </Col> */}
            </Row>
          </Modal.Body>
          :
          <Modal.Body>
             <Row>
              <Col>
                <Row>
                 {/*  <Col md={4}>
                    <Form.Group>
                      <Form.Label
                      >
                        {t("modal_creationactivities_text13")} <small>(1 - 100)</small>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        min="1"
                        max="100"
                        maxLength={3}
                        name="poValue"
                        value={_getValue("poValue")}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) => {
                          if(e.target.value > 100){
                            return 
                           }else{
                            _changeInput(e);
                           }
                          _changeInput(e);
                        }}
                      />
                    </Form.Group>
                  </Col> */}
                  <Col lg={12} >
                      <div
                        className="mb-3 d-inline-block"
                        onClick={() => {
                          if(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo"){
                            return
                          }else{
                            setShowTags(true)
                          }
                        }
                        }
                        style={{ cursor: "pointer" }}
                        >
                        <i className="fa fa-tags text-muted mr-2"></i>
                        {selectedTags.length > 0 ? (
                          selectedTags.map((item) => {
                            
                            return (
                              <span
                                style = {{backgroundColor: item.Color}}
                                key={item.Id}
                                className="badge text-white mr-1"
                              >
                                {item.NombreEtiqueta}
                              </span>
                            );
                          })
                        ) : (
                          <span className="text-primary">{t("modal_creationactivities_text2")}</span>
                        )}
                      </div>
                  </Col>
                  <Col lg={8} >
                    <div className="d-none">
                      <Form.Control
                        type="file"
                        ref={filesInput}
                        id="add-task-file"
                        label={filesName || "Agregar archivos"}
                        data-browse={t("modal_creationactivities_button_search")}
                        accept="image/png, image/jpeg, .pdf, .doc, .docx, .xls"
                        custom
                        multiple
                        onChange={handleInputFileChange}
                      />
                    </div>
                  </Col>
                  <div className="d-flex w-100">
                  {!props.hideStories && (
                    <Col md={8} lg={8}>
                      <Form.Group>
                        <Form.Label>{t("modal_creationactivities_text11")}</Form.Label>
                        <Form.Control
                          as="select"
                          name="HistoriaUsuarioId"
                          value={_getValue("HistoriaUsuarioId")}
                          onClick={(e) => {
                            setShowSelectHus(true);
                          }}
                          onChange={(e) => {
                            _changeInput(e);
                          }}
                          disabled={(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? true : false}
                        >
                          <option value="">{t("modal_editsprint_text2_1")}...</option>
                          {userStories !== undefined &&
                            userStories.map((item, index) => {
                              return (
                                <option key={item.value} value={item.value}>
                                  {item.label}
                                </option>
                              );
                            })}
                        </Form.Control>
                        <SelectInModal
                          title={t("modal_creationactivities_text11_1")}
                          noneLabel={t("modal_creationactivities_text11_6")}
                          show={showSelectHus}
                          value={_getValue("HistoriaUsuarioId")}
                          onClose={() => {
                            setShowSelectHus(false);
                          }}
                          items={userStories} // all hus
                          name="HistoriaUsuarioId"
                          onChange={(e) => _changeInput(e)}
                        />
                      </Form.Group>
                    </Col>
                  )}
                  </div>
                  <div className="d-flex w-100">
                  <Col md={5}>
                    <Form.Group>
                      <Form.Label
                        data-tip={(t("planningboard_lenguaje") == "es")? selectTooltip(34) : selectTooltip_en(34)}
                        data-class="pwc-tooltip shadow-lg py-3 tip-to-front"
                      >
                        {t("modal_creationactivities_text12")}
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="0"
                        step={0.1}
                        min="1"
                        name="HorasEjecutadas"
                        value={_getValue("HorasEjecutadas")}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) => {
                          _changeInput(e);
                        }}
                        disabled={(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? true:false}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={5}>
                    <Form.Group>
                      <Form.Label
                      data-tip={(t("planningboard_lenguaje") == "es")? selectTooltip(40): selectTooltip_en(40)}
                      data-class="pwc-tooltip shadow-lg py-3 tip-to-front"
                      >
                        {t("modal_creationactivities_text13")} <small>(1 - 100)</small>
                      </Form.Label>
                      <Form.Control
                        /* isValid={(_getValue("poValue") >= 0 && _getValue("poValue") <= 100 )? true : false} */
                        type="number"
                        placeholder="0"
                        min="1"
                        max="100"
                        maxLength={3}
                        name="poValue"
                        value={_getValue("poValue")}
                        onFocus={(e) => {
                          e.target.select();
                        }}
                        onChange={(e) => {
                         if(e.target.value > 100){
                          return 
                         }else{
                          _changeInput(e);
                         }
                        /*   _changeInput(e); */
                        }}
                        disabled={(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? true:false}
                      />
                    </Form.Group>
                  </Col>
                  </div>
                  
                {/*   <Col lg={12}>
                  </Col> */}
                
              
                {(notesData?.length > 3) &&
                  <Button 
                    variant="outline-info"
                    size ="sm"
                    className="mt-0 mb-2"
                    onClick={()=> {
                      setShowNotes(value => value = !value)
                    }}
                    >  
                        {(!showNotes)? <>
                          {t("modal_creationactivities_notes_4")}
                        ( {numberOfnotes()} )
                          </>
                          : <>{t("modal_creationactivities_notes_5")}( 3 )</>
                        }
                  </Button>
                }
                 <Col lg={12}>
                <Row className="px-1 mb-0" style={{backgroundColor:"#e9ecef",borderTopLeftRadius:"5px",borderTopRightRadius:"5px", maxHeight:"365px", overflowY:"auto"}}>
                {(notesData.length == 0 ) && 
                <Form.Label className="mb-1 mt-2"><b>{t("modal_creationactivities_text19")}</b></Form.Label>
                }
                {(notesData.length >= 1) && 
                <Form.Label className="mb-1">{t("modal_creationactivities_notes_6")}</Form.Label>
                }
                <Col lg={12}  >
                  {(commentsToShow()) ? 
                  <>
                  {  commentsToShow()?.map((item,idx)=>{
                    return <Col key={idx + 1}   lg={12} className="mb-0 pb-0 d-flex border-bottom rounded border-2 border-light" style={{backgroundColor:"#e9ecef"}}>
                            <div style={{width:"100%"}} className="mr-2 " >
                            <small className="mr-2">{item.name}</small>
                            <small>{t("modal_creationactivities_notes_1")} {item.createDate}</small>
                            <Form.Control
                            key={idx}
                            as="textarea"
                            className="mb-1 pb-0"
                            style={{border:"none"}}
                            name="text"
                            value={item.text}
                            disabled = {(idx == enableEdit )? false:true}
                            onChange={(e)=> {
                              handleOnChangeForNotes(e,item.idComment)
                            }}
                            />
                            </div>
                            <div className="d-flex justify-content-around align-items-center">
                            {(idx != enableEdit)? <>
                              <i className="text-info fa fa-edit mr-2" 
                                  style={{cursor:"pointer"}}
                                  onClick={(e)=>{
                                    if(props.rol != "Ejecutivo"){
                                      handleEditNote(idx)
                                    }else {
                                      return
                                    }
                                  }}
                                  >
                                  
                              </i>
                              <i className="text-danger fas fa-trash"
                                  style={{cursor:"pointer"}}
                                  onClick={(e)=>{
                                    if(props.rol != "Ejecutivo"){
                                      deleteNote(item.idComment)
                                    }else{
                                      return
                                    }
                                  }}
                              ></i>
                            </> :""
                            }
                            {(idx == enableEdit)? 
                            <>
                            <div className="d-flex flex-column">
                            <Button
                              type="button"
                              variant="info"
                              size="sm"
                              className="p-1 mb-1"
                              onClick={(e)=> {
                                editNote(item.idComment)
                              }}
                            
                            >
                            {t("modal_creationactivities_button_save")}
                            </Button>
                            <Button
                              type="button"
                              variant="dark"
                              size="sm"
                              className="p-1 m-0"
                              onClick={(e)=> {
                                setEnableEdit()
                                setEditNotes(false)
                                getAllNotes(props.data.current.id)
                              }}
                            
                            >
                            {t("modal_creationactivities_button_cancel")}
                            </Button>
                            </div>
                            </> :
                            
                            "" }
                            </div>
                            </Col>
                  })}
                  
                  </>
                  :""
                  }
                  </Col>
               
                
                </Row>
                </Col>
                <Col lg={12}>
                <Row>
                  <Form
                  style={{backgroundColor:"#e9ecef", borderBottomLeftRadius:"5px", borderBottomRightRadius:"5px"}}
                  className="w-100 mb-2 px-1"
                  > 
                  {(notesData?.length >= 1 ) && 
                  
                    <Form.Label><b>{t("modal_creationactivities_notes_2")}</b></Form.Label>
                  }
                    <Col lg={12} md={12}>
                 
                      <Form.Control
                      ref={noteref}
                      as="textarea"
                      className="mb-2"
                      
                      name="newComment"
                      placeholder={t("modal_creationactivities_text20")}
                      onChange={(e) => {
                        inputChange(e,setNewNote);
                      }}
                      />
                      <Button
                      type="button"
                      variant="success"
                      size="sm"
                      className="p-1 mb-2"
                      onClick={(e)=> {
                        createNewNote(e)
                        
                      }
                      }
                      disabled={(props.rol == "Ejecutivo")? true : false}
                    >
                      <icon class="fa fa-sticky-note mr-2"/>{t("modal_creationactivities_notes_3")}
                    </Button>
                    </Col>
                  </Form>
                  </Row>
                  </Col>
                </Row>
                {(idOrganization == ID_ORGANIZACION) && <>
                <hr/>
                <h6>Campos Personalizados</h6>
                <div className="d-flex flex-wrap w-80">
                  { props.customFields?.map((field,idx)=>{
                    return <Col key={idx} md={(idx == 0)? 8 : field.size} className="mx-2 p-0">
                            <Form.Group>
                              <Form.Label
                              data-class="pwc-tooltip shadow-lg py-3 tip-to-front"
                              >
                                {field.title}
                              </Form.Label>
                              <Form.Control
                              type={field.Type}
                              placeholder={field.ItemPlaceHolder}
                              min={field.minValue}
                              max={field.maxValue}
                              step={(field.step == 2)? "0.01": "1"}
                              name={field.name}
                              /* value={(idx == 1)? getValueKm( taskData.metadata ) : _getValue(field.name) } */
                              value= {_getValue(field.name) || 0}
                              readOnly={field?.readonly || false}
                              onFocus={(e)=>{
                                e.target.select()
                              }}
                              onChange={(e)=>{
                                _changeInput(e)
                              }}
                              disabled={(props.rol == "Miembro de Equipo" || props.rol == "Ejecutivo")? true:false}  
                              >
                              </Form.Control>
                            </Form.Group>
                          </Col>
                  })[0]}
                 </div>
                <hr />
                </>}
              </Col>
             
            </Row>
            

          </Modal.Body>
           }
          <Modal.Footer>
            <Button
              type="button"
              variant="dark"
              onClick={(e) => {
                setTaskData({})
                props.setShow(false);
              }}
            >
              <i className="fa fa-times mr-2"></i>{t("modal_creationactivities_button_cancel")}
            </Button>
            <Button
           /*    type="button" */
              /* id="external-form" */
              type="submit"
              variant="success"
              onClick={()=>handleValidations(taskData)}
              disabled={(props.rol == "Ejecutivo")? true : false}
            >
              <i className="fa fa-save mr-2"></i>{t("modal_creationactivities_button_save")}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <ModalSelectResources
        show={showResources}
        setShow={setShowResources}
        resources={props.resources} // all resources
        selectedResources={selectedResources} // selected resources
        setResources={setSelectedResources}
      />
       <ModalSelectTags
        show={showTags}
        setShow={setShowTags}
        tags = {props.tags}
        selectedTags={selectedTags} 
        setSelectedTags={setSelectedTags}
      />
        <Suspense fallback={"loading..."}>
          <ModalAvancedOptions
          setShow={setShowAvancedOptions}
          show={showAvancedOptions}
          handleClose = {handleShowAcvancedOptions}
          data={taskData}
          handleValidations = {handleValidations}
          getAllprojectData ={props.getAllprojectData}
          />
        </Suspense> 
    </>
  );
};

export default React.memo(ModalEditTask);
/* export default ModalEditTask */
// export default ModalEditTask;
