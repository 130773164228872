import React, { useState } from "react";
import {
  Gantt,
  Tasks,
  Column,
  Toolbar,
  Item,
  Validation,
  Editing,
} from "devextreme-react/gantt";
import "devextreme/dist/css/dx.light.css"; // Importar estilos necesarios
import _ from "lodash";
import { SelectBox } from "devextreme-react";
import { Form } from "react-bootstrap";

const tasks = [
  {
    id: 1,
    title: "Automatización backlog",
    start: "2024-09-15T00:00:00", // Fecha en formato ISO
    end: "2024-12-15T23:59:59",
  },
  {
    id: 2,
    title: "Gestión de Capacidades",
    start: "2024-05-01T00:00:00",
    end: "2024-12-23T23:59:59",
  },
  {
    id: 3,
    title: "Pg. Gestión Capacidades",
    start: "2024-09-01T00:00:00",
    end: "2024-12-31T23:59:59",
  },
  {
    id: 4,
    title: "BAU Seguridad",
    start: "2024-09-01T00:00:00",
    end: "2024-12-31T23:59:59",
  },
  {
    id: 5,
    title: "MDR - Ciso",
    start: "2024-09-01T00:00:00",
    end: "2024-12-31T23:59:59",
  },
  {
    id: 6,
    title: "Automatización backlog",
    start: "2024-09-15T00:00:00", // Fecha en formato ISO
    end: "2024-12-15T23:59:59",
  },
  {
    id: 7,
    title: "Gestión de Capacidades",
    start: "2024-05-01T00:00:00",
    end: "2024-12-23T23:59:59",
  },
  {
    id: 8,
    title: "Pg. Gestión Capacidades",
    start: "2024-09-01T00:00:00",
    end: "2024-12-31T23:59:59",
  },
  {
    id: 9,
    title: "BAU Seguridad",
    start: "2024-09-01T00:00:00",
    end: "2024-12-31T23:59:59",
  },
  {
    id: 10,
    title: "MDR - Ciso",
    start: "2024-09-01T00:00:00",
    end: "2024-12-31T23:59:59",
  },
];

const TimelineResource = ({ user, dataFilter }) => {
  const [scaleType, setScaleType] = useState("days");

  const taskByUser = dataFilter?.filter((task) => {
    return task.IdUsuario == user.IdUsuario;
  });
  /* ?.map((tk) => {
      return {
        id :  tk.IdActividad,
        title : tk.NombreActividad,
        start : tk.vFechaInicio,
        end : tk.vFechaFin,
        parentId : tk.IdProyecto
      };
    }); */

  const projects = _.unionBy(taskByUser, "IdProyecto").map((pr) => {
    return {
      project: pr.Proyecto,
      IdProject: pr.IdProyecto,
    };
  });

  const allTaskAndProjects = taskByUser.concat(projects);
  const dataForTimeLine = allTaskAndProjects?.map((item) => {
    return {
      id: item.IdActividad ? item.IdActividad : item.IdProject,
      parentId: item.IdProyecto ? item.IdProyecto : 0,
      title: item.IdProyecto ? item.NombreActividad : item.project,
      start: item.IdProyecto ? item.vFechaInicio : "",
      end: item.IdProyecto ? item.vFechaFin : "",
    };
  });

  const scaleTypes = ["auto", "days", "weeks", "months", "quearters", "years"];

  return (
    <>
      {/*  <h5 className="ml-5">
      
        Timeline  : Ocupación
      </h5> */}
      <div>
        {/* <div className="label">Escala de tiempo</div> */}
        <div>
          <Form.Control
            size="sm"
            as="select"
            value={scaleType}
            onChange={(e) => {
              setScaleType(e.target.value);
            }}
          >
            <option value="0">Seleccione escala de tiempo</option>
            <option value="days">Días</option>
            <option value="weeks">Semanas</option>
            <option value="months">Meses</option>
            <option value="years">Años</option>
          </Form.Control>
        </div>
      </div>
      <Gantt
        taskListWidth={500}
        scaleType={scaleType}
        // scaleTypeRange={["weeks", "months", "years"]}
        height={400}
        onTaskDblClick={() => {
          return console.log("clickOnTask");
        }}
      >
        <Tasks dataSource={dataForTimeLine} />
        {/*  <Dependencies dataSource={dependencies} />
          <Resources dataSource={resources} />
          <ResourceAssignments dataSource={resourceAssignments} /> */}

        <Toolbar>
          {/* <Item name="undo" />
            <Item name="redo" /> */}
          {/*  <Item name="separator" /> */}
          <Item name="collapseAll" />
          <Item name="expandAll" />
          <Item name="separator" />
          {/*    <Item name="addTask" />
            <Item name="deleteTask" />
            <Item name="separator" /> */}
          <Item name="zoomIn" />
          <Item name="zoomOut" />
        </Toolbar>

        <Column dataField="title" caption="Nombre Actividad" width={300} />
        <Column
          dataField="start"
          caption="Fecha Inicio"
          minWidth={100}
          dataType="date"
          format="dd/MM/yyyy"
        />
        <Column
          dataField="end"
          caption="Fecha Fin"
          minWidth={100}
          dataType="date"
          format="dd/MM/yyyy"
        />
        {/*  <Column
          dataField="progress"
          caption="Estado"
          width={60}
          customizeText={custom_progress_test}
        /> */}

        <Validation autoUpdateParentTasks />
        <Editing disabled />
      </Gantt>
    </>
  );
};

export default TimelineResource;
