import React, { useEffect, useState } from "react";
import { Button, Col, Container, Dropdown, Row , Form, Modal} from "react-bootstrap";
import emptyImg from "../../assets/people-1.png";
import SuperTableV2 from "../SuperTableV2";
import axios from "axios";
import { API_ROUTE, headersRequest, removeData } from "../../helpers";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { formatDateToInput } from "./helpers_MGI";

const WaitForApproval = ({}) => {
  const [initiatives, setInitiatives] = useState([]);

  const getAllInitiatives = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}listariniciativas`, headersRequest());
      const formatDates = res.data.map(initiative => ({
        ...initiative,
        FechaIdentificacion : formatDateToInput(initiative.FechaIdentificacion),
        FechaCreacion : formatDateToInput(initiative.FechaCreacion),
      }));
      const idEstadoPorAprobacion = '253411FA-149E-4803-B766-8F44E8EC8B3A';
      const porAprobacion = formatDates.filter(d => d.IdEstado === idEstadoPorAprobacion);
      console.log(formatDates) 
      setInitiatives(porAprobacion);
    } catch (error) {
      console.error(error);
    }
  };

  const enviarStandBy = async (uid) => {
    const success = () => {
      swal(
        "¡Hecho!",
        "La Iniciativa ha sido Enviada a StandBy exitosamente.",
        "success"
      )
    };

    const resultado = await swal({
      title: "¿Seguro?",
      text: "Enviar la Iniciativa a StandBy. ¿Deseas continuar?",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Sí, Enviar",
          value: true,
          visible: true,
          className: "btn-danger",
          closeModal: true,
        },
      },
      dangerMode: true,
    });
    if (resultado) {
        success();
        const dummie = initiatives?.filter((item) => item.Id!== uid);
        setInitiatives(dummie);
      }
  };
  const enviarSubsanacion = async (uid) => {
    const success = () => {
      swal(
        "¡Hecho!",
        "La Iniciativa ha sido Enviada a Subsanación exitosamente.",
        "success"
      )
    };

    const resultado = await swal({
      title: "¿Seguro?",
      text: "Enviar la Iniciativa a Subsanación. ¿Deseas continuar?",
      icon: "warning",
      buttons: {
        cancel: {
          text: "Cancelar",
          value: false,
          visible: true,
          className: "",
          closeModal: true,
        },
        confirm: {
          text: "Sí, Enviar",
          value: true,
          visible: true,
          className: "btn-danger",
          closeModal: true,
        },
      },
      dangerMode: true,
    });
    if (resultado) {
        success();
        const dummie = initiatives?.filter((item) => item.Id!== uid);
        setInitiatives(dummie);
      }
  };

  useEffect(() => {
    getAllInitiatives()
  }, []);

  const columns = [
    {
      caption: "Nombre",
      selector: "NombreIniciativa",
      width: 250,
      customRender: (row) => {
        return (
        <div className="d-flex align-items-center dropdown-in-table">
          <Dropdown onClick={(e) => e.stopPropagation()}>
            <Dropdown.Toggle
              variant="outline-primary"
              size="sm"
              className="dropdown-btn-ellipsis"
            >
              <i className="fa fa-ellipsis-h"></i>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* <Dropdown.Item
               //con otra vista
                  as={Link} 
                  to={`/initiative/`}
                   onClick={() => {
                    Swal.fire('Éxito', 'La Iniciativa ha sido Aprobada!', 'success');
                    }
                }
              >
                Enviar a Aprobación
              </Dropdown.Item> */}
              {/* <Dropdown.Item
                onClick={() => enviarSubsanacion(row.data.Id)}
              >
                Enviar a Subsanación
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => enviarStandBy(row.data.Id)}
              >
                Enviar a StandBy
              </Dropdown.Item> */}

              <Dropdown.Item
               as={Link} 
               to={`/InitiativeAssessment/${row.data.Id}`}
                /* onClick={() => enviarStandBy(row.data.Id)} */
              >
                Ir Evaluación
              </Dropdown.Item> 
            </Dropdown.Menu>
          </Dropdown>
          <span
            className="ml-2"
            style={{ maxWidth: "340px" }}
            title={row.data.NombreIniciativa}
          >
            {row.data.NombreIniciativa}
          </span>
        </div>
        );
      },
    },
    {
      caption: "Descripción",
      selector: "Descripcion",
      width: 250,
    },
    {
      caption: "Fecha de Inscripción",
      selector: "FechaIdentificacion",
      width: 110,
    },
    {
      caption: "Fecha de Estructuración",
      selector: "FechaCreacion",
      width: 110,
    },
    {
      caption: "Responsable",
      selector: "CreadoPor",
      width: 110,
    },
    {
      caption: "Estado",
      selector: "Estado",
      width: 110,
    },
    {
      caption: "Calificación %",
      selector: "Calificacion",
      width: 110,
    },
  ];
  
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="pt-3 pb-5 mb-5">
            {initiatives.length > 0 ? (
              <>
                <SuperTableV2 data={initiatives} columns={columns} />
              </>
            ) : (
              <>
                <div className="text-center pt-1">
                  <img
                    src={emptyImg}
                    alt="Sin datos"
                    className="img-fluid mb-3 animated pulse slower infinite"
                  />
                  <h5>¡Vaya!</h5>
                  <p>Parece que aún no tienes ninguna Iniciativa por Aprobación!</p>
                </div>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default WaitForApproval