import swal from "sweetalert";
import axios from "axios";
import { API_ROUTE, headersRequest } from "../../helpers";
import { formatDateToInput } from "./helpers_MGI";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";

const ModalForceRating = ({
    openModal,
    setOpenModal,
    handleOpenModal,
    initiative,
    setInitiative,
    update,
    setUpdate
}) =>{
    // const users = useSelector((state) => state.users); // redux
    const userName  = window.localStorage.getItem("nombreUser")
    const userID = window.localStorage.getItem("UID");
  const forceInitiative = async (ini, user, success) => {
    const iniForced = {
        Id : ini.Id,
        Reason : ini.razon,
        userForcer: userID
    };

    try {
      await axios.post(`${API_ROUTE}force_rating`, iniForced, headersRequest());
    } catch (error) {
        console.error(error)
    }
    setUpdate(!update);
  };

  const getForceReasons = (e) => {
    const reason =e.target.value;
    console.log(reason);
    setInitiative((prev) =>({
        ...prev,
        razon: reason,
    }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const success = (objName) => {
      swal(
        "¡Hecho!",
        `La Iniciativa ${objName} ha sido 'Calificada' correctamente!`,
        "success"
      )
    };
    forceInitiative(initiative, userName, success(initiative?.NombreIniciativa));
    handleOpenModal(setOpenModal);
  };


  return (
    <Modal size="lg" show={openModal} onHide={()=>handleOpenModal(setOpenModal)}>
      <Modal.Header closeButton>
        <Modal.Title>Forzar Calificación de Iniciativa</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-2">
            <Form.Label style={{ fontWeight: "700" }}>Nombre</Form.Label>
            <Form.Control
              name="NombreIniciativa"
              type="textarea"
              value={initiative?.NombreIniciativa || ""}
              readOnly={true}
            />
          </Form.Group>
          <Form.Group className="mb-2" >
            <Form.Label style={{ fontWeight: "700" }}>Descripción</Form.Label>
            <Form.Control
              name="Descripcion"
              as="textarea"
              value={initiative?.Descripcion || ""}
              rows={3}
              readOnly={true}
            />
          </Form.Group>
          <hr />
          <Row>
            <Col lg={4} sm={12} className="mb-3">
              <Form.Label style={{ fontWeight: "700" }}>Fecha Prevista de Inicio</Form.Label>
              <Form.Control
                name="FechaIdentificacion"
                type="date"
                value={formatDateToInput(initiative?.FechaIdentificacion) || ""}
                readOnly={true}
              />
            </Col>
            <Col lg={4} sm={12} className="mb-3">
              <Form.Label style={{ fontWeight: "700" }}>Fecha Creación</Form.Label>
              <Form.Control
                name="FechaRegistro"
                type="date"
                value={formatDateToInput(initiative?.FechaCreacion) || ""}
                readOnly={true}
              />
            </Col>
            <Col lg={4} sm={12} className="mb-3">
              <Form.Label style={{ fontWeight: "700" }}>
                Responsable de la Iniciativa
              </Form.Label>
              <Form.Control
                type="textarea"
                name="CreadoPor"
                value={initiative?.CreadoPor || ""}
                readOnly={true}
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={12} className="mb3">
                <Form.Label style={{ fontWeight: "700" }}>
                    Justificación
                </Form.Label>
                <Form.Control
                name="Justificación"
                as="textarea"
                rows={3}
                placeholder="Justificación por forzado de Calificación de Iniciativa"
                required
                onChange={(e) => {
                    getForceReasons(e);
                }}
                />
            </Col>
            </Row>
            <hr className="my-4"/>
            <Row>
            <Col lg={6} sm={12} className="mb-3">
              <Form.Label style={{ fontWeight: "700" }}>
                Usuario quien Autoriza Forzado de Iniciativa :
              </Form.Label>
              <Form.Control
                as="textarea"
                name="AutorizaForzado"
                value={userName}
                readOnly={true}
              />
            </Col>
          </Row>
          <Modal.Footer>
            <Button variant="dark" onClick={()=>handleOpenModal(setOpenModal)}>
              Cancelar
            </Button>
            <Button type="submit" variant="success">
              Guardar
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalForceRating;