export const tooltips_en = [
  {
    id: 0,
    location: "En el título de todas las columnas PRODUCT BACKLOG",
    msg: "Here you will be able to create your PBI (Product Backlog Item), and the order, will determine your priorities.",
  },
  {
    id: 1,
    location: "En el título de todas las columnas TO DO",
    msg: "Here you will be able to see the activities that are about to start, you can prioritize your attention with the order of each card that represents an activity.",
  },
  {
    id: 2,
    location: "En el título de todas las columnas DOING",
    msg: "Here you will be able to see the activities that are in progress, you will also be able to see the work team who is involved in their execution",
  },
  {
    id: 3,
    location: "En el título de todas las columnas DONE",
    msg: "Here you will be able to see the activities that have been completed and the notes that were recorded during their progress.",
  },
  {
    id: 4,
    location: "En el título de todas las columnas SPRINT",
    msg: "In this time interval, you will be able to group your activities to achieve objectives that do not exceed 4 weeks, in case you exceed it, you can create another Sprint and group your new activities",
  },
  {
    id: 5,
    location: "Botón proyectos",
    msg: "Here you will be able to view and create your projects, with Gantt and Kanban tools.",
  },
  {
    id: 6,
    location: "Botón tableros",
    msg: "Here you will be able to record the activities that you don't want to lose sight of and that are not part of a project.",
  },
  {
    id: 7,
    location: "Botón Tareas",
    msg: "This is your digital agenda, which will allow you to see with a timeline and an activity finder, everything you have to execute or that has been executed, either in projects or in your day to day life.",
  },
  {
    id: 8,
    location: "Botón productividad",
    msg: "Here you will be able to see your performance in projects and that of your work team.",
  },
  {
    id: 9,
    location: "Here you will be able to create your work team, note that the creation of users has an approval, which will allow you to enjoy an appropriate package for you.",
  },
  {
    id: 10,
    location: "Botón Crear en el centro de proyectos",
    // msg: "Aquí podrás estructurar tus proyectos, darles una identidad, planificar tus actividades y entregas, tu línea de tiempo y conformar un equipo de trabajo que te ayudará a lograr el objetivo que estas buscando!",
    msg: "Structure your project, plan activities and form your work team.",
  },
  {
    id: 11,
    location: "En el formulario de creación de proyecto, campo COD",
    msg: "It is important that your project can have an identification, therefore you can create a code that serves as a main identifier example: PRY001",
  },
  {
    id: 12,
    location: "En el formulario de creación de proyecto, campo NOMBRE",
    msg: "Assign a name to your project, which is descriptive and allows you to differentiate it from others.",
  },
  {
    id: 13,
    location: "En el formulario de creación de proyecto, campo METODOLOGÝA",
    msg: "Choose the methodology that suits you best, you can select gantt or kanban tool for its planning and execution.",
  },
  {
    id: 14,
    location: "En el formulario de creación de proyecto, campo DESCRIPCIÓN",
    msg: "Here you can have the main guide of the characterization of your project, therefore, you can contemplate what will be very useful for you in this descriptive field and that will allow you to achieve the scope you want to plan.",
  },
  {
    id: 15,
    location: "En el formulario de creación de proyecto, campo TIPO",
    msg: "Select the project typology, it will be very useful for you when creating reports.",
  },
  {
    id: 16,
    location: "En el formulario de creación de proyecto, campo PAÝS",
    msg: "Select the territory where you are going to execute your project.",
  },
  {
    id: 17,
    location: "En el formulario de creación de proyecto, campo GERENTE",
    msg: "Select the person in charge of managing your project",
  },
  {
    id: 18,
    location: "En el formulario de creación de proyecto, campo JUSTIFICACIÓN",
    msg: "Please describe the main reasons that motivated you to execute this project",
  },
  {
    id: 19,
    location: "En el formulario de creación de proyecto, campo ANTECEDETES",
    msg: "Please describe the previous work that was taken into account to execute this project or the possible problems you want to solve.",
  },
  {
    id: 20,
    location:
      "En el formulario de creación de proyecto, campo OBJETIVO GENERAL",
    msg: "What is the main purpose you want to achieve by executing this project?",
  },
  {
    id: 21,
    location:
      "En el formulario de creación de proyecto, campo OBJETIVOS ESPECÝFICOS",
    msg: "What are the main activities you want to execute to achieve the main goal of this project?",
  },
  {
    id: 22,
    location: "Here you will be able to create the main deliverables of your project, note that to achieve each deliverable, you will need to create the work plan in the Gantt.",
  },
  {
    id: 23,
    location: "En proyecto ágil, título historias de usuario",
    msg: "Here you will be able to create the main features or desirables that will allow you to achieve the goal of your project.",
  },
  {
    id: 24,
    location: "En el centro de proyectos título EN CURSO",
    msg: "Here you can find your projects to start or in progress. Just click on the name of the project, or on the options tab that accompanies it.",
  },
  {
    id: 25,
    location: "En el centro de proyectos título COMPLETADOS",
    msg: "Here you can see the projects that have all their activities completed. In case any of them are still running, your project will automatically be open and in the In Progress view.",
  },
  {
    id: 26,
    location: "Textbox Buscar en el centro de proyectos",
    msg: "Type part of the name of your project and we will help you to find it.",
  },
  {
    id: 27,
    location:
      "En el centro de proyectos, en la pestaña de opciones dle proyecto: Ver Hoja de Vida",
    msg: "Here you can see an executive and consolidated report of the health status of your project.",
  },
  {
    id: 28,
    location: "Botón crear tablero Kanban",
    msg: "Here you can record the activities that you do not want to lose sight of and that are not part of a project, create dashboards that allow you to identify activities such as: regulatory, routine, internal control, operation in general, etc.",
  },
  {
    id: 29,
    location: "título Tareas asignadas en el calendario",
    msg: "This is your digital agenda, which will allow you to see on the calendar everything you have to execute or that has been executed, whether in traditional projects, agile or in your day to day.",
  },
  {
    id: 30,
    location: "título proyectos en el calendario",
    msg: "In this space you will be able to find your activities organized by projects or that are not part of them. You will be able to easily locate the activities in execution, to be started, highlighted or finished.",
  },
  {
    id: 31,
    location: "Textbox Buscar en vista proyectos dentro del calendario",
    msg: "Type part of the name of your activity and we will help you find it.",
  },
  {
    id: 32,
    location: "Formulario creación de proyecto, campo Metodología (Ayuda)",
    msg: "Don't know which methodology to choose?, find out by taking our quiz, just click on the question mark.",
  },
  {
    id: 33,
    location: "Formulario creación de proyecto, campo ScrumMaster",
    msg: "Select the Scrum Master for your project.",
  },
  {
    id: 34,
    location: "Formulario editar tarea ágil campo horas por ejecutar",
    msg: "This is the estimated amount of hours to execute this activity.",
  },
  {
    id: 35,
    location: "PlanningBoard Lane-header alert fecha maxima",
    msg: "One of your activities has a completion date after the Sprint completion date.",
  },
  {
    id: 36,
    location: "PlanningBoard Lane-header alert fecha minima",
    msg: "One of your activities has start date earlier than the Sprint start date.",
  },
  {
    id: 37,
    location: "PlanningBoard Lane-header alert fecha minima y alert fecha maxima",
    msg: "One of your activities has a start and end date outside the Sprint range.",
  },
  {
    id: 38,
    location: "título Tareas asignadas en el calendario",
    msg: "This is your digital calendar, which will allow you to see everything you have to execute or that has been executed, either in traditional projects, agile or in your day-to-day.",
  },
  {
    id: 39,
    location: "Botón boards",
    msg: "Here you will be able to see summary information about your organization, the projects you are executing and the use of your resources.",
  },
  {
    id: 40,
    location: "Modal EdiTask",
    msg: "This is the complexity value of the activity according to the Product Owner's criteria on a scale from 1 to 100.",
  },
  {
    id: 41,
    location: "Top-Bar icon-MasServiciosPAS",
    msg: "Project Advisory Services: Project Management Consulting Services.",
  },
  {
    id: 42,
    location: "sideBar",
    msg: "Need Help?",
  },
  {
    id: 43,
    location: "PlanningBoard Lane-header alert excedio fechas",
    msg: "The start or end date of this activity exceeds the Sprint deadlines ",
  },
  {
    id:44,
    location: "Hv Agile project's indicator",
    msg:"Deviation criterion based on the difference between the Planned % and Actual %."
  },
  {
    id:45,
    location: "Module-Risks-Icon",
    msg:"Here you can manage your risks"
  },
  {
    id:46,
    location: "RiskToolTipkOne",
    msg:"This is a simple view where you can create the risks of your projects"
  },
  {
    id:47,
    location: "RiskToolTipkOne",
    msg:"This is a compact view where you can quickly view and create your risks"
  },
  {
    id:48,
    location: "RiskToolTipkOne",
    msg:"This is the initial state of the risk"
  },
  {
    id:49,
    location: "RiskFormToolTipInputTwo",
    msg:"Select the person in charge of identifying this risk"
  },
  {
    id:50,
    location: "RiskFormToolTipInputThree",
    msg:"Select the area that will be affected by this risk"
  },
  {
    id:51,
    location: "RiskTableDescriptionImpacto MuyAlto",
    msg:"Catastrophic consequences with significant and prolonged impact"
  },
  {
    id:52,
    location: "RiskTableDescriptionImpacto Alto",
    msg:"Serious consequences with considerable impact."
  },
  {
    id:53,
    location: "RiskTableDescriptionImpacto Medio",
    msg:"Moderate consequences with notable impact."
  },
  {
    id:54,
    location: "RiskTableDescriptionImpacto Bajo",
    msg:"Minor consequences with limited impact."
  },
  {
    id:55,
    location: "RiskTableDescriptionImpacto MuyBajo",
    msg:"Negligible consequences with very limited impact."
  },
  {
    id:56,
    location: "RiskTableDescriptionOcurrencia MuyProbable",
    msg:"High probability that the event will occur in the short term. It will almost certainly happen."
  },
  {
    id:57,
    location: "RiskTableDescriptionOcurrencia Probable",
    msg:"Significant probability that the event will occur in the short or medium term. It is very possible that it will happen."
  },
  {
    id:58,
    location: "RiskTableDescriptionOcurrencia Posible",
    msg:"There is a moderate probability that the event will occur in the medium term. It could happen."
  },
  {
    id:59,
    location: "RiskTableDescriptionOcurrencia Improbable",
    msg:"Low probability that the event will occur in the medium or long term. It is unlikely to happen."
  },
  {
    id:60,
    location: "RiskTableDescriptionOcurrencia MuyImprobable",
    msg:"Very low probability of the event occurring in the long term. It is highly unlikely to happen."
  },
  {
    id:60,
    location: "RiskDateToolTipe",
    msg:"If you do not update the date, the date that was already registered will be saved."
  },
  {
    id: 61,
    location: "Initiative Management",
    msg: "The management of initiatives in strategic planning for companies involves identifying, evaluating, and prioritizing innovative ideas and improvement opportunities. These initiatives are transformed into projects, aligned with strategic objectives, driving organizational growth and competitiveness."
  }
];
