import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import TaskList from "./components/TaskList";
import TimelineResource from "./components/TimeLineResource";

const HvResource = ({ user, dataFilter }) => {
  const [currentView, setView] = useState("taskList"); // Controlar vista activa
  return (
    <Container fluid>
      <Row>
        {/*  <Col md={3}>
            <Sidebar />
          </Col> */}
        <Col md={12}>
          <Header user={user} currentView={currentView} setView={setView} />
          {currentView === "taskList" && (
            <TaskList user={user} dataFilter={dataFilter} />
          )}
          {currentView === "timeline" && (
            <TimelineResource user={user} dataFilter={dataFilter} />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default HvResource;
