import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import enUS from 'date-fns/locale/en-US';
import { formatISO, parse, setHours, setMinutes } from 'date-fns';
import { es } from 'date-fns/locale';

registerLocale('es',es)

const DatePickerBlock = ({ task, ganttMet, excluded=[] }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [durationInDays,setDurationInDays] = useState("")
  const [durationInHours , setDurationInHours] = useState("")

  const durationInDaysfn = (start,end)=> {
//    let days  = (ganttMet.calculateDuration(start,end, "hours") / 8);  //estaba asi originalmente
    let days  = (ganttMet.calculateDuration(start,end, "hours"));
    setDurationInDays(days)
  } 

  const durationInHoursfn = (start,end)=> {
//    let hours  = (ganttMet.calculateDuration(start,end, "hours")); // asi estaba originalmente
    let hours  = (ganttMet.calculateDuration(start,end, "hours")/0.125); // 8 hrs laborales .125
    setDurationInHours(hours)
  } 

  useEffect(()=>{
    if(task) {
      setStartDate(new Date(task?.start_date))
      setEndDate( new Date(task?.end_date))
    }
   
  },[task])
  
  useEffect(()=>{
    if(startDate && endDate ){
      durationInDaysfn(startDate,endDate)
      durationInHoursfn(startDate,endDate)
    }

  },[task,startDate,endDate])

  const handleStartDateChange = (date) => {
    setStartDate(date);
    ganttMet.getTask(task.id).start_date = date
  };

  const handleEndDateChange = (date) => {
    if (date < startDate) {
      date = startDate;
    }
    setEndDate(date);
    ganttMet.getTask(task.id).end_date = date;
  };


  const convertToISO = (date) =>{
    const parseDate  = parse(date,'yyyy/MM/dd' , new Date())
    return  parseDate
  }
  // const allExcludeDates = excluded?.map((date) => {
  //   return convertToISO(date)
  //   //new Date(date)
  // } );

 
  return (
    <>
    <div className='d-flex mb-3 ml-1' style={{gap:"40px", width:"70%"}}>
      <div className=''>
        <span style={{fontSize:"13px"}} className='ml-1'>Duración (días) : </span>
        <span style={{fontSize:"13px"}} >{durationInDays} </span>
      </div>
      <div className=''>
        <span  style={{fontSize:"13px"}} className='ml-1'>Duración (horas) : </span>
        <span style={{fontSize:"13px"}} >{durationInHours} </span>
      </div>
    </div>
    <div className='gantt_cal_ltext' style={{display:'flex', justifyContent:'space-evenly'}}>
      <ReactDatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        showTimeSelect
        dateFormat='dd-MM-yyyy HH:mm'
        className='gantt_input'
        locale = "es"
       // excludeDates={allExcludeDates}
        timeIntervals ={60}
        minTime = {setHours(setMinutes(new Date(),0),7)}
        maxTime = {setHours(setMinutes(new Date(),0),17)}
        filterDate ={date => date.getDay() !== 6 && date.getDay() !== 0}
      />
      <ReactDatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        showTimeSelect
        filterDate ={date => date.getDay() !== 6 && date.getDay() !== 0}
        dateFormat='dd-MM-yyyy HH:mm'
        className='gantt_input'
        locale = "es"
       /*  startDate={start} */
        //excludeDates={allExcludeDates}
        timeIntervals ={60}
        minTime = {setHours(setMinutes(new Date(),0),7)}
        maxTime = {setHours(setMinutes(new Date(),0),17)}
      />
    </div>
    </>
    );
};

export default DatePickerBlock;