import React, { useEffect, useState, useRef} from 'react';
import { useParams, useHistory, Link } from "react-router-dom";
import Layout from '../../Layout';
import TitleCommon from '../TitleCommon';
import { Container, Row, Col, Form, Button, Modal, Table, Card } from 'react-bootstrap';
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import GanntStructuring from './StructuringGantt_DX';
import axios from 'axios';
import { API_ROUTE, headersRequest } from '../../helpers';
import Swal from 'sweetalert2';
import { formatDateToInput, updateState } from './helpers_MGI';
import SuperTableV2 from '../SuperTableV2';
import FileUploader from './FileUploader';

const Structuring = () => {
  const { idInitiative } = useParams();
  const history = useHistory();
  const [initiativeToStructure, setInitiativeToStructure] = useState(null);
  const [objectives, setObjectives] = useState([]);
  const [criterios, setCriterios] = useState([]);
  const [initiatives, setInitiatives] = useState([]);
  const [selectedImpacts, setSelectedImpacts] = useState([]);
  const [validated, setValidated] = useState(false);
  const [t, i18n] = useTranslation("initiatives");

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  let title_i18 = t("iniciativas_title");

  const idsToFilter = [
    'CFAA5266-943D-47AE-91AE-3B16C9662A9E', //En Inscripción
    'D13C16B6-27E6-4ADE-BAAA-D59E3796504E', //En Estructuración
    // '29935AC9-9493-4659-B78A-D5B847299441' //En Subsanación
  ];

  const getAllInitiatives = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}listariniciativas`, headersRequest());
      const formatDates = res.data.map(initiative => ({
        ...initiative,
        FechaIdentificacion : formatDateToInput(initiative.FechaIdentificacion),
        FechaCreacion : formatDateToInput(initiative.FechaCreacion),
      }));
      const filteredInitiatives = formatDates.filter(d => idsToFilter.includes(d.IdEstado));
      setInitiatives(filteredInitiatives);
    } catch (error) {
      console.error(error);
    }
  };

  const getInitiative = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}iniciativa/${idInitiative}`, headersRequest());
      const initiative = res.data;
      setInitiativeToStructure(initiative || []);
    } catch (error) {
      console.error(error);
    }
  };

  const getAllCriterios = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}criterios`, headersRequest());
      setCriterios(res.data || []);
      //console.log(criterios)
    } catch (error) {
      console.error(error);
    }
  };
  const getAllObjetives = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}strategy`, headersRequest());
      setObjectives(res.data || []);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getInitiative();
  }, [idInitiative]);
  useEffect(() => {
    getAllInitiatives();
    getAllCriterios();
    getAllObjetives();
  }, []);

  // const handleImpactChange = (objectiveId, newValue) => {
  //   setSelectedImpacts((prevImpacts) => (
  //     {...prevImpacts,
  //     [objectiveId]:newValue}
  //   ));
  // };
  const handleImpactChange = (objectiveId, newValue) => {
    setSelectedImpacts((prevImpacts) => {
      // Verificar si el impacto ya existe
      const existingImpactIndex = prevImpacts.findIndex(impact => impact.cod === objectiveId);
  
      if (existingImpactIndex >= 0) {
        // Si existe, actualizar el valor
        return prevImpacts.map((impact, index) =>
          index === existingImpactIndex
            ? { ...impact, valor: newValue }
            : impact
        );
      } else {
        // Si no existe, agregar un nuevo impacto
        return [...prevImpacts, { cod: objectiveId, valor: newValue }];
      }
    });
  };
  const getImpactValue = (objectiveId) => {
    const impact = selectedImpacts.find(impact => impact.cod === objectiveId);
    return impact ? impact.valor : '';
  };
  

  const handleSave = async () =>{
    const dataToEvaluate = {
      id: idInitiative,
      data: selectedImpacts
    };
    await axios.post(`${API_ROUTE}initiatives_to_rating/`, dataToEvaluate, headersRequest());
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   const form = event.currentTarget;

  //   if (form.checkValidity() === false) {
  //     event.stopPropagation();
  //   } else {
  //     Swal.fire('Éxito', 'La Iniciativa ha sido estructurada satisfactoriamente!', 'success');
  //     handleSave();
  //   }
  //   setValidated(true);
  // };
  
  const showAlert = async (event) => {

    const response = await axios.get(`${API_ROUTE}task_count/${idInitiative}`, headersRequest());
    const taskCount = response.data.taskCount;
    console.log(response,"taskCount");

    event.preventDefault(); // Prevenir el envío del formulario
    const form = event.target.closest('form');

    if (!form) {
        console.error("El formulario no está disponible.");
        return; // Salir si el formulario no está disponible
    }

    const result = await Swal.fire({
        title: '¿Qué deseas hacer?',
        showCancelButton: true,
        confirmButtonText: 'Guardar',
        cancelButtonText: 'Cancelar',
        confirmButtonColor: '#db526a',
    });

    if (result.isConfirmed) {
        const isValid = Array.from(form.elements).every(input => {
            if (input.required && !input.value) {
                return false; // Un campo requerido está vacío
            }
            return true; // Todos los campos requeridos están llenos
        });

        if (!isValid) {
            Swal.fire('Error', 'Por favor completa todos los campos requeridos.', 'error');
            return; // Salir si hay campos vacíos
        }

        if (taskCount === 0) {
          await Swal.fire({
              title: 'Error',
              text: 'Al menos debes crear una Actividad para determinar el [Tiempo-Costo-Esfuerzo]',
              icon: 'error',
              confirmButtonText: 'Aceptar',
          });
          return; // Detener el proceso si no hay tareas
        }

        // Si el formulario es válido, se guarda
        await handleSubmit(form, 'save'); // Guardar y esperar que se complete

        // Luego pregunta si desea enviar a calificación
        const sendResult = await Swal.fire({
            title: '¿Quieres Cambiar de Estado la Iniciativa?',
            showCancelButton: true,
            confirmButtonText: 'Sí, Enviar a Calificación',
            cancelButtonText: 'No, Dejar en Estructuración',
            confirmButtonColor: '#db526a',
        });

        if (sendResult.isConfirmed) {
            await handleSubmit(form, 'submit'); // Pasar el formulario directamente
        } else {
            console.log('Dejar en estructuración');
            history.push("/initiatives"); // Redirigir después de guardar
        }
    } else if (result.isDismissed) {
        console.log('cancel');
    }
};

const handleSubmit = async (form, action) => {
  const isValid = Array.from(form.elements).every(input => {
      if (input.required && !input.value) {
          return false; // Un campo requerido está vacío
      }
      return true; // Todos los campos requeridos están llenos
  });

  if (!isValid) {
      Swal.fire('Error', 'Por favor completa todos los campos requeridos.', 'error');
      return; // Salir si hay campos vacíos
  }

  // Lógica según la acción
  if (action === 'save') {
      await Swal.fire({
          title: 'Éxito',
          text: 'La Iniciativa ha sido estructurada satisfactoriamente!',
          icon: 'success',
          timer: 3000, // Cierra después de 3 segundos
          timerProgressBar: true,
          showConfirmButton:false,
      });
      await handleSave(); // Asegúrate de que handleSave sea una función asíncrona si tiene operaciones asíncronas
      setValidated(true);
  } else if (action === 'submit') {
      const IdState2 = '3AEDC141-1E04-4411-8FC9-71A6CA13BEC4'; // Estado Calificación
      try {
          await updateState(idInitiative, IdState2);
          await handleSave();
          Swal.fire({
              title: 'Éxito',
              text: 'La Iniciativa ha sido enviada a Calificación!',
              icon: 'success',
              timer: 3000,
              timerProgressBar: true,
              showConfirmButton:false,
          });
          setValidated(true);
          history.push("/initiatives"); // Redirigir después de enviar
      } catch (error) {
          Swal.fire('Error', 'Hubo un problema al enviar a Calificación', 'error');
          console.log(error);
      }
  }
  setValidated(true);
};

const IdState = 'D13C16B6-27E6-4ADE-BAAA-D59E3796504E';//En Estructuración

const columns = [
  {
    caption: "Nombre",
    selector: "NombreIniciativa",
    width: 250,
    customRender: (row) => {
      return (
      <div className="d-flex align-items-center dropdown-in-table">
        <Button
          //con otra vista
          as={Link} 
          to={`/initiative/${row.data.Id}`}
          onClick={() => updateState(row.data.Id, IdState)}
          className='btn-sm'
          variant="outline-primary"
          size="sm"
        >
          <i 
          className="fas fa-toolbox"
          data-tip={(t("iniciativas_lenguaje") === "es") ? "Estructurar está Iniciativa" : "Structuring this Initiatives"}
          ></i>
        </Button>
        <span
          className="ml-2"
          style={{ maxWidth: "340px" }}
          title={row.data.NombreIniciativa}
        >
          {row.data.NombreIniciativa}
        </span>
      </div>
      );
    },
  },
  {
    caption: "Descripción",
    selector: "Descripcion",
    width: 250,
  },
  {
    caption: "Fecha Prevista de Inicio",
    selector: "FechaIdentificacion",
    width: 110,
  },
  {
    caption: "Responsable",
    selector: "CreadoPor",
    width: 110,
  },
  {
    caption: "Estado",
    selector: "Estado",
    width: 110,
  },
];
  
const filteredCriterios = criterios.filter(criterio => criterio.Peso > 0);
const filteredObjectives = objectives.filter(obj => obj.Peso > 0);

  // si no enviaron a estructurar una iniciativa
  if (!initiativeToStructure) {
    return(
      <>
        <Container fluid>
          <Row>
              <h2>
                <small>Inicitivas por Estructurar</small>
              </h2>
          </Row>
          <Row>
            <SuperTableV2 data={initiatives} columns={columns} />
          </Row>
        </Container>
      </>
    );
  };

  return (
    <>
      <Layout>
        <TitleCommon
          title={{
            text: "Estructuración",
            icon: "tools"
          }}
          color={"secondary"}
          goBack={{
              text: "Volver",
              icon: "arrow-left",
            }}
        />
        <Container fluid>
          <Form 
            noValidate 
            validated={validated} 
            //onSubmit={showAlert}
          >
            <Card className="bg-light my-5">
              <Card.Body className="px-2 pt-2 pb-0">
                <Row>
                  <Col md={3} sm={12} >
                    <Form.Group>
                      <Form.Label>Nombre de la Iniciativa</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        value={initiativeToStructure?.NombreIniciativa}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={3} sm={12} >
                    <Form.Group>
                      <Form.Label>Responsable</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={1}
                        value={initiativeToStructure?.CreadoPor}
                        disabled
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} sm={12} className='text-right'>
                    <Form.Group>
                    <Button 
                      variant="success" 
                      type='button'  
                      className='mt-4 mr-3'
                      onClick={(e) => showAlert(e)} // Pasar el evento aquí
                    >
                      Guardar | Enviar
                    </Button>
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
            <Card className="my-3 bg-light">
              <Card.Body>
              <Card.Title className='d-flex justify-content-between align-items-center'>
                  <div className="">
                    Objetivos Estratégicos<br/>
                    <small style={{color:"#828285", fontSize:"60%"}}>
                    Configurados: {filteredObjectives?.length}
                    </small>
                  </div>
                  {filteredObjectives?.length > 0 && (
                    <Button 
                    variant="secondary"   
                    className=''
                    as={Link} 
                    to={`/configstrategy/two`}
                    style={{display:'none'}}
                  >
                    Ir a Configurar
                  </Button>
                  )}
                </Card.Title>
                <Table striped hover className='mt-5'>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Nombre del Objetivo</th>
                          <th>Descripción</th>
                          <th>Pregunta
                          </th>
                          <th>Respuesta</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredObjectives?.length> 0 ? (
                          filteredObjectives.map((objective, index) => (
                            <tr key={objective.Id || index}>
                              <td>{index + 1}</td>
                              <td>{objective.NombreObjetivo}</td>
                              <td>{objective.Descripcion}</td>
                              <td>{objective.Pregunta}</td>
                              <td>
                              <Form.Control
                                as="select"
                                name="Impacto"
                                required
                                //value={selectedImpacts[objective.Id] || ''}
                                value={getImpactValue(objective.Id) || ''}
                                onChange={(e) => handleImpactChange(objective.Id, e.target.value)}
                              >
                                <option value="" disabled>Selecciona un Impacto</option>
                                <option value="ImpactoNulo">{objective.ImpactoNulo}</option>
                                <option value="ImpactoBajo">{objective.ImpactoBajo}</option>
                                <option value="ImpactoModerado">{objective.ImpactoModerado}</option>
                                <option value="ImpactoCritico">{objective.ImpactoCritico}</option>
                                <option value="ImpactoExtremo">{objective.ImpactoExtremo}</option>
                              </Form.Control>
                              </td>
                            </tr>
                          ))
                        ) :
                        (
                          <td 
                            colSpan={12}
                            className='text-center align-items-center'
                          >
                            No hay Objetivos Estratégicos configurados. 
                            <br/>
                            <Button 
                              variant="secondary" 
                              className='mt-4'
                              as={Link} 
                              to={`/configstrategy/two`}
                            >
                              Ir a Configurar
                            </Button>
                          </td>
                        )}
                      </tbody>
                    </Table>
              </Card.Body>
            </Card>
            <Card className="my-3 bg-light">
              <Card.Body>
                <Card.Title className='d-flex justify-content-between align-items-center'>
                  <div className="">
                    Parámetros / Criterios <br/>
                    <small style={{color:"#828285", fontSize:"60%"}}>
                    Configurados: {filteredCriterios?.length}
                    </small>
                  </div>
                  {filteredCriterios?.length > 0 && (
                    <Button 
                    variant="secondary"   
                    className=''
                    as={Link} 
                    to={`/configstrategy/two`}
                    style={{display:'none'}}
                  >
                    Ir a Configurar
                  </Button>
                  )}
                </Card.Title>
                <Table striped hover className='mt-5'>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Párametro / Criterio</th>
                          <th>Descripción</th>
                          <th>Pregunta</th>
                          <th>Respuesta</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredCriterios?.length> 0 ? (
                          filteredCriterios.map((criterio, index) => (
                            <tr key={criterio.Id || index}>
                              <td>{index + 1}</td>
                              <td>{criterio.Parametro} <br/> <small>{criterio.NombreCriterio}</small></td>
                              <td>{criterio.Descripcion}</td>
                              <td>{criterio.Pregunta}</td>
                              {criterio.Tipo === 'Cuantitativo' ? (
                                <td>
                                  <Form.Control
                                    as="input"
                                    type="number"
                                    name="Respuesta"
                                    placeholder={criterio?.Abrev || ''}
                                    value={criterio.Respuesta}
                                    onChange={(e) => handleImpactChange(criterio.Id, e.target.value)}
                                    required
                                  />
                                </td>
                              ) : (
                                <td>
                                  <Form.Control
                                    as="select"
                                    name="Respuesta"
                                    required
                                    value={criterio.Respuesta}
                                    onChange={(e) => handleImpactChange(criterio.Id, e.target.value)}
                                  >
                                    <option selected value="" disabled>Selecciona una Respuesta</option>
                                    <option value="CualitativoAlta">{criterio.CualitativoAlta}</option>
                                    <option value="CualitativoMedia">{criterio.CualitativoMedia}</option>
                                    <option value="CualitativoBaja">{criterio.CualitativoBaja}</option>
                                  </Form.Control> 
                                </td>
                              )}
                            </tr>
                          ))
                        ) :
                        (
                          <td 
                            colSpan={12}
                            className='text-center align-items-center'
                          >
                            No hay Parámetros / Criterios configurados. 
                            <br/>
                            <Button 
                              variant="secondary" 
                              className='mt-4'
                              as={Link} 
                              to={`/configstrategy/two`}
                            >
                              Ir a Configurar
                            </Button>
                          </td>
                        )
                        }
                      </tbody>
                    </Table>
              </Card.Body>
            </Card>
            <Card className="my-3 bg-light">
              <Card.Body>
                <Card.Title>
                  Tiempo - Costo - Esfuerzo
                </Card.Title>
                <GanntStructuring
                  idInitiative={idInitiative}
                />
                <div className='mt-4'>
                  <FileUploader/>
                </div>
              </Card.Body>
            </Card>
          </Form>
        </Container>
      </Layout>
    </>
  );
};

export default Structuring;