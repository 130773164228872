import axios from 'axios';
import Swal from 'sweetalert2';
import { API_ROUTE, headersRequest } from '../../helpers';
// import { format } from 'date-fns';
// import { gantt } from 'dhtmlx-gantt';

export const formatDateToInput = (date) => {
  if (!date) return "";
  const d = new Date(date);

  // Sumar 5 horas
  d.setHours(d.getHours() + 5);

  const year = d.getFullYear();
  const month = String(d.getMonth() + 1).padStart(2, '0'); // Los meses son 0-indexed
  const day = String(d.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
};

export const setLightBoxSections = (gantt) => {
  
  gantt.locale.labels.section_description = "Actividad";
  gantt.locale.labels.section_time = "Duración";
  gantt.locale.labels.section_cost = "Costo";
  gantt.locale.labels.section_owner = "Recursos";

  gantt.config.lightbox.sections = [
    {
      name: "description",
      label: "Actividad",
      height: 40,
      map_to: "text",
      type: "textarea",
      focus: true,
    },
    { name: "time", 
      height: 35, 
      map_to: "auto", 
      type: "datepicker" 
    },
    {
      name: "cost",
      label: "Costo $",
      height: 40,
      map_to: "cost",
      type: "textarea",
    },
    {
      name: "owner", 
      height: 150,
      type: "resources", 
      map_to: "owner_id", 
      default_value: 8,
      options: gantt.serverList("resourceList")
    },
  ];

  gantt.render();
};

// CRUD de dataCreator
export const createTask = async (data) =>{
  try {
    const response = await axios.post(`${API_ROUTE}initiative_tasks`, data, headersRequest());
    return response.data;
  } catch (error) {
    console.error("error al crear tarea : ",error);
    throw error;
  }
};

export const updateTask = async (id, data) =>{
  try {
    const response = await axios.put(`${API_ROUTE}initiative_tasks/${id}`, data, headersRequest());
    return response.data;
  } catch (error) {
    console.error("error al actualizar la tarea :", error);
    throw error;
  }
};

export const deleteTask = async (id) =>{
  try {
    await axios.delete(`${API_ROUTE}initiative_tasks/${id}`, headersRequest());
    
    // Mensaje de éxito
    await Swal.fire({
        title: 'Éxito!',
        text: 'Eliminación de Tarea exitosa!',
        icon: 'success',
        timer: 2000,
        showConfirmButton: false
    });
    } catch (error) {
        console.error("Error al eliminar la tarea:", error);
        throw error;
    }
};

export const setScaleConfig = (gantt, level) => {
  switch(level) {
    case "day":
      gantt.config.scales = [
        {unit: "day", step: 1, format: "%d %M"}
      ];
      gantt.config.scale_height = 27;
      //gantt.config.min_column_width = 70;
      break;
    case "week":
      var weekScaleTemplate = function (date) {
        const dateToStr = gantt.date.date_to_str("%d %M");
        const endDate = gantt.date.add(gantt.date.add(date, 1, "week"), -1, "day");
        return dateToStr(date) + " - " + dateToStr(endDate);
      };
      gantt.config.scales = [
        {unit: "week", step: 1, format: weekScaleTemplate},
        {unit: "day", step: 1, format:"%D"}
      ];
      gantt.config.scale_height = 50;
      //gantt.config.min_column_width = 70;
      break;
    case "month":
      gantt.config.scales = [
        {unit: "month", step: 1, format: "%F, %Y"},
        {unit: "day", step: 1, format: "%j, %D"}
      ];
      gantt.config.scale_height = 50;
      //gantt.config.min_column_width = 70;
      break;
    case "year":
      gantt.config.scales = [
        {unit: "year", step: 1, format:"%Y"},
        {unit: "month", step: 1, format: "%M"}
      ];
      gantt.config.scale_height = 90;
      //gantt.config.min_column_width = 70;
      break;
    default:
      break;
  }
};

export const getResourceNameById = (resList, resItm)=>
  {
    //console.log("resname:"+JSON.stringify(resItm));
    for(let itm of resList)
    {
      if(resItm === itm.key)
      {
          return itm.label;
      }      
    }
    return "X";
  }
  
export const getResourceNames = (resList, resKeys) => {
  let assigned = "";
  if(resKeys != null && resKeys.length > 0)
  {
    for (let key of resKeys) {
      if(assigned.length === 0)
        assigned += getResourceNameById(resList, key.resource_id);
      else
        assigned += '/' + getResourceNameById(resList, key.resource_id);
    }
  }
  //console.log("assigned:"+JSON.stringify(assigned));
  return assigned;
};

export const setTask = (data, gantt) => {
  let task = [];
  for (let row of data) {
    let res = [];
    if (row.resources?.length > 0) {
      for (let itm of row.resources) {
        res.push({
          resource_id: itm.IdRecurso,
          value: itm.HorasAsignadas,
          asignacion_id: itm.IdAsignacion,
        });
      }
    }
    // Ahora, tanto el valor mínimo como el máximo están incluidos en el resultado.
    task.push({
      id: row.id,
      text: row.text,
      start_date: row.start,
      end_date: row.end,
      progress: row.progress,
      parent: row.parent,
      cost: row.cost,
      //type: row.tipo,
      open: true,
      owner_id: res,
    });
  }
  let alltask = { data: task };
  // console.log(task, "helper");
  gantt.parse(alltask);
  gantt.render();
};

export const configLayout = {
  css: "gantt_container",
  cols: [
    {
      width: 700,
      min_width: 300,
      rows: [
        { view: "grid", scrollX: "gridScroll", scrollable: true, scrollY: "scrollVer",},
        { view: "scrollbar", id: "gridScroll", group: "horizontal" },
      ],
    },
    { resizer: true, width: 1 },
    {
      rows: [
        { view: "timeline", scrollX: "scrollHor", scrollY: "scrollVer" },
        { view: "scrollbar", id: "scrollHor", group: "horizontal" },
      ],
    },
    { view: "scrollbar", id: "scrollVer" },
  ],
};


export const updateState = async (Id, IdState) =>{
  const data = {
    IdEstado: IdState,
    IdIniciativa: Id,
  };
  try {
    await axios.post(`${API_ROUTE}iniciativa_state`,data, headersRequest());
  } catch (error) {
      console.error(error)
  };
};