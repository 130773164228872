import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { API_ROUTE, headersRequest, inputChange } from "../../helpers";
import swal from "sweetalert";
import axios from "axios";
import Swal from "sweetalert2";

const ModalRegCriterios = ({
  openModal,
  setOpenModal,
  handleOpenModal,
  criterio,
  setCriterio,
  addCriterios,
  updateCriterios,
  action,
}) => {
  const titleModal = action === "create" ? "Crear" : "Editar";
  const urlToSave = action === "create" ? "criterios" : `criterios/${criterio?.Id}`;
  const [ddlParameters, setDdlParameters] = useState([]);
  const [ddlCriterios, setDdlCriterios] = useState([]);
  const [tipoCrit, setTipoCrit] = useState("");
  const [showAddParametroModal, setShowAddParametroModal] = useState(false);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [showAddUnidadModal, setshowAddUnidadModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [categorias, setCategorias] = useState([]);
  const [unidadesMedida, setUnidadesMedida] = useState([]);
  const [categoriaSelected, setCategoriaSelected] = useState("");
  const [unidadSeleccionada, setUnidadSeleccionada] = useState("");
  const [unidadesFiltradas, setUnidadesFiltradas] = useState([]);
 // const [categoriasUnidades, setCategoriasUnidades] = useState("");
  const [modeParm, setModeParm] = useState('new');
  const [modeCategoria, setModeCategoria] = useState('new');
  const [modeUnit, setModeUnit] = useState('new');
  const [selectedId, setSelectedId] = useState(null);
  const [newParametro, setNewParametro] = useState("");
  const [newCategoria, setNewCategoria] = useState("");
  const [newUnidad, setNewUnidad] = useState({
    UnidadMedida: "",
    AbreviaturaUnidad: "",
    IdCategoria: "",
  });
  
  const getCategories = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}categorias`, headersRequest());
      setCategorias(res.data.categorias);
      setUnidadesMedida(res.data.unidades);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (action === "create") {
      // Solo reinicia si los criterios no están definidos
      if (!criterio || Object.keys(criterio).length === 0) {
        setCategoriaSelected(''); // No hay categoría seleccionada
        setTipoCrit(''); // Limpia el tipo de criterio
    }
    } else if (criterio?.IdCategoria) {
      setCategoriaSelected(criterio.IdCategoria); // En modo de edición, preseleccionamos la categoría del criterio
    }

    // Aquí manejamos el tipo de criterio
    if (action === "edit" && criterio?.IdTipoCriterio) {
      const selectedTipoCrit = ddlCriterios.find(cri => cri.Id === criterio.IdTipoCriterio);
      setTipoCrit(selectedTipoCrit ? selectedTipoCrit.TipoCriterio : '');
    }
  }, [action, criterio, ddlCriterios]);

  useEffect(() => {
    if (categoriaSelected) {
      const filtro = unidadesMedida.filter(unid => unid.IdCategoria === categoriaSelected);
      setUnidadesFiltradas(filtro);
    } else {
      setUnidadesFiltradas([]);
    }
  }, [categoriaSelected, unidadesMedida]);
  
  useEffect(() => {
    if (unidadesFiltradas.length === 1) {
      // Si solo hay una unidad, selecciona esa unidad automáticamente
      const singleOptionValue = unidadesFiltradas[0].Id;
      if (criterio.IdUnidadMedida !== singleOptionValue) {
        setCriterio(prevCriterio => ({
          ...prevCriterio,
          IdUnidadMedida: singleOptionValue
        }));
        setUnidadSeleccionada(singleOptionValue);
      }
    } else if (unidadesFiltradas.length === 0) {
      // Si no hay unidades, limpia la selección
      setUnidadSeleccionada('');
      if (criterio.IdUnidadMedida) {
        setCriterio(prevCriterio => ({
          ...prevCriterio,
          IdUnidadMedida: ''
        }));
      }
    } else if (unidadesFiltradas.length > 1) {
      // Si hay más de una unidad, asegúrate de que el criterio actual esté en la lista de unidades
      if (!unidadesFiltradas.some(unidad => unidad.Id === criterio.IdUnidadMedida)) {
        setCriterio(prevCriterio => ({
          ...prevCriterio,
          IdUnidadMedida: '' // O un valor predeterminado si es necesario
        }));
      }
    }
  }, [unidadesFiltradas, criterio.IdUnidadMedida, setCriterio, setUnidadSeleccionada]);

  const handleCategoriaChange = (e) =>{
    setCategoriaSelected(e.target.value); 
  }
  const handleUnidadChange = (e) => {
  const selectedValue = e.target.value;
  setCriterio(prevCriterio => ({
    ...prevCriterio,
    IdUnidadMedida: selectedValue
  }));
  setUnidadSeleccionada(selectedValue);
  //para manejar varias opciones en el ddl unidades
};
 // este es el primero que funcionaba correctamente pero no seteaba cuando se editaba
  const handleOnchangeTipo = (e) => {
    inputChange(e, setCriterio);
    const selIndex = e.target.selectedIndex;    
    setTipoCrit(e.target.options[selIndex].text);
  }; 
 
  //este setea cuando se edita pero no cuando se crea
  // const handleOnchangeTipo = (e) => {
  //   inputChange(e, setCriterio);
  //   const selIndex = e.target.selectedIndex;    
  //   const selectedTipo = e.target.options[selIndex].text;
  //   setTipoCrit(selectedTipo);
  // };

//   const handleOnchangeTipo = (e) => {
//     inputChange(e, setCriterio); // Asegúrate de que esto esté manejando el cambio correctamente
    
//     // Actualiza el tipoCrit
//     const selIndex = e.target.selectedIndex;   
//     const selectedTipo = e.target.options[selIndex].text;
//     console.log(e.target.options[selIndex].text)
//     setTipoCrit(selectedTipo);
// };
  const getParameters = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}parametros`, headersRequest());
      setDdlParameters(res.data);
    } catch (error) {
      console.error(error);
    }
  };
  const getTipoCriterio = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}tipocriterio`, headersRequest());
      setDdlCriterios(res.data);
    } catch (error) {
      console.error(error);
    }
  };
  const createCriterio = async (data, success) => {
   // console.log(JSON.stringify(data));
    try {
      const res = await axios.post(`${API_ROUTE}criterios`, data, headersRequest());
      addCriterios(res.data);
      //success()
    } catch (error) {
      console.error(error);
    }
  };
  const updateObjetive = async (data, success) => {
    try {
      await axios.put(`${API_ROUTE}criterios/${data?.Id}`, data, headersRequest());
      updateCriterios(data);
      //success()
    } catch (error) {
      console.error(error);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    const text = action === "create" ? "guardados" : "editados";
    const success = (objName) => {
      swal(
        "¡Hecho!",
        `Los datos del criterio ${objName} han sido ${text}.`,
        "success"
      ).then(() => {
        console.log("saved");
      });
    };
    action === "create" ? createCriterio(criterio, success(criterio?.NombreCriterio)) : updateObjetive(criterio, success(criterio?.NombreCriterio), success);
    setCriterio("");
    setTipoCrit(""); 
    handleOpenModal(setOpenModal);
  };
  const handleAddParametro = async () => {
    try {
      await axios.post(`${API_ROUTE}parametros`, { NombreParametro: newParametro }, headersRequest());
      setNewParametro("");
      setShowAddParametroModal(false);
      getParameters(); // Actualiza la lista de parámetros después de añadir uno nuevo
      await Swal.fire({
        title: 'Exito al crear '+newParametro,
        text: 'Se creó correctamente!',
        icon: 'success',
        timer: 1500,
        showConfirmButton:false
      })
      setOpenModal(true);
    } catch (error) {
      console.error(error);
    }
  };
  const handleUpdateParametro = async () => {
    try {
      await axios.put(`${API_ROUTE}parametros/${selectedId}`, 
        { NombreParametro: newParametro }, 
        headersRequest()
      );
      // Limpieza y cierre del modal
      setNewParametro("");
      setShowAddParametroModal(false);
      getParameters(); // Actualiza la lista de parámetros después de la actualización
  
      // Mostrar mensaje de éxito
      await Swal.fire({
        title: 'Éxito al actualizar ' + newParametro,
        text: 'Se actualizó correctamente!',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false
      });
      setOpenModal(true);
    } catch (error) {
      console.error(error);
      // Mostrar mensaje de error
      await Swal.fire({
        title: 'Error!',
        text: 'Hubo un problema al actualizar el parámetro.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
    }
  };
  const handleUpdateCategoria = async () => {
    try {
      await axios.put(`${API_ROUTE}categorias/${categoriaSelected}`, 
        { NombreCategoria: newCategoria }, 
        headersRequest()
      );
      // Limpieza y cierre del modal
      setNewCategoria("");
      setShowAddCategoryModal(false);
      getCategories();
      // Mostrar mensaje de éxito
      await Swal.fire({
        title: 'Éxito al actualizar ' + newCategoria,
        text: 'Se actualizó correctamente!',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false
      });
      setOpenModal(true);
    } catch (error) {
      console.error(error);
      // Mostrar mensaje de error
      await Swal.fire({
        title: 'Error!',
        text: 'Hubo un problema al actualizar la Categoría.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
    }
  };
  const handleUpdateUnidades = async () => {
    try {
      await axios.put(`${API_ROUTE}unidades`, newUnidad, headersRequest());
  
      await Swal.fire({
        title: 'Éxito!',
        text: 'Unidad actualizada correctamente!',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false
      });
      setCategoriaSelected("");
      setNewUnidad("");
      getCategories(); 
      setshowAddUnidadModal(false);
      setOpenModal(true);
    } catch (error) {
      console.error(error);
  
      // Maneja el error
      await Swal.fire({
        title: 'Error!',
        text: 'Hubo un problema al actualizar la unidad.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
    }
  };
  const handleAddUnidades = async () => {
    //console.log(newUnidad)
    try {
      await axios.post(`${API_ROUTE}unidades`, newUnidad, headersRequest());
      setCategoriaSelected("");
      setNewUnidad("");
      setshowAddUnidadModal(false);
      getCategories(); // Actualiza la lista de categorías después de añadir uno nuevo
      await Swal.fire({
        title: 'Éxito al Crear ' + newUnidad.UnidadMedida,
        text: 'Se creó correctamente!',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false
      });
      setOpenModal(true);
    } catch (error) {
      console.error(error);
    }
  };
  const handleAddCategoria = async () => {
    try {
      await axios.post(`${API_ROUTE}categorias`, {NombreCategoria : newCategoria}, headersRequest());
      await Swal.fire({
        title: 'Éxito al Crear ' + newCategoria,
        text: 'Se creó correctamente!',
        icon: 'success',
        timer: 1500,
        showConfirmButton: false
      });
      setNewCategoria("");
      setShowAddCategoryModal(false);
      getCategories(); // Actualiza la lista de categorías después de añadir uno nuevo
      setOpenModal(true);
    } catch (error) {
      console.error(error);
    }
  };
  const validateSubmitUnidades = () => {
    if (newUnidad.UnidadMedida.length < 4){
        swal("¡Ups!", "Por favor, introduzca un nombre de Unidad valido.", "warning");
    } else if (newUnidad.AbreviaturaUnidad.length <2){
      swal("Ups!", "Por favor, introduzca una abreviatura valida.", "warning");
    }else if (newUnidad.IdCategoria.length < 5){
      swal("Ups!", "Por favor, Selecciona una categoría", "warning");
    }else {
      if(modeUnit === 'new'){
        handleAddUnidades ();
      }else{
        handleUpdateUnidades();
      }
    }
  }
  const validateSubmitParametro = () => {
    if (newParametro.length < 4){
        swal("¡Ups!", "Por favor, introduzca un nombre de parámetro valido.", "warning");
    } else {
      if(modeParm === 'new'){
        handleAddParametro ();
      }else{
        handleUpdateParametro();
      }
    }
  }
  const validateSubmitCategoria = () => {
    if (newCategoria.length < 4){
        swal("¡Ups!", "Por favor, introduzca un nombre de Categoría valido.", "warning");
    } else {
        if(modeCategoria === 'new'){
          handleAddCategoria ();
        } else {
          handleUpdateCategoria();
        }
      }
  }
  useEffect(() => {
    getCategories();
    getParameters();
    getTipoCriterio();
  }, []);

  const filtredParameters = ddlParameters.filter(par =>
    par.NombreParametro.toLowerCase().includes(searchText.toLowerCase())
    );
  const findNameParameters = (id) => {
    const selectedParametro = ddlParameters.find(par => par.Id === id);
    return selectedParametro ? selectedParametro.NombreParametro : 'No Encontrado';
  }
  const findNameCategoria = (id) => {
    const nombreCateg = categorias.find(par => par.Id === id);
    return nombreCateg ? nombreCateg.NombreCategoria : 'No Encontrado';
  }
  const findNameUnidad = (id) => {
    const nameUnidad = unidadesMedida.find(uni => uni.Id === id);
    return nameUnidad ? nameUnidad.UnidadMedida : 'No Encontrado';
  }
  const eliminarParametro = async () =>{
    if(findNameParameters(selectedId) ==='No Encontrado'){
      await Swal.fire({
        title: 'Error',
        text: 'Primero debes seleccionar un Parámetro',
        icon: 'error',
        timer: 1500,
        showConfirmButton:false
      })
    } else{
      try {
        const result = await Swal.fire({
          title: "Eliminar: "+findNameParameters(selectedId),
          text: "¿Estás Seguro? Esto no se podrá deshacer",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: '#db526a',
          confirmButtonText: 'Sí, Eliminar!',
          cancelButtonText: 'No, Cancelar',
          reverseButtons: true
        });
    
        if (result.isConfirmed) {
          
          await axios.delete(`${API_ROUTE}parametros/${selectedId}`, headersRequest());
          getParameters();
          // Mostrar mensaje de éxito
          await Swal.fire({
            title: 'Éxito!',
            text: 'Eliminación de Parámetro exitosa!',
            icon: 'success',
            timer: 2000, // Duración del mensaje en milisegundos
            showConfirmButton: false // No mostrar botón de confirmación
          });
          
          setCriterio(prev => prev.IdParametro = '');
          setOpenModal(true);
        } else {
          console.log("Nada para hacer");
        }
      } catch (error) {
        // Mostrar mensaje de error
        await Swal.fire({
          title: 'Error!',
          text: 'Hubo un error al eliminar el parámetro.',
          icon: 'error',
          timer: 2000, // Duración del mensaje en milisegundos
          showConfirmButton: false // No mostrar botón de confirmación
        });
        console.error("Hubo un problema con la solicitud:", error);
      }
    }
  }
  const eliminarCategoria = async () =>{
    if(findNameCategoria(categoriaSelected) ==='No Encontrado'){
      await Swal.fire({
        title: 'Error',
        text: 'Primero debes seleccionar una Categoría',
        icon: 'error',
        timer: 1500,
        showConfirmButton:false
      })
    } else{
      try {
        const result = await Swal.fire({
          title: "Eliminar: "+findNameCategoria(categoriaSelected),
          text: "¿Estás Seguro? Esto no se podrá deshacer",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: '#db526a',
          confirmButtonText: 'Sí, Eliminar!',
          cancelButtonText: 'No, Cancelar',
          reverseButtons: true
        });
    
        if (result.isConfirmed) {
          await axios.delete(`${API_ROUTE}categorias/${categoriaSelected}`, headersRequest());
          
          // Mostrar mensaje de éxito
          await Swal.fire({
            title: 'Éxito!',
            text: 'Eliminación exitosa!',
            icon: 'success',
            timer: 1500, // Duración del mensaje en milisegundos
            showConfirmButton: false // No mostrar botón de confirmación
          });
          getCategories();
          setNewCategoria('');
          setOpenModal(true);
        } else {
          console.log("Nada para hacer");
        }
      } catch (error) {
        // Mostrar mensaje de error
        await Swal.fire({
          title: 'Error!',
          text: 'Hubo un error al eliminar la categoría.',
          icon: 'error',
          timer: 2000, 
          showConfirmButton: false 
        });
        console.error("Hubo un problema con la solicitud:", error);
      }
    }
  }
  const eliminarUnidad = async () => {
    try {
      const unidad = unidadesMedida.find(item => item.Id === unidadSeleccionada);
  
      // Verifica si se encontró la unidad
      if (!unidad) {
        await Swal.fire({
          title: 'Error!',
          text: 'Primero debes seleccionar una Unidad.',
          icon: 'error',
          timer: 1500,
          showConfirmButton: false
        });
        return; 
      }  
      const result = await Swal.fire({
        title: "Eliminar: "+findNameUnidad(unidadSeleccionada),
        text: "¿Estás Seguro? Esto no se podrá deshacer",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#db526a',
        confirmButtonText: 'Sí, Eliminar!',
        cancelButtonText: 'No, Cancelar',
        reverseButtons: true
      });

      // Realiza la solicitud DELETE
      if (result.isConfirmed) {
        await axios.delete(`${API_ROUTE}unidades/${unidad.Id}`, headersRequest());
        // Maneja el éxito
        await Swal.fire({
          title: 'Éxito!',
          text: 'Unidad eliminada correctamente!',
          icon: 'success',
          timer: 1500,
          showConfirmButton: false
        });
        getCategories();
        setCategoriaSelected("");
        setNewUnidad(''); 
        setOpenModal(true); 
      } else { 
        console.log("Nada para hacer");
      }
    } catch (error) {
      console.error(error);
      await Swal.fire({
        title: 'Error!',
        text: 'Hubo un problema al eliminar la unidad.',
        icon: 'error',
        timer: 2000,
        showConfirmButton: false
      });
    }
  };
  
  return (
    <>
      <Modal 
        size="lg" 
        show={openModal && !showAddParametroModal} 
        onHide={() => handleOpenModal(setOpenModal)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header >
          <Modal.Title>{titleModal} Parámetros / Criterios</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label style={{ fontWeight: "700" }}>Parámetro</Form.Label>
              <Row>
                <Col md={8} className="py-2">
                    <Form.Control
                        as="select"
                        name="IdParametro"
                        value={criterio?.IdParametro || ""}
                        autoFocus
                        required
                        onChange={(e) => {
                        inputChange(e, setCriterio);
                        const id = e.target.value;  
                        setSelectedId(id);
                        setNewParametro(findNameParameters(id));
                        }}
                    >   
                        <option value="" selected disabled>Selecciona un parámetro</option>
                        {filtredParameters.length > 0 ? filtredParameters.map(par => (
                        <option key={par.Id} value={par.Id}>{par.NombreParametro}</option>
                        )) : <option value="" disabled>No hay Parámetros disponibles</option>}
                    </Form.Control>
                      <i 
                        className="fa fa-plus text-info" 
                        title="Crear Parámetro" 
                        onClick={() => {
                            setNewParametro("");
                            setModeParm('new');
                            setShowAddParametroModal(true);
                            setOpenModal(false); // oculta modal principal
                        }} 
                        style={{
                            cursor: 'pointer', 
                            paddingTop: '10px', 
                            paddingRight: '5px',  
                            fontWeight: '700',
                            opacity: '0.7'
                        }}
                    ></i>
                      <i 
                        className="fa fa-edit text-warning" 
                        title="Editar Parámetro" 
                        style={{ cursor: 'pointer', paddingRight: '5px', opacity: '0.7' }} 
                        onClick={() => {
                            if (findNameParameters(selectedId) === 'No Encontrado') {
                                Swal.fire({
                                    title: 'Error',
                                    text: 'Primero debes seleccionar un Parámetro',
                                    icon: 'error',
                                    timer: 1500,
                                    showConfirmButton: false
                                });
                                setShowAddParametroModal(false);
                            } else {
                                setNewParametro(findNameParameters(selectedId));
                                setShowAddParametroModal(true);
                                setModeParm('edit');
                                setOpenModal(false); 
                            }
                        }}
                    ></i>
                      <i className="fa fa-trash text-danger"
                        title="Eliminar Parámetro"
                        style={{cursor:'pointer', opacity:'0.7' }}
                        onClick={eliminarParametro}
                      ></i>
                </Col>
                <Col md={4} className="py-2">
                    <InputGroup>
                       {/*  <InputGroup.Prepend>
                            <InputGroup.Text>
                                <i className="dx-icon dx-icon-search"></i>
                            </InputGroup.Text>
                        </InputGroup.Prepend> */}
                        <Form.Control
                        type="text"
                        placeholder="Buscar parámetro..."
                        value={searchText}
                        onChange={(e) =>  setSearchText(e.target.value)}
                        >
                        </Form.Control>
                    </InputGroup>
                </Col>
              </Row>
              <Form.Control.Feedback type="invalid">
                Por favor, selecciona un Parámetro!
              </Form.Control.Feedback>
            </Form.Group>
            <hr className="my-3"/>
            <Row>
                <Col md={8}>
                    <Form.Group className="mb-2" controlId="NombreCriterio">
                        <Form.Label style={{ fontWeight: "700" }}>Nombre Criterio</Form.Label>
                        <Form.Control
                            name="NombreCriterio"
                            type="text"
                            placeholder=""
                            value={criterio?.NombreCriterio || ""}
                            autoFocus
                            required
                            onChange={(e) => {
                            inputChange(e, setCriterio);
                            }}
                        />
                        <Form.Control.Feedback type="invalid">
                            Por favor, introduzca un nombre !
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col md={4} className="pl-md-0">
                    <Form.Label style={{ fontWeight: "700" }}>Tipo de Criterio </Form.Label>
                    <Form.Control
                        as="select"
                        name="IdTipoCriterio"
                        autoFocus
                        required
                        value={criterio?.IdTipoCriterio || ""}
                        onChange={(e) =>{ handleOnchangeTipo(e) }}
                    >
                        <option value="" selected disabled>Selecciona un tipo criterio</option>
                        {ddlCriterios.length > 0 ? ddlCriterios.map(cri => (
                        <option key={cri.Id} value={cri.Id}>{cri.TipoCriterio}</option>
                        )) : <option value="" disabled>No hay tipos criterios disponibles</option>}
                    </Form.Control>
                </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-2" controlId="Descripcion">
                  <Form.Label style={{ fontWeight: "600" }}>Descripción</Form.Label>
                    <Form.Control
                        name="Descripcion"
                        as="textarea"
                        value={criterio?.Descripcion || ""}
                        placeholder=""
                        required
                        autoFocus
                        onChange={(e) => {
                        inputChange(e, setCriterio);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor, introduzca una Descripción !
                    </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-2" controlId="Pregunta">
                  <Form.Label style={{ fontWeight: "600" }}>Pregunta</Form.Label>
                    <Form.Control
                        name="Pregunta"
                        as="textarea"
                        value={criterio?.Pregunta || ""}
                        placeholder=""
                        required
                        autoFocus
                        onChange={(e) => {
                        inputChange(e, setCriterio);
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        Por favor, introduzca una Descripción !
                    </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <hr className="my-3"/>
            {/* Inputs de Cualitativo */}
            {tipoCrit === 'Cualitativo' && (
              <>
                <Row>
                    <Col md={4}>
                        <Form.Group controlId="FormCualitativoAlta">
                            <Form.Label style={{ fontWeight: "700" }}>Cualitativo Alta</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="CualitativoAlta"
                                value={criterio?.CualitativoAlta ||  ""}
                                placeholder="El Impacto es Positivo"
                                required
                                autoFocus
                                onChange={(e) => {
                                inputChange(e, setCriterio);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, introduzca una definición !
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="FormCualitativoMedia">
                            <Form.Label style={{ fontWeight: "700" }}>Cualitativo Media</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="CualitativoMedia"
                                value={criterio?.CualitativoMedia || ""}
                                placeholder="El Impacto es Regular"
                                required
                                onChange={(e) => {
                                inputChange(e, setCriterio);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, introduzca una definición !
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="FormCualitativoBaja">
                            <Form.Label style={{ fontWeight: "700" }}>Cualitativo Baja</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="CualitativoBaja"
                                value={criterio?.CualitativoBaja || ""}
                                placeholder="El Impacto es Negativo"
                                required
                                onChange={(e) => {
                                inputChange(e, setCriterio);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, introduzca una definición !
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
              </>
            )}
            {/* Inputs de Cuantitativo */}
            {tipoCrit === 'Cuantitativo' && (
              <>
                <Row>
                  <Col md={6}>
                    <Form.Group as={Row} controlId="CategoriaSelect" className="d-flex align-items-center">
                      <Col md={12}>
                        <Form.Label>Categoría</Form.Label>
                        <Form.Control
                          as="select"
                          value={categoriaSelected }
                          required
                          onChange={(e) =>{
                            handleCategoriaChange(e)
                          }}
                        >
                        {categorias?.length === 0 ? (
                            <option value="" disabled>No hay categorías disponibles</option>
                          ) : (
                            <>
                              {categorias?.length > 1 && (
                                <option disabled value="">Selecciona una categoría</option>
                              )}
                              {categorias.map((cat) => (
                                <option key={cat.Id} value={cat.Id}>
                                  {cat.NombreCategoria}
                                </option>
                              ))}
                            </>
                          )}
                        </Form.Control>
                        <i 
                            className="fa fa-plus text-info"
                            title="Crear Nueva Categoría"
                            style={{cursor:'pointer', paddingTop:'10px', paddingRight:'5px', opacity:'0.7'}}
                            onClick={() => {
                              setNewCategoria("");
                              setModeCategoria('new');
                              setShowAddCategoryModal(true);
                              setOpenModal(false); 
                          }}
                        ></i>
                          <i 
                            className="fa fa-edit text-warning" 
                            title="Editar Categoría"
                            style={{cursor:'pointer', paddingRight:'5px', opacity:'0.7'}}
                            onClick={() =>{
                              if(findNameCategoria(categoriaSelected) === 'No Encontrado'){
                                Swal.fire({
                                  title: 'Error',
                                  text: 'Primero debes seleccionar una Categoría',
                                  icon: 'error',
                                  timer: 1500,
                                  showConfirmButton:false
                                })
                              setShowAddCategoryModal(false);
                              } else {
                                setNewCategoria(findNameCategoria(categoriaSelected));
                                setShowAddCategoryModal(true);
                                setModeCategoria('edit');
                                setOpenModal(false);
                              }
                            }}
                        ></i>
                          <i className="fa fa-trash text-danger"
                          title="Eliminar Categoría"
                            style={{cursor:'pointer', opacity:'0.7' }}
                            onClick={eliminarCategoria}
                          ></i>
                      </Col>
                      
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group as={Row} controlId="UnidadesMedida" className="d-flex align-items-center">
                      <Col md={12}>
                        <Form.Label>Unidades de Medida</Form.Label>
                        <Form.Control
                          as="select"
                          name="IdUnidadMedida"
                          value={criterio?.IdUnidadMedida || ""}
                          onChange={handleUnidadChange}
                        >
                          {unidadesFiltradas?.length === 0 ? (
                            <option value="" disabled>No hay Unidades de Medida disponibles</option>
                          ) : (
                            <>
                              {unidadesFiltradas?.length > 1 && (
                                <option disabled value="">Selecciona una Unidad de Medida</option>
                              )}
                              {unidadesFiltradas.map((unidad) => (
                                <option key={unidad.Id} value={unidad.Id}>
                                  {unidad.UnidadMedida}
                                </option>
                              ))}
                            </>
                          )}
                        </Form.Control>
                        <i 
                        className="fa fa-plus text-info" 
                            title="Crear Nueva Unidad de Medida"
                            onClick={() => {
                              setNewUnidad("");
                              setModeUnit('new');
                              setshowAddUnidadModal(true);
                              setOpenModal(false); 
                          }}
                            style={{
                                cursor:'pointer', 
                                paddingTop:'10px', 
                                paddingRight:'5px',  
                                fontWeight:'700',
                                opacity:'0.7'
                              }}
                          ></i>
                          <i 
                        className="fa fa-edit text-warning" 
                            title="Editar Unidad de Medida"
                            onClick={()=>{
                              setNewUnidad("");
                              setModeUnit('edit');
                              setshowAddUnidadModal(true);
                              setOpenModal(false); 
                              //
                              if(findNameUnidad(unidadSeleccionada) === 'No Encontrado'){
                                Swal.fire({
                                  title: 'Error',
                                  text: 'Primero debes seleccionar una Unidad',
                                  icon: 'error',
                                  timer: 1500,
                                  showConfirmButton:false
                                })
                              setshowAddUnidadModal(false);
                              setOpenModal(true);
                              } else {
                                setNewUnidad(
                                  unidadesMedida.find(uni => (
                                    uni.Id === unidadSeleccionada
                                  ))
                                );
                                setshowAddUnidadModal(true);
                                setModeUnit('edit');
                                setOpenModal(false);
                              }
                            
                            }}
                            style={{cursor:'pointer', paddingRight:'5px', opacity:'0.7'}}
                          ></i>
                          <i className="fa fa-trash text-danger"
                            title="Eliminar Unidad de Medida"
                            style={{cursor:'pointer', opacity:'0.7'}}
                            onClick={()=>{
                              setNewUnidad("");
                              eliminarUnidad();
                            }}
                          ></i>
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <hr className="my-3"/>
                <Row>
                    <Col md={4}>
                        <Form.Group controlId="FormCuantitativoAlta">
                            <Form.Label>Cuantitativo Alta</Form.Label>
                            <Form.Control
                                type="number"
                                name="CuantitativoAlta"
                                value={criterio?.CuantitativoAlta || ""}
                                min={0}
                                placeholder="El Impacto es Positivo"
                                required
                                autoFocus
                                onChange={(e) => {
                                inputChange(e, setCriterio);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, introduzca una definición !
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="FormCuantitativoMedia">
                            <Form.Label>Cuantitativo Media</Form.Label>
                            <Form.Control
                                type="number"
                                name="CuantitativoMedia"
                                value={criterio?.CuantitativoMedia || ""}
                                min={0}
                                placeholder="El Impacto es Regular"
                                required
                                onChange={(e) => {
                                inputChange(e, setCriterio);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, introduzca una definición !
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Group controlId="FormCuantitativoBaja">
                            <Form.Label>Cuantitativo Baja</Form.Label>
                            <Form.Control
                                type="number"
                                name="CuantitativoBaja"
                                value={criterio?.CuantitativoBaja || ""}
                                min={0}
                                placeholder="El Impacto es Negativo"
                                required
                                onChange={(e) => {
                                inputChange(e, setCriterio);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Por favor, introduzca una definición !
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
              </>
            )}
            <Modal.Footer>
              <Button
                variant="dark"
                onClick={() => {
                  handleOpenModal(setOpenModal);
                  setCriterio("");
                }}
              >
                Cancelar
              </Button>
              <Button type="submit" variant="success" >
                Guardar
              </Button>
            </Modal.Footer>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal para añadir nuevo parámetro */}
      <Modal 
        show={showAddParametroModal} 
        onHide={() => setShowAddParametroModal(false)}
        backdrop="static"
        keyboard={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>{modeParm === 'new' ? 'Crear Parámetro' : 'Editar Parámetro'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="parametroControl">
              <Form.Label>Nombre del Parámetro</Form.Label>
              <Form.Control
                type="text"
                name="NombreParametro"
                value={newParametro}
                required
                autoFocus
                minLength={4}
                onChange={(e) => setNewParametro(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={() => {
            setShowAddParametroModal(false);
            setOpenModal(true);
            setTipoCrit(""); 
            setCriterio("");
          }}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={validateSubmitParametro}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal 
        show={showAddCategoryModal} 
        onHide={() => setShowAddCategoryModal(false)}
        backdrop="static"
        keyboard={false}
        >
        <Modal.Header closeButton>
          <Modal.Title>{modeCategoria === 'new' ? 'Crear Categoría' : 'Editar Categoría'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="categoria1">
              <Form.Label>Nombre Categoría</Form.Label>
              <Form.Control
                type="text"
                name="Categoria"
                value={newCategoria}
                required
                autoFocus
                minLength={4}
                onChange={(e) =>  setNewCategoria(e.target.value) }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={() => {
            setShowAddCategoryModal(false);
            setOpenModal(true);
          }}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={validateSubmitCategoria}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>   
      <Modal 
        show={showAddUnidadModal} 
        onHide={() => setshowAddUnidadModal(false)}
        backdrop="static"
        keyboard={false}
        >
        <Modal.Header closeButton>
        <Modal.Title>{modeUnit === 'new' ? 'Crear Unidad' : 'Editar Unidad'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="UnidadControl">
              <Form.Label>Nombre Unidad de Medida</Form.Label>
              <Form.Control
                type="text"
                name="UnidadMedida"
                value={newUnidad?.UnidadMedida || ""}
                required
                autoFocus
                minLength={4}
                onChange={(e) => {
                  inputChange(e, setNewUnidad);
                }}
              />
            </Form.Group>
            <Form.Group controlId="UnidadControl2">
              <Form.Label>Abreviatura de la Unidad</Form.Label>
              <Form.Control
                type="text"
                name="AbreviaturaUnidad"
                value={newUnidad?.AbreviaturaUnidad || ""}
                required
                autoFocus
                minLength={4}
                onChange={(e) => {
                  inputChange(e, setNewUnidad);
                }}
              />
            </Form.Group>
            <Form.Group controlId="CategoriaSelect2">
              <Form.Label>Asociar a una Categoría</Form.Label>
              <Form.Control
                as="select"
                name="IdCategoria"
                value={newUnidad?.IdCategoria || ""}
                required
                onChange={(e) => {
                  inputChange(e, setNewUnidad);
                }}
              >
                <option selected disabled value="">Selecciona una categoría</option>
                {categorias.length > 0 ? categorias.map(cat =>(
                  <option key={cat.Id} value={cat.Id}>
                    {cat.NombreCategoria}
                  </option>
                )) : <option value="" disabled>No hay Categorías disponibles</option>
              }
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="dark" onClick={() => {
            setshowAddUnidadModal(false);
            setOpenModal(true);
          }}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={validateSubmitUnidades}>
            Guardar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default ModalRegCriterios;