import React, { useState } from "react";
import { Table, ProgressBar, Card } from "react-bootstrap";
import SuperTableProductivity from "../../SuperTableProdutivity";
import { useTranslation } from "react-i18next";

const tasks = [
  {
    name: "Automatización backlog de hackathon - 2023 Adición",
    progress: 15,
    start: "15 Sept",
    end: "15 Dic",
  },
  {
    name: "Gestión de Capacidades",
    progress: 20,
    start: "01 May",
    end: "23 Dic",
  },
  {
    name: "Pg. Gestión Capacidades - 2024 Adición",
    progress: 30,
    start: "01 Sept",
    end: "31 Dic",
  },
  { name: "BAU Seguridad", progress: 2.5, start: "01 Sept", end: "31 Dic" },
  { name: "MDR - Ciso", progress: 2.5, start: "01 Sept", end: "31 Dic" },
];

const TaskList = ({ dataFilter, user }) => {
  const [t, i18n] = useTranslation("productivity");
  const [compStartDate, setCompStartDate] = useState(null);
  const [compEndDate, setCompEndDate] = useState(null);

  const columns = [
    {
      selector: "Propietario",
      caption: t("productivity_table_manager"),
      customRender: (row) => {
        return <span>{row.data.Propietario}</span>;
      },
    },
    {
      selector: "Actividad",
      caption: t("productivity_table_activity"),
      customRender: (row) => {
        return <span className="text-danger">{row.data.NombreActividad}</span>;
      },
    },
    {
      selector: "vFechaInicio",
      caption: t("productivity_table_start"),
      customRender: (row) => {
        return (
          <>
            <span>{row.data.vFechaInicio}</span>
          </>
        );
      },
      align: "center",
      width: 100,
    },
    {
      selector: "vFechaFin",
      caption: t("productivity_table_end"),
      customRender: (row) => {
        return (
          <>
            <span>{row.data.vFechaFin}</span>
          </>
        );
      },
      align: "center",
      width: 100,
    },
    {
      selector: "CantidadHorasPlaneadas",
      caption: t("productivity_table_planhours"),
      customRender: (row) => {
        return <span>{row.data.CantidadHorasPlaneadas} Hrs.</span>;
      },
      width: 100,
    },
    {
      caption: t("productivity_table_executehours"),
      customRender: (row) => {
        return <span>{row.data.CantHorasEjecutadas} Hrs.</span>;
      },
      width: 100,
      align: "right",
    },
    {
      selector: "Avance",
      caption: t("productivity_table_avanced"),
      customRender: (row) => {
        return (
          <>
            {row.data.Avance === "Done" && (
              <>
                <span className="mr-2">
                  <i className="fa fa-circle text-success"></i>{" "}
                  {row.data.Avance}
                </span>
              </>
            )}
            {row.data.Avance === "Doing" && (
              <>
                <span className="mr-2">
                  <i className="fa fa-circle text-info"></i> {row.data.Avance}
                </span>
              </>
            )}
            {row.data.Avance === "To Do" && (
              <>
                <span className="mr-2">
                  <i className="fa fa-circle text-light"></i> {row.data.Avance}
                </span>
              </>
            )}
            {row.data.Avance !== "To Do" &&
              row.data.Avance !== "Doing" &&
              row.data.Avance !== "Done" && <span>{row.data.Avance}</span>}
          </>
        );
      },
      align: "center",
      width: 100,
    },
    {
      selector: "FechaModificado",
      caption: t("productivity_table_lastmod"),
      customRender: (row) => {
        return <span> {row.data.FechaModificado}</span>;
      },
      width: 120,
    },
  ];

  const projectsHeader = (data) => {
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    const totalHrsPlanByProject = (idUser, idProject) => {
      let projectByuser = dataFilter.filter(
        (proje) => proje.IdUsuario === idUser
      );
      return projectByuser
        .filter((fl) => fl.IdProyecto === idProject)
        .map((item) => item.CantidadHorasPlaneadas)
        .reduce(reducer);
    };
    const totalHrsEjecbyProject = (idUser, idProject) => {
      let projectByuser = dataFilter.filter(
        (proje) => proje.IdUsuario === idUser
      );
      return projectByuser
        .filter((fl) => fl.IdProyecto === idProject)
        .map((item) => item.CantHorasEjecutadas)
        .reduce(reducer);
    };
    const pctEjecByProject = (idUser, idProject) => {
      let plan = totalHrsPlanByProject(idUser, idProject);
      let ejec = totalHrsEjecbyProject(idUser, idProject);
      if (plan != 0) {
        return Math.round((ejec * 100) / plan);
      } else {
        return 0;
      }
    };

    return data.map((activity) => {
      return {
        ...activity,
        Proyecto: `${activity.Proyecto} ( ${t(
          "productivity_table_planhours"
        )}: ${totalHrsPlanByProject(
          activity.IdUsuario,
          activity.IdProyecto
        )} | ${t("productivity_table_executehours")}:${totalHrsEjecbyProject(
          activity.IdUsuario,
          activity.IdProyecto
        )} | % ${t("productivity_table_avanced")}: ${pctEjecByProject(
          activity.IdUsuario,
          activity.IdProyecto
        )} )`,
      };
    });
  };

  const renderDataProjects = (itemId) => {
    if (dataFilter.length > 0) {
      let personalProjects = [];
      personalProjects = projectsHeader(
        dataFilter?.filter((proje) => proje.IdUsuario === itemId)
      );
      return personalProjects;
    }
  };


  const reducer = (accumulator, currentValue) => accumulator + currentValue;
  const totalHrsPlan = (id) => {
    let filterMainData = renderDataProjects(id);
    let hrsPlan =
      filterMainData?.length > 0
        ? filterMainData
            .map((item) => item.CantidadHorasPlaneadas)
            .reduce(reducer)
        : 0;
    return Math.round(hrsPlan + Number.EPSILON);
  };
  const totalHrsEjec = (id) => {
    let filterMainData = renderDataProjects(id);
    let hrsEjec =
      filterMainData?.length > 0
        ? filterMainData.map((item) => item.CantHorasEjecutadas).reduce(reducer)
        : 0;
    return Math.round(hrsEjec + Number.EPSILON);
  };
  const pctEjec = (id) => {
    let plan = totalHrsPlan(id);
    let ejec = totalHrsEjec(id);
    if (plan != 0) {
      return Math.round((ejec * 100) / plan);
    } else {
      return 0;
    }
  };

  return (
    <>
      <div className="d-flex mb-2 mt-4">
        <Card className="mr-3">
          <Card.Body className="px-2 py-1 d-flex">
            <div className="d-flex flex-column  border-right pr-2 mr-2">
              <span className="small mr-2">
                {t("productivity_resources_plannedhours")}
              </span>
              <span className="text-info font-weight-bold">
                {totalHrsPlan(user.IdUsuario)}
              </span>
            </div>
            <div className="d-flex flex-column ">
              <span className="small mr-2">
                {t("productivity_resources_hours_hoursworked")}
              </span>
              <span className="text-success font-weight-bold">
                {totalHrsEjec(user.IdUsuario)}
              </span>
            </div>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body className="px-2 py-1 d-flex">
            <div className="d-flex flex-column" style={{ width: "140px" }}>
              <span className="small mr-2 mb-2">
                <i className="fa fa-briefcase mr-2"></i>
                {t("productivity_resources_performance")} (
                {pctEjec(user.IdUsuario)}
                %)
              </span>
              <ProgressBar
                variant="success"
                now={pctEjec(user.IdUsuario)}
                style={{ height: "8px" }}
              />
            </div>
          </Card.Body>
        </Card>
      </div>
      <SuperTableProductivity
        /*  title_proje = {t("productivity_table_project")} */
        title_proje="Proyecto"
        showProjectsProductivity
        columns={columns}
        data={renderDataProjects(user?.IdUsuario)}
        noSearch={false}
        textSearch={t("productivity_table_search")}
      />
    </>
  );
};

export default TaskList;
