import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import ReactDOM from "react-dom";
//import $ from 'jquery'
import axios from 'axios';
import 'dhtmlx-gantt/codebase/dhtmlxgantt.css';
import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_new_material.css'
import { Gantt} from 'dhtmlx-gantt';
import { API_ROUTE, headersRequest } from '../../helpers';
import { 
  setLightBoxSections, 
  createTask, 
  updateTask, 
  deleteTask,
  setScaleConfig,
  getResourceNames,
  getResourceNameById,
  setTask,
  configLayout,
} from './helpers_MGI';
import DatePickerBlock from './DatePickerBlock';
import { Background } from 'devextreme-react/range-selector';
//const GanttStructuring = (props) => {
const GanttStructuring = (props) => {
    const ganttContainer = useRef(null);
    const [resources, setResources] = useState([]);
    const [selectedResources, setSelectedResources] = useState([]);
    const [ganttInstance, setGanttInstance] = useState([]);
    const [scale, setScale] = useState("week");
    const [zoomLevel, setZoomLevel] = useState("week");
    
    useEffect(() => {
        let gantt = Gantt.getGanttInstance();
       setGanttInstance(gantt);

       gantt.config.layout = configLayout;
       gantt.config.readonly = props.readOnly? true : false

        // defino las columnas
        gantt.config.columns = [
            { name: "add", align: "center" },
            //{name: 'text', label:'Actividad', min_width:200, resize:true, tree:true},
            {
              name: "text",
              label: 'Actividad',
              tree: true,
              min_width: 250,
              width: "*",
              resize: true,
            },
            {name: 'start_date', label: 'Fecha Inicio', min_width: 100,width: "*", resize:true,align:'center'},
            {name: 'end_date', label: 'Fecha Final', min_width: 100,width: "*", resize:true, align:'center'},
            {name: 'duration', label: 'Duracion',resize:true, align:'center'},
            {name: 'cost', label: 'Costo $', resize:true,align:'center'},
            // {name: 'resources', label : 'Recursos', align:'center'},
        ];

        //datapicker
        gantt.form_blocks["datepicker"] = {
            render: function (sns) {
              return "<div id='datepicker_block'></div>";
            },
            set_value: function (node, value, task, section) {
         
              ReactDOM.render(
                <DatePickerBlock
                  task={task}
                  ganttMet={gantt}
                  // excluded = {excludedDates.current}
                  excluded={[]}
                />,
                document.getElementById("datepicker_block")
              );
            },
            get_value: function (node, task, section) {
              console.log(task)
              task.duration = gantt.calculateDuration( task.start_date, task.end_date)
              // La duración se calcula automáticamente al actualizar las fechas
            },
            focus: function (node) {},
          };

        // tengo que ver a cuales tareas o actividades apuntar
        //cargar datos iniciales de las tareas 
        axios.get(`${API_ROUTE}initiative_tasks/${props.idInitiative}`, headersRequest()).then(response =>{
          let processed = response.data.task.task.map((task) => {
            return {
              ...task,
              start: task.start_date,
              end: task.end_date,
              parentId: !task.parent ? 0 : task.parent,
              cost: task.cost ? task.cost : 0,
              resources: response.data.task.hours.filter((resourhours) => {
                return resourhours.IdActividad == task.id;
              }),
            };
          });
          setTask(processed, gantt);
          gantt.serverList("asigHours", response.data.task.hours);
        })

        gantt.templates.rightside_text = function (start, end, task) {
          /* console.log(task.owner_id, "text de recurso") */
          return getResourceNames(gantt.serverList("resourceList"), task.owner_id);
        };

        gantt.config.resource_property = "owner_id";

        // Cargar recursos
        axios.get(`${API_ROUTE}users`, headersRequest()).then(response => {
            const resourceOptions = response.data.map(user => ({
                key: user.IdUsuario,
                label: `${user.Nombre}`
            }));

            gantt.serverList("resourceList", resourceOptions);
            setLightBoxSections(gantt);
            setResources(resourceOptions);
        });

        setScaleConfig(gantt, scale);

        //configurando el dataProcessor
        var dp = gantt.createDataProcessor(async function (entity, action, data, id){
          
          let taskInfohour = gantt.serverList("asigHours");
          let oneTaskInfoHour = taskInfohour?.filter((it) => {
            return it.IdActividad == data.id;
          });
          let ownerHourTask = data?.owner_id?.map((it) => {
            return {
              resource_id: it.resource_id,
              value: it.value,
              assignment_id: oneTaskInfoHour
                ?.filter((itx) => {
                  return itx.IdRecurso == it.resource_id;
                })
                .map((itx3) => {
                  return itx3.IdAsignacionHoras;
                })[0],
            };
          });

            data.idInitiative = props.idInitiative;
            let result;
            switch (action){
              case "create":
                result = await createTask(data);
                //console.log(result.TaskUID);
                gantt.changeTaskId(data.id, result.TaskUID); // cambio el id del gantt por el de la BD
                gantt.refreshData();
                break;
              case "update":
                result = await updateTask(id, data);
                gantt.refreshData();
                break;
              case "delete":
                await deleteTask(id);
                gantt.refreshData();
                break;
              default:
                break;
            }
            return true; // le dice al gantt que todo salio monocuco...
        });
        
        dp.init(gantt);
        gantt.init(ganttContainer.current);
        gantt.render();
        return () =>{
            gantt.clearAll();
        };
    },[scale, zoomLevel]);

    return (
        <div>
          <div className='d-flex justify-content-start align-items-center'>
              <select className='select-scale' value={scale} onChange={(e)=> {setScale(e.target.value)}}
                style={{
                  border: '1px solid #ddd',
                  borderRadius: '4px',
                  padding: '10px',
                  marginBottom:'10px',
                  fontSize: '16px',
                  fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif",
                  color: '#333',
                  backgroundColor: '#f8f9fa',
                  appearance: 'none',
                  outline: 'none',
                  transition: 'all 0.3s ease',
                  width: '200px', // Ajusta el ancho según sea necesario
                }}
                onFocus={(e) => {
                  e.target.style.borderColor = '#007bff';
                  e.target.style.boxShadow = '0 0 0 0.2rem rgba(38, 143, 255, 0.25)';
                }}
                onBlur={(e) => {
                  e.target.style.borderColor = '#ddd';
                  e.target.style.boxShadow = 'none';
                }}
                >
                <option value="day">Día</option>
                <option value="week">Semana</option>
                <option value="month">Mes</option>
                <option value="year">Año</option>
              </select>
          </div>
            
          <div ref={ganttContainer} style={{width: '100%', height: '500px'}} />
        </div>
    );
};
export default GanttStructuring;