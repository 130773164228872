import { Card, Col, Container, Row, Table } from "react-bootstrap";
import Layout from "../../Layout";
import TitleCommon from "../TitleCommon";
import { Link, useHistory,useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_ROUTE, headersRequest } from "../../helpers";
import { formatDateToInput } from "./helpers_MGI";
import InitiativesProgress from "./InitiativesProgress";
import SuperTableV2 from "../SuperTableV2";
import InitiativesMap from "./InitiativesMap";

const InitiativesBudge = () => {
    const [dataBudge, setDataBudge] = useState([]);
    const [totalPresupuesto, setTotalPresupuesto] = useState(0);
    const [presupuestoPorEstado, setPresupuestoPorEstado] = useState({});
    const [initiatives, setInitiatives] = useState([]);
    const [filteredIntiatives, setFilteredInitiatives] = useState([]);
    const [selectedState, setSelectedState] = useState("Todas");
    
      const getAllInitiatives = async () => {
        try {
          const res = await axios.get(`${API_ROUTE}listariniciativas`, headersRequest());
          const formatDates = res.data.map(initiative => ({
            ...initiative,
            FechaIdentificacion : formatDateToInput(initiative.FechaIdentificacion),
            FechaCreacion : formatDateToInput(initiative.FechaCreacion),
          }));
          setInitiatives(formatDates);
        //   const filteredInitiatives = formatDates.filter(d => idsToFilter.includes(d.IdEstado));
        //   setInitiatives(filteredInitiatives);
        } catch (error) {
          console.error(error);
        }
      };

    const handleFilterByState = (estado = null) =>{
        console.log(estado);
        if(estado === null){
            setFilteredInitiatives(initiatives);
            setSelectedState("Todas");
        } else {
            const filtered = initiatives?.filter(ini => ini.Estado === estado);
            setFilteredInitiatives(filtered);
            setSelectedState(estado);
        }
    };

    const getDataBudge = async () =>{
        try {
            const res = await axios.get(`${API_ROUTE}initiativesbudge`, headersRequest());
            const data = res.data;
            setDataBudge(data);

            // Calcular total de presupuesto
            const total = data.reduce((acc, item) => acc + parseFloat(item.Presupuesto), 0);
            setTotalPresupuesto(total);

            // Calcular presupuesto por estado
            const presupuestoPorEstadoTemp = {};
            data.forEach(item => {
                const estado = item.Estado;
                const presupuesto = parseFloat(item.Presupuesto);
                if (presupuestoPorEstadoTemp[estado]) {
                    presupuestoPorEstadoTemp[estado] += presupuesto;
                } else {
                    presupuestoPorEstadoTemp[estado] = presupuesto;
                }
            });
            setPresupuestoPorEstado(presupuestoPorEstadoTemp);
        } catch (error) {
            console.error(error);
        }
    };

    const getClassByState = (estado) => {
        switch (estado.Estado) {
            case 'En Inscripción':
                return 'card-mgi-register';
            case 'En Calificación':
                return 'card-mgi-rating';
            case 'En Evaluación':
                return 'card-mgi-evaluation';
            case 'Aprobada':
                return 'card-mgi-approve'; 
            case 'En Estructuración':
                return 'card-mgi-structuring';    
            default:
                return '';
        }
    };

    const renderInitiatives = (row) =>{
        return(
            <span
              className="ml-2"
              style={{ maxWidth: "340px" }}
              title={row.data.NombreIniciativa}
            >
              <Link
                to={`/InitiativesDetails/${row.data.Id}`}
              >
                {row.data.NombreIniciativa}
              </Link>
            </span>
        );
    };

    const columns = [
        {
          caption: "Nombre",
          width: 250,
          customRender: renderInitiatives
        },
        {
          caption: "Descripción",
          selector: "Descripcion",
          width: 250,
        },
        {
          caption: "Fecha de Creación",
          selector: "FechaCreacion",
          width: 110,
        },
        {
          caption: "Responsable",
          selector: "CreadoPor",
          width: 110,
        },
        {
          caption: "Estado",
          selector: "Estado",
          width: 110,
        },
    ];

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
    };

    const contarEstados = (dataBudge) => {
        const contador = {};
    
        dataBudge.forEach(item => {
            const estado = item.Estado;
            if (contador[estado]) {
                contador[estado]++;
            } else {
                contador[estado] = 1;
            }
        });
    
        return contador;
    };
    const estadoCounts = contarEstados(dataBudge);
    const Count = initiatives?.length;  

    useEffect(() =>{
        setFilteredInitiatives(initiatives);
    },[initiatives]);

    useEffect(()=>{
        getDataBudge();
        getAllInitiatives();
    },[]);
    
    return (
        <>
            <Layout>
                <TitleCommon
                    title={{
                        text: (
                            <>
                                <i className="fas fa-coins" style={{ marginRight: '8px' }}></i>
                                Iniciativas | Gestión Presupuestal
                            </>
                        ),
                    }}
                    color={"secondary"}
                    goBack={{
                        text: "Volver",
                        icon: "arrow-left",
                      }}
                />
                <Container fluid style={{maxWidth:'1440px'}}>
                    <Card className="bg-light mt-4">
                        <Card.Body className="px-3 py-3">
                            <h4>
                                Gestión Presupuestal
                            </h4>
                            <Row className="d-flex align-items-center">
                                <Col lg={4} md={6} sm={12}>
                                    <Card 
                                        className="my-3 border-primary card-dashboard-mgi card-mgi-initiatives"
                                        style={{cursor:'default'}}        
                                    >
                                        <Card.Body>
                                            <div className="d-flex justify-content-center align-items-center ">
                                                <i className="fas fa-sack-dollar dashboard-icons mr-2"></i>
                                                <span className="h5 mb-0 text-center">Total Iniciativas <br/>
                                                    <small>
                                                    con presupuesto {dataBudge?.length} de {Count}    
                                                    </small>    
                                                </span>
                                            </div>
                                            {
                                                dataBudge?.length > 0 && (
                                                    <h2 className="my-2 text-center">
                                                       $ {formatCurrency(totalPresupuesto)} {dataBudge[0]?.Moneda}
                                                    </h2>
                                                )
                                            }
                                        </Card.Body>
                                    </Card>
                                </Col>
                                {Object.entries(presupuestoPorEstado).map(([estado, total]) => (
                                    <Col key={estado} lg={4} md={6} sm={12}>
                                        <Card 
                                            className={`my-3 border-primary card-dashboard-mgi ${getClassByState({ Estado: estado })}`}
                                            style={{cursor:'default'}}    
                                        >
                                            <Card.Body>
                                                <div className="d-flex justify-content-center align-items-center ">
                                                    <i className="fa fa-star dashboard-icons mr-2"></i>
                                                    <span className="h5 mb-0">Iniciativas {estado} : {estadoCounts[estado] || 0} </span>
                                                </div>
                                                <h2 className="my-0 text-center">
                                                    $ {formatCurrency(total)} {dataBudge[0]?.Moneda}
                                                </h2>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="bg-light mt-4">
                        <Card.Body className="px-3 py-3">
                            <h4>
                                Iniciativas Por Estado
                            </h4>
                        </Card.Body>
                        <Row className="d-flex px-3 py-3">
                            <InitiativesProgress
                                iniciativas={initiatives}
                                onFilter={handleFilterByState}
                            />
                        </Row>
                    </Card>
                    <Card className="bg-light mt-4">
                        <Card.Body className="px-3 py-3">
                            <h4>
                                Listado de Iniciativas Por Estado : {selectedState}
                            </h4>
                        </Card.Body>
                        <Card.Footer className="d-flex align-items-center">
                        {filteredIntiatives?.length > 0 ? (
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <SuperTableV2
                                    data={filteredIntiatives}
                                    columns={columns}
                                />
                            </Col>
                        </Row>
                        ):(
                        <Row>
                            <Col lg={12} md={12} sm={12}>
                                <SuperTableV2
                                    data={null}
                                    columns={columns}
                                />
                            </Col>
                        </Row>
                        )}
                        </Card.Footer>
                    </Card>
                    <Card className="bg-light my-4">
                        <Card.Body className="px-3 py-3">
                            <h4>
                                Ubicación Iniciativas | Proyectos
                            </h4>
                            <Row className="d-flex justify-content-center align-items-center my-3">
                                <InitiativesMap />
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>
            </Layout>
        </>
    );

}
export default InitiativesBudge