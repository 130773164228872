import axios from "axios";
import { API_ROUTE, headersRequest } from "../../helpers";

export const PerformanceServices = {
  getUserByOrganization: async () => {
    try {
      const result = await axios.get(`${API_ROUTE}users`, headersRequest());
      if (result.status == 200) {
        return result.data;
      }
    } catch (error) {
      throw new Error("Error al cargar los datos");
    }
  },

  getDataByFilter: async (data) => {
    try {
      const result = await axios.get(
        `${API_ROUTE}productivity-panel-filter?Rol=${data.Idrol}&Position=${data.position}&Name=${data.IdUser}&StartDate=${data.start_date}&EndDate=${data.end_date}`,
        headersRequest()
      );
      if (result.status == 200) {
        return result.data;
      }
    } catch (error) {
      throw new Error("Error al cargar los datos");
    }
  },
  getTasksByResource: async () => {
    try {
      const result = await axios.get(
        `${API_ROUTE}dashboard-resources-activities`,
        headersRequest()
      );
      if (result.status == 200) {
        return result.data;
      }
    } catch (error) {
      throw new Error("Error al cargar los datos");
    }
  },
};
