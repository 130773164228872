import React, { useState, useEffect, Suspense, lazy } from "react";
import { Tab, Row, Col, Nav, Container } from "react-bootstrap";
import TitleCommon from "../components/TitleCommon";
import Layout from "../Layout";
import { StyledTabs } from "../components/StyledCommon";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import Structuring from '../components/Initiatives/Structuring';
import Strategy from "../components/Initiatives/Strategy";
import Dashboard from '../components/Initiatives/Dashboard';
import axios from "axios";
import { API_ROUTE, headersRequest } from "../helpers";
import Rating from "../components/Initiatives/Rating";
import Assessment from "../components/Initiatives/Assessment";
import WaitForApproval from "../components/Initiatives/WaitingForApproval";

const Registration = lazy(() => import('../components/Initiatives/InitiativeRegistration'));
const StrategicObjetives = lazy(() => import('../components/Initiatives/StrategicObjetives'));

const Initiatives = () => {
  const [t, i18n] = useTranslation("initiatives");
  const [selectedTab, setSelectedTab] = useState("zero");
  const [save, setSave] = useState([]);
  const [initiatives, setInitiatives] = useState([])
  const [update, setUpdate] = useState(true);
  const { idView } = useParams();

  const getAllInitiatives = async () => {
    try {
      const res = await axios.get(`${API_ROUTE}listariniciativas`, headersRequest());
      setInitiatives(res.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  });

  let title_i18 = t("iniciativas_title");

  const savePage = (value) => {
    localStorage.setItem('savePage', value);
  };

  const currentPage = localStorage.getItem("savePage") || "zero";

  console.log(idView)
  console.log(currentPage)
 useEffect(()=>{
  getAllInitiatives()
 },[])
  return (
    <React.Fragment>
      <Layout save={save}>
        <TitleCommon
          title={{
            text: title_i18,
            icon: "lightbulb"
          }}
        />
        <Container fluid>
          <Tab.Container
            id="left-tabs-example"
             defaultActiveKey= {(idView) ? idView : currentPage}
            //  defaultActiveKey="zero"
            activeKey={selectedTab}
            transition={false}
            onSelect={(e) => setSelectedTab(e)}
          >
            <Row className="bg-light">
              <Col>
                <StyledTabs as={Nav}>
                <Nav.Item data-tip={(t("iniciativas_lenguaje") === "es") ? "Acá encontrarás toda la info de las Iniciativas" : "Here you will find all the info about the Initiatives"}>
                    <Nav.Link eventKey="zero" onClick={() => savePage("zero")}>
                      {t("iniciativas_button_dashboard")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item data-tip={(t("iniciativas_lenguaje") === "es") ? "Acá puedes registrar tus Objetivos Estratégicos" : "Here you can register your Strategic Objectives"}>
                    <Nav.Link eventKey="one" onClick={() => savePage("one")}>
                      {t("iniciativas_button_objetivos")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item data-tip={(t("iniciativas_lenguaje") === "es") ? "Puedes Registradas y visualizar las Iniciativas que ya fueron inscritas" : "You can register and view the Initiatives that have already been submitted"}>
                    <Nav.Link eventKey="two" onClick={() => savePage("two")}>
                      {t("iniciativas_button_inscripcion")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item data-tip={(t("iniciativas_lenguaje") === "es") ? "Estructura mejor tu iniciativa con diferentes drivers del negocio" : "Better structure your initiative with different business drivers"}>
                    <Nav.Link eventKey="three" onClick={() => savePage("three")}>
                      {t("iniciativas_button_estructuracion")}
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item data-tip={(t("iniciativas_lenguaje") === "es") ? "Calificación de Iniciativas puede hacerse de forma Auto/Manual, y visualizar las que ya fueron calificadas" : "Initiative rating can be done in Auto/Manual mode, and you can view those that have already been rated"}>
                    <Nav.Link eventKey="four" onClick={() => savePage("four")}>
                      {t("iniciativas_button_calificacion")}
                    </Nav.Link>
                  </Nav.Item>
                 <Nav.Item data-tip={(t("iniciativas_lenguaje") === "es") ? "Estás Iniciativas ya fueron calificadas, ahora están esperando por Aprobación" : "These Initiatives have already been rated and are now awaiting Approval"}>
                    <Nav.Link eventKey="five" onClick={() => savePage("five")}>
                      {t("iniciativas_button_wait_approval")}
                    </Nav.Link>
                  </Nav.Item>
                </StyledTabs>
              </Col>
            </Row>
            <Row>
              <Col className="pt-3 pb-5 mb-5">
                <Tab.Content>
                <Tab.Pane
                    eventKey="zero"
                    className="animate__animated animate__fadeIn"
                  >
                    <Dashboard />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="one"
                    className="animate__animated animate__fadeIn"
                  >
                    <Strategy />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="two"
                    className="animate__animated animate__fadeIn"
                  >
                    <Suspense fallback={<div>Loading...</div>}>
                      <Registration />
                    </Suspense>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="three"
                    className="animate__animated animate__fadeIn"
                  >
                    <Suspense fallback={<div>Loading...</div>}>
                      <Structuring initiatives={initiatives} />
                    </Suspense>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="four"
                    className="animate__animated animate__fadeIn"
                  >
                    <Suspense fallback={<div>Loading...</div>}>
                      <Rating 
                      update={update} 
                      setUpdate={setUpdate}
                      
                      />
                    </Suspense>
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="five"
                    className="animate__animated animate__fadeIn"
                  >
                    <Suspense fallback={<div>Loading...</div>}>
                      <WaitForApproval
                      update={update} 
                      setUpdate={setUpdate}
                      />
                     {/*  <Assessment/> */}
                    </Suspense>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      </Layout>
    </React.Fragment>
  );
};

export default Initiatives;
