import { Card, Col, Row , Container} from "react-bootstrap";
import { fetchAllProjects, fetchUsersAccess, fetchAllBoards, fetchUsers } from "../../redux";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { API_ROUTE, getData, headersRequest } from "../../helpers";
import InitiativesMap from "./InitiativesMap";
import axios from "axios";

const Dashboard_Iniciativas = () =>{

    const [t,i18n] = useTranslation("landing")
    const projects = useSelector((state) => state.projects);
    const users = useSelector((state) => state.users);
    const [allAct, setAllAct] = useState([]);
    const [recAct, setRecAct] = useState([]);
    const [proAct, setProAct] = useState([]);
    const [dataDashboard, setDataDashboard] = useState([]);


    let history = useHistory();
    const handleCards = (page) => {
        history.push(page)
        };
        // projecNoComple //
    const projectNoComple = projects.projects?.filter(item => item.PctCompletadoProyecto < 100);
    // projects extracting data //
    const projPorIniciar = projects.projects.filter((item) => {
        return (item.PctCompletadoProyecto == 0 || item.PctCompletadoProyecto == null);
    });
    const projEnCurso = projects.projects.filter((item) => {
        return (item.PctCompletadoProyecto > 0 && item.PctCompletadoProyecto < 100);
    });

    const projComple = projects.projects.filter((item) => {
        return item.PctCompletadoProyecto == 100;
    });

    const actUsers = users.users.filter(item => item.Estado == "Activo")?.length;
    const inacUsers = users.users.filter(item => item.Estado == "Inactivo")?.length;

    // extracting info for main user //
    const userUID = localStorage.getItem("UID");
    const myOwnAct = allAct?.filter(item => item.IdUsuario == userUID);
    const myOwnActToDo = myOwnAct?.filter(item => item.PctAvance == 0 || item.PctAvance == null)?.length;
    const myOwnActDoing = myOwnAct?.filter(item => item.PctAvance > 0 && item.PctAvance < 100)?.length;
    const myOwnActDone = myOwnAct?.filter(item => item.PctAvance == 100)?.length;

    //estado de salud de los proyectos 
    const projCritico = projects.projects.filter(item => item.PctCompletadoProyecto < 100).filter((item) => {
        let desv = Math.round(item.PctCompletadoProyecto - item.PctPlaneadoProyecto);
        return (desv < -25);
    });
    const projModerado = projects.projects.filter(item => item.PctCompletadoProyecto < 100).filter((item) => {
        let desv = Math.round(item.PctCompletadoProyecto - item.PctPlaneadoProyecto);
        return (desv <= -1 && desv >= -25);
    });
    const projAlDia = projects.projects.filter(item => item.PctCompletadoProyecto < 100).filter((item) => {
        let desv = Math.round(item.PctCompletadoProyecto - item.PctPlaneadoProyecto);
        return (desv >= 0);
    });
    //projCritico.length

    const getDataDashboard = async () =>{
        try {
            const res = await axios.get(`${API_ROUTE}dashboard_mgi`, headersRequest());
            const data = {
                estados: res.data[0],
                totales: res.data[1],
                estadosini: res.data[2],
                tipologias: res.data[3],
            };
            setDataDashboard(data);
        } catch (error) {
            console.error(error);
        }
    };



    const dispatch = useDispatch();

    const getAllData = () => {
        Promise.all([
            getData("productivity-panel", []),
            getData("dashboard-resources-activities", []),
            getData("dashboard-projects-activities", [])]
        ).then((values) => {
            setAllAct(values[0]);
            setRecAct(values[1]);
            setProAct(values[2]);
        });
    };

    useEffect(() => {
        dispatch(fetchAllProjects());
        dispatch(fetchAllBoards());
        dispatch(fetchUsers());
        getAllData();
        getDataDashboard();
    }, []);

    const getClassByState = (estado) => {
        switch (estado.Estado) {
            case 'En Inscripción':
                return 'card-mgi-register';
            case 'En Calificación':
                return 'card-mgi-rating';
            case 'En Evaluación':
                return 'card-mgi-evaluation';
            case 'Aprobada':
                return 'card-mgi-approve'; 
            case 'En Estructuración':
                return 'card-mgi-structuring';    
            default:
                return '';
        }
    };

    const totalini = dataDashboard.totales?.length > 0 ? dataDashboard.totales[0].TotalIniciativas : 0;

    return (
        <>
            <Container fluid style={{maxWidth:'1440px'}}>
                <Card className="bg-light mt-4">
                    <Card.Body className="px-3 py-3">
                        <h4>
                            Iniciativas por estado
                        </h4>
                        <Row>
                            <Col lg={3} md={6} sm={12}>
                                <Card onClick={()=>{handleCards("/initiativebudge")}} className="my-3 border-primary card-dashboard-mgi card-mgi-initiatives">
                                    <Card.Body>
                                        <div className="d-flex justify-content-center align-items-center ">
                                            <i className="fas fa-lightbulb dashboard-icons mr-2"></i>
                                            <span className="h5 mb-0">Total Iniciativas</span>
                                        </div>
                                        {
                                            dataDashboard.totales?.length > 0 && 
                                            <h2 className="my-0 text-center">
                                                {totalini || '0'}
                                            </h2>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                            {dataDashboard.estadosini?.map((estado) => (
                                <Col key={estado.Estado} lg={3} md={6} sm={12}>
                                    <Card onClick={()=>{handleCards("/initiativebudge")}} className={`my-3 border-primary card-dashboard-mgi ${getClassByState(estado)}`}>
                                        <Card.Body>
                                            <h2 className="my-0 text-center">
                                                {estado.TotalIniciativasPorEstado}
                                            </h2>
                                            <div className="d-flex justify-content-center align-items-center ">
                                                <i className="dashboard-icons mr-2"></i>
                                                <span className="h5 mb-0">{estado.Estado}</span>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="bg-light mt-4">
                    <Card.Body className="px-3 py-3">
                        <h4>
                           Iniciativas por Tipología
                        </h4>
                        <Row>
                            <Col lg={3} md={6} sm={12}>
                                <Card 
                                    className="my-3 border-primary card-dashboard-mgi"
                                    style={{cursor:'default'}}    
                                >
                                    <Card.Body>
                                        <div className="d-flex justify-content-center align-items-center ">
                                            <i className="fa fa-star dashboard-icons mr-2"></i>
                                            <span className="h5 mb-0">Total Tipologías</span>
                                        </div>
                                        {
                                            dataDashboard.totales?.length > 0 && 
                                            <h2 className="my-0 text-center">
                                                {dataDashboard.totales[0].TotalTipologias || '0'}
                                            </h2>
                                        }
                                    </Card.Body>
                                </Card>
                            </Col>
                            {dataDashboard.tipologias?.map((tipo) => (
                                <Col key={tipo.Estado} lg={3} md={6} sm={12}>
                                    <Card 
                                        className="my-3 border-primary card-dashboard-mgi"
                                        style={{cursor:'default'}}    
                                    >
                                        <Card.Body>
                                            <h2 className="my-0 text-center">
                                                {tipo.TotalIniciativasPorTipologia}
                                            </h2>
                                            <div className="d-flex justify-content-center align-items-center text-center">
                                                <i className="dashboard-icons mr-2"></i>
                                                <span className="h5 mb-0">{tipo.NombreTipologia}</span>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="bg-light mt-4">
                    <Card.Body className="px-3 py-3">
                        <h4>
                            Sección de Proyectos
                        </h4>
                        <Row>
                            <Col lg={3} md={6} sm={12}>
                                <Card className="border-primary card-dashboard bg-users card-projects my-3">
                                    <Card.Body onClick={() => handleCards("/projects")} className="py-2 px-2 d-flex flex-column justify-content-between align-items-center">
                                        <div className="d-flex justify-content-center align-items-center ">
                                        <i className="fa fa-clipboard dashboard-icons mr-2"></i>
                                        <span className="h5 mb-0">{t("landing_card_projects_title")}</span>
                                        </div>
                                        <h2 className="my-0">{projects.projects?.length}</h2>
                                        <div className="d-flex">
                                        <div className="d-flex flex-column align-items-center mr-2">
                                            <small className="text-dark"><i class="fa fa-circle mr-2" style={{color:"#c5c5c5"}}></i>{t("landing_card_projects_convention_start")}</small>
                                            <small className="text-primary">( {projPorIniciar?.length} )</small>
                                        </div>
                                        <div className="d-flex flex-column align-items-center mr-2">
                                            <small className="text-dark"><i class="fa fa-circle text-info mr-2"></i>{t("landing_card_projects_convention_inprogress")}</small>
                                            <small className="text-primary">( {projEnCurso?.length} )</small>
                                        </div>
                                        <div className="d-flex flex-column align-items-center mr-2">
                                            <small className="text-dark"><i class="fa fa-circle text-success mr-2"></i>{t("landing_card_projects_convention_complete")}</small>
                                            <small className="text-primary">( {projComple?.length} )</small>
                                        </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                                <Card className="border-primary card-dashboard bg-users card-projects my-3">
                                    <Card.Body onClick={() => handleCards("/projects")} className="py-2 px-2 d-flex flex-column justify-content-between align-items-center">
                                        <div className="d-flex justify-content-center align-items-center ">
                                        <i className="fa fa-clipboard dashboard-icons mr-2"></i>
                                        <span className="h5 mb-0">Estado de Salud Proyectos</span>
                                        </div>
                                        <h4 className="my-0"><small>(En curso)</small></h4>
                                        <div className="d-flex">
                                        <div className="d-flex flex-column align-items-center  mr-2">
                                            <small className="text-dark"><i class="fa fa-circle text-success mr-2" ></i>Al día</small>
                                            <small className="text-primary">( {projAlDia?.length} )</small>
                                        </div>
                                        <div className="d-flex flex-column align-items-center mr-2">
                                            <small className="text-dark"><i class="fa fa-circle text-warning mr-2"></i>Moderados</small>
                                            <small className="text-primary">( {projModerado?.length} )</small>
                                        </div>
                                        <div className="d-flex flex-column align-items-center mr-2">
                                            <small className="text-dark"><i class="fa fa-circle text-danger mr-2"></i>Crítico</small>
                                            <small className="text-primary">( {projCritico?.length} )</small>
                                        </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={3} md={6} sm={12}>
                                <Card className="border-primary card-dashboard card-calender my-3">
                                    <Card.Body onClick={() => handleCards("/tasks")} className="py-2 px-2 d-flex flex-column justify-content-between align-items-center">
                                        <div className="d-flex justify-content-center align-items-center ">
                                        <i className="fa fa-tasks dashboard-icons mr-2"></i>
                                        <span className="h5 mb-0">{t("landing_card_activities_title")}</span>
                                        </div>
                                        <h2 className="my-0">{myOwnAct?.length}</h2>
                                        <div className="d-flex">
                                        <div className="d-flex flex-column align-items-center mr-2">
                                            <small className="text-dark"><i class="fa fa-circle mr-2" style={{color:"#c5c5c5"}}></i>{t("landing_card_activities_convention_start")}</small>
                                            <small className="text-primary">( {myOwnActToDo} )</small>
                                        </div>
                                        <div className="d-flex flex-column align-items-center mr-2">
                                            <small className="text-dark"><i class="fa fa-circle text-info mr-2"></i>{t("landing_card_activities_convention_inprogress")}</small>
                                            <small className="text-primary">( {myOwnActDoing} )</small>
                                        </div>
                                        <div className="d-flex flex-column align-items-center mr-2">
                                            <small className="text-dark"><i class="fa fa-circle text-success mr-2"></i>{t("landing_card_activities_convention_complete")}</small>
                                            <small className="text-primary">( {myOwnActDone} )</small>
                                        </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col md={6} lg={3} className="mb-2">
                                <Card className="border-primary card-dashboard card-users my-3">
                                    <Card.Body onClick={() => handleCards("/resources")} className="py-2 px-2 d-flex flex-column justify-content-between align-items-center">
                                        <div className="d-flex justify-content-center align-items-center ">
                                        <i className="fa fa-users dashboard-icons mr-2"></i>
                                        <span className="h5 mb-0">Recursos</span>
                                        </div>
                                        <h2 className="my-0">{users.users?.length}</h2>
                                        <div className="d-flex">
                                        <div className="d-flex flex-column align-items-center mr-2">
                                            <small className="text-dark"><i class="fa fa-circle text-primary mr-2"></i>{t("landing_card_resources_convention_active")}</small>
                                            <small className="text-primary">( {actUsers} )</small>
                                        </div>
                                        <div className="d-flex flex-column align-items-center mr-2">
                                            <small className="text-dark"><i class="fa fa-circle text-dark mr-2"></i>{t("landing_card_resources_convention_inactive")}</small>
                                            <small className="text-primary">( {inacUsers} )</small>
                                        </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <Card className="bg-light mt-4">
                    <Card.Body className="px-3 py-3">
                        <h4>
                            Ubicación Iniciativas | Proyectos
                        </h4>
                        <Row className="d-flex justify-content-center align-items-center my-3">
                            <InitiativesMap />
                        </Row>
                    </Card.Body>
                </Card>
            </Container>
        </>
    );
}
export default Dashboard_Iniciativas;