import { round } from 'lodash';
import React, { useState } from 'react';
import { Table } from 'react-bootstrap';

const FileUploader = () => {
    const [files, setFiles] = useState([]);

    const handleFileChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const updatedFiles = selectedFiles.map(file => ({
            name: file.name,
            size: file.size,
            date: new Date().toLocaleString(),
            url: URL.createObjectURL(file) // Crea una URL temporal para el archivo
        }));
        setFiles(prevFiles => [...prevFiles, ...updatedFiles]);
    };

    return (
        <>
            <h2>Cargar Archivos</h2>
            <input type="file" multiple onChange={handleFileChange} />
            <Table striped hover className='mt-5'>
                <thead>
                    <tr>
                        <th>Nombre</th>
                        <th>Tamaño (Mbs)</th>
                        <th>Fecha de Carga</th>
                        <th>Vista Previa</th>
                    </tr>
                </thead>
                <tbody>
                    {files.map((file, index) => (
                        <tr key={index}>
                            <td>{file.name}</td>
                            <td>{round(((file.size)/1024)/1024,2)}</td>
                            <td>{file.date}</td>
                            <td>
                                <a href={file.url} target="_blank" rel="noopener noreferrer">Ver</a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
};

export default FileUploader;
