import { Card, Col, Container, Form, Row } from "react-bootstrap";
import Layout from "../../Layout";
import TitleCommon from "../TitleCommon";
import { Link, useHistory, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import { API_ROUTE, headersRequest } from "../../helpers";
import { formatDateToInput } from "./helpers_MGI";
import SuperTableV2 from "../SuperTableV2";
import InitiativesMap from "./InitiativesMap";
import FileUploader from "./FileUploader";
import TimelineMGI from "./TimeLineMGI";

const DashboardDetailView = () => {
    const [dataBudge, setDataBudge] = useState([]);
    const [initiative, setInitiative] = useState([]);
    const [objectives, setObjectives] = useState([]);
    const {idInitiative} = useParams();
    
    const getInitiative = async () => {
        try {
            const res = await axios.get(`${API_ROUTE}iniciativa/${idInitiative}`, headersRequest());
            const initiative = {
            ...res.data,
                FechaIdentificacion: formatDateToInput(res.data.FechaIdentificacion),
                FechaCreacion: formatDateToInput(res.data.FechaCreacion),
            };
            setInitiative(initiative);
        } catch (error) {
            console.error(error);
        }
    };

    const getDataBudge = async () =>{
        try {
            const res = await axios.get(`${API_ROUTE}initiativesbudge`, headersRequest());
            const data = res.data;
            const filtred = data?.filter(item => item.IdIniciativa === idInitiative)
            setDataBudge(filtred?.[0] || null);
        } catch (error) {
            console.error(error);
        }
    };

    const getDataAnswers = async () =>{
        try {
            const res = await axios.get(`${API_ROUTE}answers/${idInitiative}`, headersRequest());
            // const answers = {
            // ...res.data,
            //     FechaEstructuracion: formatDateToInput(res.data.FechaEstructuracion),
            // };
            // console.log(answers);
            setObjectives(res.data);
        } catch (error) {
            console.log(error);
        }
    };

    const getClassByState = (estado) => {
        switch (estado.Estado) {
            case 'En Inscripción':
                return 'card-mgi-register';
            case 'En Calificación':
                return 'card-mgi-rating';
            case 'En Evaluación':
                return 'card-mgi-evaluation';
            case 'Aprobada':
                return 'card-mgi-approve'; 
            case 'En Estructuración':
                return 'card-mgi-structuring';    
            default:
                return '';
        }
    };

    const columns = [
        {
          caption: "Nombre",
          selector:"Nombre",
          width: 350,
        },
        {
          caption: "Descripción",
          selector: "Descripcion",
          width: 500,
        },
        {
          caption: "Tipo",
          selector: "Tipo",
          width: 50,
        },
        {
          caption: "Fecha Estructuración",
          selector: "FechaEstructuracion",
          width: 130,
        },
        {
          caption: "Estructurado Por",
          selector: "EstructuradoPor",
          width: 130,
        },
        {
          caption: "Respuesta",
          selector: "Respuesta",
          width: 130,
        },
    ];

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('es-ES', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(amount);
    };

    useEffect(()=>{
        getDataBudge();
        getInitiative();
        getDataAnswers();
    },[]);
    
    return (
        <>
            <Layout>
                <TitleCommon
                    title={{
                        text: (
                            <>
                                <i className="fas fa-circle-info" style={{ marginRight: '8px' }}></i>
                                HV Iniciativa | Perfil de la Iniciativa
                            </>
                        ),
                    }}
                    color={"secondary"}
                    goBack={{
                        text: "Volver",
                        icon: "arrow-left",
                      }}
                />
                <Container fluid style={{maxWidth:'1440px'}}>
                    <Card className="bg-light mt-4">
                        <Card.Body className="px-3 py-3">
                            <h4>
                                Identificación
                            </h4>
                            <Row className="d-flex align-items-center">
                                <Col md={8} sm={12}>
                                    <Card 
                                        className="my-3 border-primary card-mgi-initiatives" 
                                        // style={{maxHeight:'250px', minHeight:'250px'}}
                                    >
                                        <Card.Body>
                                            <Row>
                                                <Col md={6} sm={12}>
                                                    <Form.Label style={{ fontWeight: "700" }}>Nombre Iniciativa</Form.Label>
                                                    <Form.Control
                                                        name="NombreIniciativa"
                                                        as="textarea"
                                                        readOnly
                                                        value={initiative.NombreIniciativa|| ""}
                                                        rows={2}
                                                    />
                                                </Col>
                                                <Col md={6} sm={12}>
                                                    <Form.Label style={{ fontWeight: "700" }}>Descripción</Form.Label>
                                                    <Form.Control
                                                        name="Descripcion"
                                                        as="textarea"
                                                        readOnly
                                                        value={initiative.Descripcion|| ""}
                                                        rows={2}
                                                    />
                                                </Col>
                                                <Col md={6} sm={12}>
                                                    <Form.Label style={{ fontWeight: "700" }}>Creada Por</Form.Label>
                                                    <Form.Control
                                                        name="CreadaPor"
                                                        type="text"
                                                        readOnly
                                                        value={initiative.CreadoPor|| ""}
                                                    />
                                                </Col>
                                                <Col md={6} sm={12}>
                                                    <Form.Label style={{ fontWeight: "700" }}>Responsable</Form.Label>
                                                    <Form.Control
                                                        name="Responsable"
                                                        type="text"
                                                        readOnly
                                                        value={initiative.Responsable|| ""}
                                                    />
                                                </Col>
                                                <Col md={6} sm={12}>
                                                    <Form.Label style={{ fontWeight: "700" }}>Fecha Creación</Form.Label>
                                                    <Form.Control
                                                        name="FechaCreacion"
                                                        type="text"
                                                        readOnly
                                                        value={initiative.FechaCreacion|| ""}
                                                    />
                                                </Col>
                                                <Col md={6} sm={12}>
                                                    <Form.Label style={{ fontWeight: "700" }}>Fecha Identificación</Form.Label>
                                                    <Form.Control
                                                        name="FechaIdentificacion"
                                                        type="text"
                                                        readOnly
                                                        value={initiative.FechaIdentificacion|| ""}
                                                    />
                                                </Col>
                                                <Col md={6} sm={12}>
                                                    <Form.Label style={{ fontWeight: "700" }}>Estado Actual</Form.Label>
                                                    <Form.Control
                                                        name="EstadoActual"
                                                        type="text"
                                                        readOnly
                                                        value={initiative.Estado|| ""}
                                                    />
                                                </Col>
                                            </Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col md={4} sm={12}>
                                    <Card 
                                        className="my-3 border-primary card-mgi-budge"
                                        style={{minHeight:'274px'}}
                                    >
                                        <Card.Body
                                            style={{
                                                display:'flex', 
                                                flexDirection:'column',
                                                alignItems:'center',
                                                justifyContent:'center',
                                            }}
                                        >
                                           {
                                            dataBudge?.Presupuesto ? (
                                                <>
                                                    <div className="text-center">
                                                        <i className="fas fa-sack-dollar dashboard-icons mr-2"></i>
                                                        <span className="h5 mb-3">Presupuesto Estimado</span>
                                                    </div>
                                                    <h2 className="my-0">
                                                        $ {formatCurrency(dataBudge.Presupuesto)} {dataBudge.Moneda}
                                                    </h2>
                                                </>
                                            ) : (
                                                    <>
                                                        <div className="text-center">
                                                            <i className="fas fa-sack-dollar dashboard-icons mr-2"></i>
                                                            <span className="h5 mb-3">Aún no hay Presupuesto Asignado</span>
                                                        </div>
                                                        <h2 className="my-0">
                                                            $ {formatCurrency(0)} 
                                                        </h2>
                                                    </>
                                                )
                                           }
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                    <Card className="bg-light mt-4">
                        <Card.Body className="px-3 py-3">
                            <h4>
                                Parámetros | Criterios | Obj. Estratégicos
                            </h4>
                        </Card.Body>
                        <Card.Footer>
                            <SuperTableV2
                                columns={columns}
                                data={objectives}
                            />
                        </Card.Footer>
                    </Card>
                    <Card className="bg-light mt-4">
                        <Card.Body className="px-3 py-3">
                            <h4>
                                Estado (bolitas)
                            </h4>
                        </Card.Body>
                        <Card.Footer className="d-flex align-items-center">
                            <TimelineMGI />
                        </Card.Footer>
                    </Card>
                    <Card className="bg-light my-4">
                        <Card.Body className="px-3 py-3">
                            <h4>
                                Gestión Documental
                            </h4>
                        </Card.Body>
                        <Card.Footer>
                            <FileUploader/>
                        </Card.Footer>
                    </Card>
                </Container>
            </Layout>
        </>
    );

}
export default DashboardDetailView