import React from "react";
import { useSelector } from "react-redux";
import DataGrid, {
  Column,
  Grouping,
  GroupPanel,
  Pager,
  Paging,
  SearchPanel,
} from "devextreme-react/data-grid";
import { CommonSeriesSettingsHoverStyle } from "devextreme-react/chart";
import { calcularDuracionMinutes, getData } from "../../helpers";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const ActivitiesByResources = (props) => {
  const [t,i18n] =useTranslation("planning")

  const scrum = useSelector((state) => state.scrum);

  const [getAllActivitiesByResources , setGetAllActivitiesByResources] = useState([])
 

  const getActivitiesByResources = (uid) => {
    Promise.all([
      getData(`product-backlog-resources/${uid}`,[])])
      .then((values) => {
        setGetAllActivitiesByResources(values[0])
    });
  };


  const columns = [
    {
      caption: "Sprint",
      selector: "SprintNombre",
      width: 110,
    },
    {
      caption: t("planningboard_resources_tablecolumn_column"),
      selector: "NombreColumna",
      width: 110,
    },
    {
      caption: t("planningboard_resources_tablecolumn_activity"),
      selector: "Actividad",
      customRender: (row) => {
        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
             
              if (row.data.IdActividad)
                document.getElementById(`card-${row.data.IdActividad}`).click();
              //   handleShow();
              //   setTaskEdit(row.data);
            }}
          >
            {row.data.Destacada && (
              <small>
                <i class="fa fa-star text-warning mr-1"></i>
              </small>
            )}
            {row.data.Actividad}
          </a>
        );
      },
      minWidth: 210,
    },
    {
      caption: t("planningboard_resources_tablecolumn_start"),
      selector: "FechaInicio",
      width: 110,
    },
    {
      caption: t("planningboard_resources_tablecolumn_end"),
      selector: "FechaFin",
      width: 110,
    },
    {
      caption: t("planningboard_hu_tablecolumn_delays"),
      selector: "DiasAtraso",
      width: 60,
      customRender : (row) =>{
        return (<div className="text-center"><span>{row.data.DiasAtraso}</span></div>)
      }
    },
    {
      caption: t("planningboard_hu_tablecolumn_ind_delays"),
      selector: "IndicadorAtraso",
      width: 80,
      customRender: (row) => {
        const valueDelaysIndicator= () =>{
          switch (row.data.IndicadorAtraso.toLowerCase()) {
            case "por iniciar":
              return "text-info fw-bold";

            case "al día":
              return "text-success fw-bold";

            case "leve":
                return "text-warning fw-bold";

            case "moderado":
              return "text-warning fw-bold" ;

            case "crítico":
              return "text-danger fw-bold" ;

            case "extremo":
              return  "text-dark fw-bold";
            default:
              return ""
          }
        }

        return (
          <>
          <div className= {`d-flex justify-content-center`}>
          <span className= {`${valueDelaysIndicator()}`} >{row.data.IndicadorAtraso}</span>
          </div>
          </>
        ) 
      },
    },
    {
    caption: t("planningboard_resources_tablecolumn_value"),
    selector: "ValorPO",
    width: 80,
    customRender: (row) => {
      return (
        <>
        <div className="d-flex justify-content-center">
        <span >{row.data.ValorPO}</span>
        </div>
        </>
      ) 
    },
    },
    {
      caption: t("planningboard_resources_tablecolumn_advanced"),
      selector: "Estado",
      width: 80,
      customRender: (row) => {
        return(
        <>
          {(row.data.pctcompletado === 100) && <>
          <span className="mr-2" >
            <i className="fa fa-circle text-success"></i> {row.data.Estado}
          </span>
          </>}
          {(row.data.pctcompletado === 0) && <>
          <span className="mr-2">
          <i className="fa fa-circle text-light"></i>{row.data.Estado}
          </span>
          </>}
          {(row.data.pctcompletado > 0 && row.data.pctcompletado < 100) && <>
          <span className="mr-2">
          <i className="fa fa-circle text-info"></i>{row.data.Estado}
          </span>
          </>}
        </>
      )},
    },
    {
      caption: t("planningboard_resources_tablecolumn_last"),
      selector: "ultimamodificacion",
      width: 110,
    },
  ];
  const columnsHours = [
    {
      caption: "Sprint",
      selector: "SprintNombre",
      width: 110,
    },
    {
      caption: t("planningboard_resources_tablecolumn_column"),
      selector: "NombreColumna",
      width: 110,
    },
    {
      caption: t("planningboard_resources_tablecolumn_activity"),
      selector: "Actividad",
      customRender: (row) => {
        return (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
             
              if (row.data.IdActividad)
                document.getElementById(`card-${row.data.IdActividad}`).click();
            }}
          >
            {row.data.Destacada && (
              <small>
                <i class="fa fa-star text-warning mr-1"></i>
              </small>
            )}
            {row.data.Actividad}
          </a>
        );
      },
      minWidth: 210,
    },
    {
      caption: t("planningboard_resources_tablecolumn_start"),
      selector: "FechaInicio",
      width: 110,
    },
    {
      caption: t("planningboard_resources_tablecolumn_end"),
      selector: "FechaFin",
      width: 110,
    },
    {
      caption: "Horas",
      selector:"Horas",
      width: 60,
      customRender:(row)=>{
        const totalHours = row.data.FechaInicioHoras? calcularDuracionMinutes(row.data.FechaInicioHoras, row.data.FechaFinHoras) : "N/A"
        console.log(totalHours)
        return (<div className="text-center"><span>{totalHours}</span></div>)
      }
    },
    {
      caption: t("planningboard_hu_tablecolumn_delays"),
      selector: "DiasAtraso",
      width: 60,
      customRender : (row) =>{
        return (<div className="text-center"><span>{row.data.DiasAtraso}</span></div>)
      }
    },
    {
      caption: t("planningboard_hu_tablecolumn_ind_delays"),
      selector: "IndicadorAtraso",
      width: 80,
      customRender: (row) => {
        const valueDelaysIndicator= () =>{
          switch (row.data.IndicadorAtraso.toLowerCase()) {
            case "por iniciar":
              return "text-info fw-bold";

            case "al día":
              return "text-success fw-bold";

            case "leve":
                return "text-warning fw-bold";

            case "moderado":
              return "text-warning fw-bold" ;

            case "crítico":
              return "text-danger fw-bold" ;

            case "extremo":
              return  "text-dark fw-bold";
            default:
              return ""
          }
        }

        return (
          <>
          <div className= {`d-flex justify-content-center`}>
          <span className= {`${valueDelaysIndicator()}`} >{row.data.IndicadorAtraso}</span>
          </div>
          </>
        ) 
      },
    },
    {
    caption: t("planningboard_resources_tablecolumn_value"),
    selector: "ValorPO",
    width: 80,
    customRender: (row) => {
      return (
        <>
        <div className="d-flex justify-content-center">
        <span >{row.data.ValorPO}</span>
        </div>
        </>
      ) 
    },
    },
    {
      caption: t("planningboard_resources_tablecolumn_advanced"),
      selector: "Estado",
      width: 80,
      customRender: (row) => {
        return(
        <>
          {(row.data.pctcompletado === 100) && <>
          <span className="mr-2" >
            <i className="fa fa-circle text-success"></i> {row.data.Estado}
          </span>
          </>}
          {(row.data.pctcompletado === 0) && <>
          <span className="mr-2">
          <i className="fa fa-circle text-light"></i>{row.data.Estado}
          </span>
          </>}
          {(row.data.pctcompletado > 0 && row.data.pctcompletado < 100) && <>
          <span className="mr-2">
          <i className="fa fa-circle text-info"></i>{row.data.Estado}
          </span>
          </>}
        </>
      )},
    },
    {
      caption: t("planningboard_resources_tablecolumn_last"),
      selector: "ultimamodificacion",
      width: 110,
    },
  ];

  const resourcesHeader = (data) => {
    let user_lg = window.localStorage.getItem("idioma")
    if(user_lg == "es"){
      return data?.map((activity) => {
        return {
          ...activity,
          Recurso: `${activity.Recurso} (Actividades: ${
            activity.CantActividadesRecurso || "-"
          } | Inicio: ${activity.FechaInicioRecurso || "-"} | Fin: ${
            activity.FechaFinRecurso || "-"
          } | % Avance: ${activity.pctcompletadoRecurso } )`,
        };
      });
    }else {
      return data?.map((activity) => {
        return {
          ...activity,
          Resource: `${activity.Recurso} (Activities: ${
            activity.CantActividadesRecurso || "-"
          } | Start: ${activity.FechaInicioRecurso || "-"} | End: ${
            activity.FechaFinRecurso || "-"
          } | % Advance: ${activity.pctcompletadoRecurso } )`,
        };
      });
    }
   
  }; 
  useEffect(()=> {
    getActivitiesByResources(props.uid)
  },[props.updateActiByReso])

  const columnsToShow = props.hasDurationHours ? columnsHours : columns
  return (
    <DataGrid
      dataSource={resourcesHeader(getAllActivitiesByResources)}
      allowColumnReordering={true}
      showBorders={true}
      allowColumnResizing ={true}
    >
      <GroupPanel visible={true} />
      <SearchPanel visible={true} placeholder={t("planningboard_resources_searchbox")} />
      <Grouping autoExpandAll={false} />

      <Column dataField={t("planningboard_resources_tablecolumn_resource")} caption={t("planningboard_resources_tablecolumn_resource")} groupIndex={0} />
      {columnsToShow?.map((itmCol, index) => {
        return (
          <Column
            key={index}
            dataField={itmCol.selector}
            caption={itmCol.caption}
            width={itmCol.width}
            minWidth={itmCol.minWidth}
            cellRender={(row) => {
              return (
                <>{itmCol.customRender ? itmCol.customRender(row) : row.text}</>
              );
            }}
          />
        );
      })}

      {/* <Pager allowedPageSizes={pageSizes} showPageSizeSelector={true} /> */}
      <Paging defaultPageSize={50} />
    </DataGrid>
  );

  return <div>{JSON.stringify(scrum.stories)}</div>;
};

export default ActivitiesByResources;
