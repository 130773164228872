import React, { useState, useEffect, useRef, useMemo } from "react";
import Board from "react-trello";
import styled from "styled-components";
import { v4 as uuidv4 } from "uuid";
import {
  AddActivity,
  NewCard,
  AddSprintButton,
  NewSprint,
  LaneHeader,
  CardBacklog
} from "./CustomComponents";
import ModalEditTask from "./ModalEditTask";
import Loading from "../Loading";
import { useSelector, useDispatch } from "react-redux";
import { Button, Form } from "react-bootstrap";
import { updateBacklog } from "../../redux";
import { deleteDataScrum, getData, updateDataScrum } from "../../helpers";
import swal from "sweetalert";
import ScrollRangeBoard from "../ScrollRangeBoard";
import ModalEditSprint from "./ModalEditSprint";
import { updateSprint } from "../../services/agile";
import tasksound from '../../assets/sounds/tasksound.mp3'
import projectSound from '../../assets/sounds/projectsound.mp3'
import ReactHowler from "react-howler";
import ReactConfetti from "react-confetti";
import { useTranslation } from "react-i18next";
import ModalAvancedOptions from "./ModalAvancedOptions";
/* import ModalEditEpic from "./ModalEditEpic";
import ModalEditPBI from "./ModalEditPBI"; */



const _ = require("lodash");

const StyledBoard = styled.div`
  .react-trello-board {
    *::-webkit-scrollbar {
      width: 8px;
    }
    *::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    *::-webkit-scrollbar-thumb {
      background: #b1b1b1;
    }
    *::-webkit-scrollbar-thumb:hover {
      background: #727272;
    }
    background-color: transparent;
    height: 100vh;
    .react-trello-lane {
      background: transparent;
      border-radius: 0;
    }
    .react-trello-lane:first-child {
      margin-right: 2rem;
      border-right: 1px solid #d6d6d6;
      padding-bottom: 4rem;
      box-shadow: 20px 0 20px -20px rgb(0 0 0 / 10%);
    }
    .smooth-dnd-stretcher-element.vertical {
      background: rgba(0, 0, 0, 0.05);
    }
    .smooth-dnd-container.vertical {
      padding-top: 0 !important;
    }
    .smooth-dnd-container.vertical {
      padding-top: 1rem;
      .react-trello-card {
        border: 1px solid #d6d6d6;
      }
    }
  }
`;

const ProductBacklog = (props) => { 
  const [t,i18n] =useTranslation("planning")
  const scrum = useSelector((state) => state.scrum);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [showAvancedOptions, setShowAvancedOptions] = useState(false);
/*   const [showEpicModal, setShowEpicModal] = useState(false);
  const [showEpicModalPBI, setShowEpicModalPBI] = useState(false); */
  const [showSprintDetails, setShowSprintDetails] = useState(false);
  const [activeSoundCompletedTask, setActiveSoundCompletedTask] = useState(false);
  const [activeSoundCompletedProject, setActiveSoundCompletedProject] = useState(false);
  const [activeAnimationCompleProj,setActiveAnimationCompleProj] = useState(false)
  const [progressProjec, setProgressProjec] = useState([])
  const [customFields, setCustomFiels]= useState([])
  /* const [resources, setResources] = useState([]) */
  const uid = window.localStorage.getItem("UID")
  const [rol,setRol]=useState("")
  
  const handleShowAcvancedOptions =(e) =>{
    e.stopPropagation();
    setShowAvancedOptions((val)=> val =! val)
  }
  const profileRol = window.localStorage.getItem("rol")
  useEffect(()=>{
    setRol(profileRol)
  },[profileRol])
  // conffeti animation //

  const [windowDimen, setWindowDimen] = useState({
    width: window.innerWidth,
    height: window.innerHeight  
  })
  
  const detectSize = () =>{
    setWindowDimen({
      width: window.innerWidth,
      height: window.innerHeight  
    })
  }

  useEffect(() => {
    window.addEventListener('resize',detectSize);
    return () => {
      window.removeEventListener('resize', detectSize);
    }
  }, [windowDimen]);
  
const getProjectCompl= () => {
  Promise.all([
    getData(`hv-project-agile/${props.uid}`, [])])
    .then((values) => {
    setProgressProjec(values[0]);
    
  });

};

const getCustomFields = (idOrganization)=>{
  Promise.all([
    getData(`custom-fields/${idOrganization}`,[])])
    .then((values) => {
      setCustomFiels(values[0])
    })
}

useEffect(()=>{
  const idOrganization = localStorage.getItem("OID")
  getCustomFields(idOrganization)
},[])

const statusProject = (projectID) => {
 
  Promise.all([
    getData(`agile-cards-status-project/${projectID}`, [])])
    .then((values) => {
      console.log(values[0].PromedioAvance)
     /*  activeConfe(values[0].PromedioAvance) */
    });

  
};

const activeConfe = (progress)=>{
    if(progress === 100){
      setActiveAnimationCompleProj(true)
      setActiveSoundCompletedProject(true)
      swal({
        title: "¡Excelente Trabajo!",
        text: "Han terminado todas las actividades del proyecto. Sigamos adelante con la misma calidad y empeño.",
        icon: "success",
      })
      .then((willDelete) => {
        if (willDelete) {
            setActiveAnimationCompleProj(false)
            setActiveSoundCompletedProject(false)
        }
      });
      }
  }
  const activeSountTaskComplete = (taskProgress) =>{
    if(taskProgress == 100)
      setActiveSoundCompletedTask(true)
    setTimeout(()=>{
      setActiveSoundCompletedTask(false)
    },3000)
  }
   
  /* useEffect(()=>{
    
    if (scrum.resources.length > 0) {
     let  resourcesData = scrum.resources.map((resource) => {
        return { ...resource, selected: false, name: resource.text };
      });
      setResources(resourcesData)
    }
  },[scrum]) */

  let resources = [];
  if (scrum.resources.length > 0) {
    resources = scrum.resources.map((resource) => {
      return { ...resource, selected: false, name: resource.text };
    });
  }

  let tags = [];
  if (scrum.tags.length > 0) {
    tags = scrum.tags.map((tags) => {
      return { ...tags, selected: false, name: tags.NombreEtiqueta };
    });
  }

  const taskEdit = useRef({});
  const titleColumn = useRef({});

  const sprintToEdit = useRef({});

  const editSprint = (payload) => {
    sprintToEdit.current = payload;
    setShowSprintDetails(true);
  };

  const backlogDataMemoized = useMemo(() => {
    if (!props.filterSpsByStatus){
      return scrum.backlog.data;
    } 
    let filteredSprints = {
      lanes: scrum.backlog.data.lanes.filter(
        (lane) =>
          !lane.Estado ||
          lane.Estado === props.filterSpsByStatus ||
          lane.title === "Product Backlog"
      )
    };
    return filteredSprints;
  }, [props.filterSpsByStatus]);

  const backlogDataMemoizedbyRol = useMemo(() => {
    if (!props.filterSpsByStatus){
    const withOutPB= scrum.backlog.data.lanes?.filter((lanes)=> lanes.title != "Product Backlog")
      /* const dataByRol= scrum.backlog.data.lanes?.map((lanes)=>{ */
      const dataByRol= withOutPB.map((lanes)=>{ 
        if(lanes.title != "Product Backlog"){
          return {...lanes,
            cards: lanes.cards?.filter((items)=> {
              return items.metadata.resources.map((resource)=> resource.id).includes(uid)
            })
           }
        }else{
          return lanes
        }
      })
      return {lanes:dataByRol};
    }
    const withOutPB= scrum.backlog.data.lanes?.filter((lanes)=> lanes.title != "Product Backlog")
    let filteredSprints = {
      
      lanes : withOutPB?.map((lanes)=>{
        if(lanes.title != "Product Backlog"){
          return {...lanes,
            cards: lanes.cards?.filter((items)=> {
              return items.metadata.resources.map((resource)=> resource.id).includes(uid)
            })
           }
        }else{
          return {...lanes}
        }
      }).filter((lane)=> 
          !lane.Estado ||
          lane.Estado === props.filterSpsByStatus ||
          lane.title === "Product Backlog"
      )
    };
    return filteredSprints;
  }, [props.filterSpsByStatus]);
  
  const filterTask = (idCard) => {
    let dummie = _.find(
      scrum.backlog.data.lanes,
      _.flow(_.property("cards"), _.partialRight(_.some, { id: idCard }))
    );
    dummie = _.filter(dummie.cards, (item) => item.id === idCard);
    return dummie[0];
  };

  const filterTitleCol = (idCard) => {
    let dummie = _.find(
      scrum.backlog.data.lanes,
      _.flow(_.property("cards"), _.partialRight(_.some, { id: idCard }))
    );
    return dummie.title
  }

  const handleUpdateUS  = ()=>{
    props.setUpdateUS((val)=> val =! val)
  }
  const handleUpdateActiByReso  = ()=>{
    props.setUpdateActiByReso((val)=> val =! val)
  }
  const handleUpdateTags  = ()=>{
    props.setUpdateTags((val)=> val =! val)
  }

  const updateCardBoard = (data) => {
    let dummie = { ...scrum.backlog.data };
    let lanePos = _.findIndex(scrum.backlog.data.lanes, function (o) {
      return o.id === data.laneId;
    });
    let cardPos = _.findIndex(
      scrum.backlog.data.lanes[lanePos].cards,
      function (o) {
        return o.id === data.id;
      }
    );
    if (cardPos != -1) {
      dummie.lanes[lanePos].cards[cardPos] = { ...data, update: true };
      data.IdProject = props.uid
      // setBoardData({ ...dummie });
      // alert("put card data");
      updateDataScrum(`agile-cards/${data.id}`, data)
        .then((res) => {
          if (data.id) {
            dispatch(updateBacklog(dummie));
            activeConfe(res.PromedioAvance)
            handleUpdateUS()
            handleUpdateActiByReso()
            handleUpdateTags()
            if(res.PromedioAvance != 100){
              activeSountTaskComplete(data.metadata.progress)
            }
            props.getAllDataAct()
           
            /* if(res.PromedioAvance === 100){
              activeConfe()
            } */
            // refrescar actividades si el usuario agregó una nota
          /*   if(data.metadata.progress == 100 && res.PromedioAvance != 100) { 
              setActiveSoundCompletedTask(true)
              setTimeout(()=> {
                props.getAllprojectData();
              },300)

            }else if(data.metadata.progress == 100 && res.PromedioAvance == 100){
              statusProject(data.metadata.ProyectoId)
              setTimeout(()=>{
                props.getAllprojectData();
              },5000)
              
            }else {
              props.getAllprojectData();
            }   */       
          } else {
            props.getAllDataAct()
            dispatch(updateBacklog(dummie));
          }
        })
        .catch((err) =>
        setTimeout(()=> {
          swal(
            "¡Ups!",
            "Hubo un error al actualizar los datos la actividad, por favor refresca la página.",
            "error"
          )
        },3000)
        );
    }
  };

  const updateSprintData = (payload) => {
    updateSprint(payload.id, payload)
      .then(() => {
        props.getAllprojectData();
        setShowSprintDetails(false)
      })
      .catch(() =>
        swal("¡Ups!", "Hubo un error al actualizar Sprint", "error")
      );
  };

  const handleDragEndCard = (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails
  ) => {
    let arrCards = scrum.backlog.data.lanes.find(
      (lane) => lane.id === targetLaneId
    ).cards;
    arrCards = arrCards.filter((card) => card.id !== cardId);
    arrCards.splice(position, 0, cardDetails);
    let arrToSend = arrCards.map((card) => card.id).join();
    updateDataScrum(`agile-backlog-cards-position/${targetLaneId}`, {
      projectId: props.uid,
      idsOrdered: arrToSend // string de UID de actividades para ordenarlas posición.
    }).catch((err) =>
    setTimeout(()=> {
      swal(
        "¡Ups!",
        "Hubo un error al actualizar la posición de la actividad, por favor refresca la página.",
        "error"
      )
    },3000) 
    );
  };

  const handleCardDelete = (cardId) => {
    deleteDataScrum(`agile-cards-delete/${cardId}`).catch((err) =>
      swal(
        "¡Ups!",
        "Hubo un error al eliminar la actividad, por favor refresca la página.",
        "error"
      )
    );
  };
  const idProfileRol = (window.localStorage.getItem("cm9sX3VzdWFyaW8=") == 108 )? true : false
  return (
    <>
      <ScrollRangeBoard target=".react-trello-board"/>
      <StyledBoard id="pwc-product-backlog-kanban" className={(idProfileRol)? "disable-product-backlog" : ""}  >
        <Board
         /*  data={backlogDataMemoized} */
          data = {(rol == "Miembro de Equipo" || rol == 81)? backlogDataMemoizedbyRol : backlogDataMemoized}
          editable= {true} //hide or show create sprint- add item
          canAddLanes
          cardDraggable={idProfileRol? false : true}
          onDataChange={(data) => {
            dispatch(updateBacklog(data));
          }}
          onCardClick={(cardId) => {
            taskEdit.current = filterTask(cardId);
            titleColumn.current = filterTitleCol(cardId)
            setShow(true);
          }}
          onCardMoveAcrossLanes={(fromLaneId, toLaneId, cardId, index) => {
            let cardMovedData = { fromLaneId, toLaneId, cardId, index, metadata:{
              ProyectoId : props.uid,
            } };
            updateDataScrum(
              `agile-cards/${cardMovedData.cardId}`,
              cardMovedData
            ).catch((err) =>
                setTimeout(()=> {
                  swal(
                    "¡Ups!",
                    "Hubo un error al actualizar la actividad, por favor refresca la página.",
                    "error"
                  )
                },3000)
            );
          }}
          handleDragEnd={handleDragEndCard}
          onCardDelete={handleCardDelete}
          components={{
            AddCardLink: AddActivity,
            NewCardForm: (actionProps) => (
              <NewCard
                getAllDataAct={props.getAllDataAct}
                uid={props.uid}
                backlogUID={scrum.backlog.data.lanes[0].id}
                {...actionProps}
              />
            ),
            NewLaneSection: (actionProps) => (
              <AddSprintButton
                {...actionProps}
                disabled={props.filterSpsByStatus ? true : false}
              />
            ),
            NewLaneForm: (actionProps) => (
              <NewSprint uid={props.uid} getAllLanes={props.getAllLanes} lastSprint={props.allLanes} {...actionProps} />
            ),
            LaneHeader: (args) => (
              <LaneHeader {...args} onLaneHeaderClick={editSprint} getAllLanes={props.getAllLanes} />
          ),
           /*  Card: CardBacklog */
           Card : (actionProps) =>(
            <CardBacklog
            getAllDataAct={props.getAllDataAct}
            {...actionProps}
          />
           )
       
          }}
        />
      </StyledBoard>
      <ModalEditTask
        rol={rol}
        enableCost = {props.enableCost}
        hasDurationHours= {props.hasDurationHours}
        data-name="modal-edit-task"
        show={show}
        setShow={setShow}
        data={taskEdit}
        customFields= {customFields}
        titleColumn = {titleColumn}
        getAllDataAct={props.getAllDataAct}
        getAllprojectData={props.getAllprojectData}
        update={updateCardBoard}
        sprints={scrum.backlog.data.lanes.map((item) => {
          return {
            id: item.id,
            title: item.title
          };
        })}
        resources={resources}
        tags={tags}
        conditionManagerAdvisory ={props.conditionManagerAdvisory}
      />
      <ModalEditSprint
        data-name="modal-edit-sprint"
        show={showSprintDetails}
        setShow={setShowSprintDetails}
        data={sprintToEdit}
        update={updateSprintData}
      />
{/*       <ModalEditEpic
        setShow={setShowEpicModal}
        show={showEpicModal}
        data={taskEdit}
        setShowPbi={setShowEpicModalPBI}
        showPbi={showEpicModalPBI}
      />
      <ModalEditPBI
      setShow={setShowEpicModalPBI}
      show={showEpicModalPBI}
      data={taskEdit}
      />
 */}

  <ModalAvancedOptions
   setShow={setShowAvancedOptions}
   show={showAvancedOptions}
   handleClose = {handleShowAcvancedOptions}
   data={taskEdit}
  />
      <ReactHowler
        src={tasksound}
        xhr={{withCredentials:true}}
        playing={activeSoundCompletedTask}
        
      />
      
      {(activeAnimationCompleProj) && <>
        <ReactHowler
          src={projectSound}
          xhr={{withCredentials:true}}
          playing={activeSoundCompletedProject}
          
        />
        <ReactConfetti
          width={(windowDimen.width - 50)}
          height={windowDimen.height}
          tweenDuration={3000}
        /> 
       
      </>}
  
    </>
  );
};

export default ProductBacklog;
