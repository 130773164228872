const dataInitiative = [
    {
        Id: 123,
        NombreIniciativa: "Iniciativa 1",
        Descripcion: "Descripción de la Iniciativa 1",
        FechaPrevInicio: new Date("2022-01-01"),
        FechaCreacion: new Date(),
        CreadoPor: "Camilo Rivera",
        Estado: "En Inscripción",
        Observaciones: "Observaciones de la Iniciativa 1"
    },
    {
        Id: 231,
        NombreIniciativa: "Iniciativa 2",
        Descripcion: "Descripción de la Iniciativa 2",
        FechaPrevInicio: new Date("2022-02-01"),
        FechaCreacion: new Date(),
        CreadoPor: "Juan Ortiz",
        Estado: "En Inscripción",
        Observaciones: "Observaciones de la Iniciativa 2"
    },
    {
        Id: 345,
        NombreIniciativa: "Iniciativa 3",
        Descripcion: "Descripción de la Iniciativa 3",
        FechaPrevInicio: new Date("2022-03-01"),
        FechaCreacion: new Date(),
        CreadoPor: "Maria Garcia",
        Estado: "En Inscripción",
        Observaciones: "Observaciones de la Iniciativa 3"
    },
    {
        Id: 456,
        NombreIniciativa: "Iniciativa 4",
        Descripcion: "Descripción de la Iniciativa 4",
        FechaPrevInicio: new Date("2022-04-01"),
        FechaCreacion: new Date(),
        CreadoPor: "Pedro Pérez",
        Estado: "En Inscripción",
        Observaciones: "Observaciones de la Iniciativa 4"
    }
];

window.localStorage.setItem('dataInitiative', JSON.stringify(dataInitiative));

module.exports = {
    dataInitiative
};
