const dataObjetive =[
    {id: 1, NombreObjetivo:"Obejtivo 1", Descripcion: "Descripción 1", Estado:true, FechaCreacion:"16-12-2023", CreadoPor:"Elvis Presley"},
    {id: 2, NombreObjetivo:"Obejtivo 2", Descripcion: "Descripción 2", Estado:true, FechaCreacion:"16-20-2023", CreadoPor:"Alvaro Rojas"},
    {id: 3, NombreObjetivo:"Obejtivo 3", Descripcion: "Descripción 3", Estado:true, FechaCreacion:"16-25-2023", CreadoPor:"Leonel Messi"},
    {id: 4, NombreObjetivo:"Obejtivo 4", Descripcion: "Descripción 4", Estado:false, FechaCreacion:"16-05-2023", CreadoPor:"Levis Rolex"},
    {id: 5, NombreObjetivo:"Obejtivo 5", Descripcion: "Descripción 5", Estado:false, FechaCreacion:"16-15-2023", CreadoPor:"Cristian Garcia"},
];

window.localStorage.setItem('dataObjetive', JSON.stringify(dataObjetive));

module.exports ={
    dataObjetive
}