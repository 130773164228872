import React from "react";
import { Container, Row, Col, Nav, Card, ListGroup } from "react-bootstrap";
import photo from "../../../assets/diegoVera.png";

const Header = ({ currentView, setView, user }) => {
  return (
    <Container
      style={{ backgroundColor: "#db526a" }}
      fluid
      className="text-white p-3 mb-2"
    >
      <Row>
        <Col md={10}>
          <h1>{user.Nombre}</h1>
          <p>Cargo: {user.ProfesionCargo}</p>
          <p>Email: {user.Correo}</p>
        </Col>
        <Col md={2} className="d-none justify-content-center ">
          <Card
            style={{ backgroundColor: "#db526a", borderRadius: "100px" }}
            /* style={{ width: "18rem" }} */ className="p-3"
          >
            <Card.Img
              style={{ borderRadius: "70px" }}
              variant="top"
              sizes=""
              src={photo}
            />
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Nav
            variant="tabs"
            activeKey={currentView}
            onSelect={(selectedKey) => setView(selectedKey)}
          >
            <Nav.Item>
              <Nav.Link style={{ color: "black" }} eventKey="taskList">
                Proyectos
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link style={{ color: "black" }} eventKey="timeline">
                Ocupación
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
