import React, { useEffect, useMemo, useState } from "react";
import { PerformanceServices } from "./PerformanceServices";
import Swal from "sweetalert2";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Pagination,
  Row,
  Spinner,
} from "react-bootstrap";
import HvResource from "./HvResource";
import { useTranslation } from "react-i18next";
import ReactDatePicker from "react-datepicker";
import _, { get } from "lodash";
import { event } from "jquery";
import { formatDate } from "devextreme/localization";
import ResourcesGraph from "./ResourcesGraph";

const InitialState = {
  Idrol: "0",
  position: "",
  IdUser: "",
  start_date: null,
  end_date: null,
};

const DashboardResource = () => {
  const [t, i18n] = useTranslation("productivity");
  const [users, setUsers] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dataFilter, setDataFilter] = useState([]);
  const [loading, setLoading] = useState(false);
  //pagination
  const [filter, setFilter] = useState(InitialState);

  const [filteredUsers, setFilteredUsers] = useState(users);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterApply, setFilterApply] = useState([]);
  const [tasksByResources, setTasksByResources] = useState([]);

  const resourcesPerPage = 1;

  // Actualizar usuarios filtrados

  const loadDataByFilter = async (data) => {
    const controller = new AbortController();
    try {
      setLoading(true);
      const result = await PerformanceServices.getDataByFilter(data);

      setDataFilter(result.task);

      const resultFilter = users
        .map((user) => {
          const userTask = result.task.filter(
            (task) => task.IdUsuario == user.IdUsuario
          );
          return {
            ...user,
            task: userTask,
          };
        })
        .filter((us) => us.task.length > 0);

      setFilteredUsers(resultFilter);
      setFilterApply([result.filterApp]);
      setCurrentPage(1);
    } catch (error) {
      Swal.fire("Error", error, "error");
    } finally {
      setLoading(false);
    }

    return () => {
      controller.abort();
    };
  };

  /*   const filtered = useMemo(() => {
    return _.filter(
      users,
      (user) =>
        (!filter.Idrol || user.IdRol === filter.Idrol) &&
        (!filter.position || user.ProfesionCargo === filter.position) &&
        (!filter.IdUser || user.IdUsuario === filter.IdUser)
    );
  }, [users, filter]); */

  const handleFilter2 = (e) => {
    e.preventDefault();

    const matchObject = _.isEqual(filter, InitialState);

    if (!matchObject) {
      loadDataByFilter(filter);
    }
  };

  // Calcular paginación
  const totalPages = Math.ceil(filteredUsers.length / resourcesPerPage);
  const indexOfLastResource = currentPage * resourcesPerPage;
  const indexOfFirstResource = indexOfLastResource - resourcesPerPage;
  const currentResource = filteredUsers.slice(
    indexOfFirstResource,
    indexOfLastResource
  )[0];

  // Manejar cambio de página
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  //fin pagination

  const getUser = async () => {
    try {
      const result = await PerformanceServices.getUserByOrganization();
      setUsers(result);
    } catch (error) {
      Swal.fire("Error", error, "error");
    }
  };

  const getTaskByResource = async () => {
    try {
      const result = await PerformanceServices.getTasksByResource();

      setTasksByResources(result);
    } catch (error) {
      Swal.fire("Error", error, "error");
    }
  };

  useEffect(() => {
    getUser();
    getTaskByResource();
  }, []);

  const handleResetFilter = () => {
    setFilter(InitialState);
    setStartDate(null);
    setEndDate(null);
    setFilteredUsers([]);
  };

  const goal = _.filter(users, {
    IdRol: filter.Idrol,
    ProfesionCargo: filter.position,
  });

  const usersFilters = users.filter((us) => {
    const matchRol = filter.Idrol == "0" || us.IdRol == filter.Idrol;
    const matchPosition =
      filter.position === "" || us.ProfesionCargo === filter.position;
    const matchUser = filter.IdUser === "" || us.IdUsuario === filter.IdUser;
    return matchRol && matchPosition && matchUser;
  });

  const searchRol = (idRol) => {
    return users.filter((us) => us.IdRol == idRol)[0]?.Rol;
  };
  const searchName = (idUser) => {
    return users.filter((us) => us.IdUsuario == idUser)[0]?.Nombre;
  };

  const formaDate = (date) => {
    const dat = date.split("T")[0].split("-");
    return `${dat[2]}-${dat[1]}-${dat[0]}`;
  };

  const showFilters = (fil) => {
    if (fil.length > 0) {
      const rol = fil[0].IdRol != "0" ? `Rol: ${searchRol(fil[0].IdRol)}` : "";
      const pos = fil[0].position ? `Cargo: ${fil[0].position}` : "";
      const name = fil[0].IdUser ? `Recurso: ${searchName(fil[0].IdUser)}` : "";
      const start = fil[0].start ? `Desde: ${formaDate(fil[0].start)}` : "";
      const end = fil[0].end ? `Hasta:  ${formaDate(fil[0].end)}` : "";
      const icon = <i className="fa fa fa-arrow-right text-info" />;
      return (
        <div className="small">
          <b>Filtros aplicados :</b> {rol != "" ? <> {rol} </> : ""}{" "}
          {pos ? (
            <>
              {" "}
              {icon} {pos}{" "}
            </>
          ) : (
            ""
          )}{" "}
          {name ? (
            <>
              {icon} {name}
            </>
          ) : (
            ""
          )}{" "}
          {start ? (
            <>
              {icon} {start}
            </>
          ) : (
            ""
          )}{" "}
          {end ? (
            <>
              {icon} {end}
            </>
          ) : (
            ""
          )}
        </div>
      );
    } else {
      return "";
    }
  };

  return (
    <>
      <Container
        className="shadow-sm p-1 mb-3"
        style={{ maxWidth: "1400px", minHeight: "200px" }}
      >
        <ResourcesGraph users={users} tasksByResources={tasksByResources} />
      </Container>
      <Container
        className="shadow p-3"
        style={{ maxWidth: "1400px", minHeight: "200px" }}
      >
        <div>
          <Card>
            <Card.Body>
              <Card.Title>Resumen de Recursos</Card.Title>
              Cantidad de usuarios activos :{" "}
              {users?.filter((us) => us.Estado == "Activo").length}
            </Card.Body>
          </Card>
        </div>

        <Form onSubmit={handleFilter2}>
          <Card className="my-2 py-3 ">
            <div className="d-flex">
              <Col md={4} className="">
                <h6>Rol:</h6>
                <Form.Control
                  size="sm"
                  as="select"
                  required
                  value={filter.Idrol}
                  onChange={(e) => {
                    setFilter((prev) => {
                      return {
                        ...prev,
                        Idrol:
                          e.target.value != 0 ? Number(e.target.value) : "0",
                      };
                    });
                  }}
                >
                  <option value="0">Rol...</option>
                  {_.uniqBy(usersFilters, "Rol").map((user, idx) => {
                    return (
                      <option key={idx} value={user.IdRol}>
                        {user.Rol}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
              {/*  _.uniqBy(users, "Rol")? */}
              <Col md={4}>
                <h6>Cargo:</h6>
                <Form.Control
                  size="sm"
                  as="select"
                  value={filter.position}
                  //disabled={filter.Idrol == "0" ? true : false}
                  onChange={(e) => {
                    setFilter((prev) => {
                      return {
                        ...prev,
                        position: e.target.value != 0 ? e.target.value : "",
                      };
                    });
                  }}
                >
                  <option value="0">Cargo...</option>

                  {_.uniqBy(usersFilters, "ProfesionCargo").map((user, idx) => {
                    return (
                      <option key={idx} value={user.ProfesionCargo}>
                        {user.ProfesionCargo}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
              {/*    _.filter(_.uniqBy(users, "ProfesionCargo"), {
                    IdRol: filter.Idrol,
                  }) */}
              <Col md={4}>
                <h6>Recurso:</h6>
                <Form.Control
                  size="sm"
                  as="select"
                  value={filter.IdUser}
                  //disabled={!filter.position}
                  onChange={(e) => {
                    setFilter((prev) => ({
                      ...prev,
                      IdUser: e.target.value !== "0" ? e.target.value : "",
                    }));
                  }}
                >
                  <option value="0">Recursos...</option>

                  {usersFilters?.map((user, idx) => {
                    return (
                      <option key={idx} value={user.IdUsuario}>
                        {idx + 1}-{user.Nombre}
                      </option>
                    );
                  })}
                </Form.Control>
              </Col>
            </div>
            {/*  _.filter(users, {
                    IdRol: filter.Idrol, ProfesionCargo: filter.position}) */}
            <h6 className="h6 mx-3 my-3">
              {t("productivity_resources_searchdate_title")}
            </h6>{" "}
            <div className="d-flex align-items-center">
              <Col md={8} className="d-flex">
                <Col md={6} className="pl-0">
                  <ReactDatePicker
                    style={{ height: "32px" }}
                    selected={startDate}
                    dateFormat="dd/MM/yyyy"
                    filterDate={(date) =>
                      date.getDay() !== 6 && date.getDay() !== 0
                    }
                    wrapperClassName="w-100"
                    className="form-control"
                    required={endDate ? true : false}
                    /*   disabled={filter.Idrol == "0" ? true : false} */
                    placeholderText={t(
                      "productivity_resources_searchdate_from"
                    )}
                    name="startDate"
                    fixedHeight={false}
                    onChange={(date) => {
                      setStartDate(date);
                      console.log(date);

                      setFilter((prev) => {
                        return { ...prev, start_date: date };
                      });
                    }}
                  />
                </Col>
                <Col md={6}>
                  <ReactDatePicker
                    selected={endDate}
                    dateFormat="dd/MM/yyyy"
                    filterDate={(date) =>
                      date.getDay() !== 6 && date.getDay() !== 0
                    }
                    wrapperClassName="w-100"
                    className="form-control"
                    style={{ height: "32px" }}
                    placeholderText={t("productivity_resources_searchdate_to")}
                    name="endDate"
                    fixedHeight={false}
                    required={startDate ? true : false}
                    /* disabled={filter.Idrol == "0" ? true : false} */
                    minDate={startDate}
                    onChange={(date) => {
                      setEndDate(date);
                      /*  const dataFormt = date.toISOString().split("T")[0]; */
                      setFilter((prev) => {
                        return { ...prev, end_date: date };
                      });
                    }}
                  />
                </Col>
              </Col>
              <Col md={3}>
                <Button
                  variant="outline-primary"
                  className="mr-2"
                  type="submit"
                  size="sm"
                >
                  <i className="fa far fa-search" />
                </Button>
                <Button
                  onClick={handleResetFilter}
                  variant="outline-info"
                  size="sm"
                >
                  <i className="fa far fa-undo" />
                </Button>
              </Col>
            </div>
          </Card>
        </Form>

        <div>
          <Col className="p-0">
            {loading ? (
              <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="info" role="status" />
              </div>
            ) : (
              <Card className="p-4">
                {currentResource ? (
                  <>
                    {" "}
                    <h5>{showFilters(filterApply)}</h5>
                    <h6>
                      Se han encontrado {filteredUsers?.length} coincidencias
                      con sus filtros...{" "}
                    </h6>
                    <HvResource
                      user={currentResource}
                      dataFilter={dataFilter}
                    />
                  </>
                ) : (
                  <>
                    No hay recursos disponibles con los filtros seleccionados.
                  </>
                )}
              </Card>
            )}
            {/* Paginación */}
            {totalPages > 1 && (
              <Pagination
                style={{ overflow: "auto" }}
                className="justify-content-center mt-3"
              >
                <Pagination.First
                  onClick={() => handlePageChange(1)}
                  disabled={currentPage === 1}
                />
                <Pagination.Prev
                  onClick={() => handlePageChange(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: totalPages }, (_, idx) => (
                  <Pagination.Item
                    key={idx + 1}
                    active={currentPage === idx + 1}
                    activeLabel=""
                    onClick={() => handlePageChange(idx + 1)}
                  >
                    {idx + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => handlePageChange(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
                <Pagination.Last
                  onClick={() => handlePageChange(totalPages)}
                  disabled={currentPage === totalPages}
                />
              </Pagination>
            )}
          </Col>
        </div>
      </Container>
    </>
  );
};

export default DashboardResource;
