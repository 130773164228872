import React from 'react';
import { useState } from 'react';
import { Chrono } from 'react-chrono';
import { API_ROUTE, headersRequest } from '../../helpers';
import axios from 'axios';
import { useEffect } from 'react';

const data = [
  {
    title: "2023-01-10",
    cardTitle: "En Inscripcion",
    cardDetailedText: "Fecha fin: 2023-01-30",
    fechaInicio: "Fecha de inicio:2023-01-10",
    fechaFin :"Fecha fin: 2023-01-30"
  },
  {
    title: "2023-02-01",
    cardTitle: "En estructuracion",
    cardDetailedText: "Fecha fin: 2023-03-15",
    fechaInicio:"Fecha de inicio:2023-02-01",
    fechaFin :"Fecha fin: 2023-03-15"
  },
  {
    title: "2023-04-01",
    cardTitle: "En evaluacion",
    cardDetailedText: "Fecha fin: 2023-06-30",
    fechaInicio:"Fecha de inicio:2023-04-01",
    fechaFin : null
  },
  {
    title: "2023-07-10",
    cardTitle: "Aprobada",
  /*   cardSubtitle: "Fecha de inicio:2023-07-01", */
    cardDetailedText: "Fecha fin: 2023-08-15",
    fechaFin :  "Fecha fin: 2023-08-15",
    fechaInicio:"Fecha de inicio:2023-07-01", 
  }
];

const TimelineMGI = () => {
  const [dataTimeLine, setDataTimeLine] = useState([])

  const getTimeLineData = async()=>{ 
    try {
        const res = await axios.get(`${API_ROUTE}""`, headersRequest())
        console.log(res.data)
        setDataTimeLine(res.data)
    } catch (error) {
      
    }
  }
  useEffect(()=>{
    // getTimeLineData();
  },[])


    return (
        <>
        <div className='d-flex flex-column align-items-center' style={{width:"80%", margin:"0 auto"}}>
        <h5>Etapas:</h5>
        <div style={{width:"100%"}}>
        <Chrono
          items={data}
          mode="VERTICAL"
          showAllCardsHorizontal={true}
          timelinePointDimension ={45}
          toolbarPosition="BOTTOM"
          borderLessCards={true}
          // itemWidth={150}
          cardHeight={150}
          //cardWidth={350}
          hideControls={true}
          theme={{primary:"#db526a", secondary:"#efb21e"
          }}
        >
          {data?.map((it,idx)=>{
            return <div key={idx} className={`${it.fechaFin ? "card-timeline-initiative_doing": "card-timeline-initiative_done"}`}>
              <p>{it.fechaInicio}</p>
              <p>{it.fechaFin != null ? it.fechaFin : "Sin fecha de finalización"}</p>
            </div>
          })}
        </Chrono>
        </div>
        </div>
        </>
      );
}

export default TimelineMGI