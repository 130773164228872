import React, { useState, useEffect, Suspense, lazy } from "react";
import { Tab, Row, Col, Nav, Container, Button } from "react-bootstrap";
import TitleCommon from "../TitleCommon";
import { StyledTabs } from "../StyledCommon";
import { selectTooltip, selectTooltip_en } from "../../utils/selectTooltip";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";
import { useHistory, useRouteMatch, useParams } from "react-router-dom";
import ParametersCriterios from "./Parameters";
import Structuring from './Structuring';
import axios from "axios";
import { API_ROUTE, headersRequest } from "../../helpers";
import ConfigStrategy from "./ConfigStrategy";
const StrategicObjetives = lazy(() => import('./StrategicObjetives'));

const Strategy = () => {
//   const [t, i18n] = useTranslation("initiatives");
  const [selectedTab, setSelectedTab] = useState("zero");
  const [save, setSave] = useState([]);
  const [initiatives, setInitiatives] = useState([])
   const [t, i18n] = useTranslation("initiatives");
  const { idView } = useParams();
  let history = useHistory();
  let { path } = useRouteMatch();
  const [update, setUpdate] = useState(true);

  useEffect(() => {
    ReactTooltip.rebuild();
  });

//   const goToStructuring = (data) => {
//     setSelectedTab("three");
//     localStorage.setItem('savePage', 'three');
//   };

  let title_i18 = t("iniciativas_title");

  const savePage = (value) => {
    localStorage.setItem('savePage', value);
  };

  const currentPage = localStorage.getItem("savePage") || "zero";

  const change = window.localStorage.getItem("rol");
  const { idViewConfig } = useParams();
  console.log(idViewConfig);


  return (
    <React.Fragment>
      {/* <Layout save={save}> */}
        {/* <TitleCommon
          title={{
            text: title_i18,
            icon: "lightbulb"
          }}
        /> */}
        <Container fluid>
          <Tab.Container
            id="left-tabs-example"
             //defaultActiveKey= {(idView) ? idView : currentPage}
             //defaultActiveKey={idViewConfig ? idViewConfig : currentPage}
            activeKey={idViewConfig ? idViewConfig : selectedTab}
            transition={false}
            onSelect={(e) => setSelectedTab(e)}
          >
            <Row className="py-2">
              <Col>
                <StyledTabs as={Nav}>
                  <Nav.Item  className="px-3" data-tip={(t("iniciativas_lenguaje") === "es") ? "Acá puedes registrar tus Objetivos Estratégicos" : "You can register your Strategic Objectives"}>
                    <Nav.Link as={Button} className="btn-sm" variant="outline-secondary" eventKey="zero" onClick={() => savePage("zero")}>
                      {/*t("iniciativas_button_objetivos")*/}
                      Obj. Estratégicos
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="px-3" data-tip={(t("iniciativas_lenguaje") === "es") ? "Acá puedes registrar tus Parámetros / Criterios de priorización" : "You can register Parameters / Prioritization cParameters / Prioritization Criteria"}>
                    <Nav.Link as={Button} className="btn-sm" variant="outline-secondary" eventKey="one" onClick={() => savePage("one")}>
                      {/*t("iniciativas_button_inscripcion")*/}
                      Parámetros / Criterios
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="px-3" data-tip={(t("iniciativas_lenguaje") === "es") ? "Una vez registrados los Parámetros y Objetivos Estratégicos, ahora vamos a configurarlos" : "Once the Parameters and Strategic Objectives have been registered, now we are going to configure them"}>
                    <Nav.Link as={Button} className="btn-sm" variant="outline-secondary" eventKey="two" onClick={() => savePage("two")}>
                      {/*t("iniciativas_button_estructuracion")*/}
                      Planeación Estratégica
                    </Nav.Link>
                  </Nav.Item>
                </StyledTabs>
              </Col>
            </Row>
            <hr color="#db526a"/>
            <Row>
              <Col className="pt-3 pb-5 mb-5">
                <Tab.Content>
                  <Tab.Pane
                    eventKey="zero"
                    className="animate__animated animate__fadeIn"
                  >
                  <Suspense fallback={<div>Loading...</div>}>
                      <StrategicObjetives 
                      update={update}
                      setUpdate={setUpdate}
                      />
                    </Suspense>

                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="one"
                    className="animate__animated animate__fadeIn"
                  >
                    <ParametersCriterios
                     update={update}
                     setUpdate={setUpdate}
                    />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey="two"
                    className="animate__animated animate__fadeIn"
                  >
                   <ConfigStrategy
                   update={update}
                   />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Container>
      {/* </Layout> */}
    </React.Fragment>
  );
};

export default Strategy;
