import React, { useEffect, useState } from 'react';
import { Card, Col, ProgressBar, Row } from 'react-bootstrap';

const InitiativesProgress = ({iniciativas , onFilter}) =>{
  const [stateCounts, setStateCounts] = useState({});

  // Agrupo las iniciativas por su estado pa' contar cuántas hay en c/u
  useEffect(() => {
    const counts = iniciativas.reduce((acc, iniciativa) => {
      const estado = iniciativa.Estado;
      acc[estado] = (acc[estado] || 0) + 1;
      return acc;
    }, {});

    setStateCounts(counts);
  }, [iniciativas]);

  const totalProjects = iniciativas.length;

const getClassByState = (estado) => {
  switch (estado) {
      case 'En Inscripción':
          return 'card-mgi-register';
      case 'En Calificación':
          return 'card-mgi-rating';
      case 'En Evaluación':
          return 'card-mgi-evaluation';
      case 'Aprobada':
          return 'card-mgi-approve'; 
      case 'En Estructuración':
          return 'card-mgi-structuring';    
      default:
          return '';
  };
};

  return (
    <>
      <Col lg={3} md={6} sm={12}>
        <Card 
          className="my-3 border-primary card-dashboard-mgi card-mgi-initiatives"
          onClick={()=> onFilter(null)}
          style={{minHeight:'144px'}}
        >
          <Card.Body
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div className="d-flex align-items-center">
              <i className="fas fa-lightbulb dashboard-icons mr-2"></i>
              <span className="h4 mb-0">Total de Iniciativas</span>
            </div>
            <h2 className="my-0 text-center">
              {totalProjects}
            </h2>
          </Card.Body>
        </Card>
      </Col>
    {Object.entries(stateCounts).map(([estado, count]) => {
      const progress = (count / totalProjects) * 100;
      return (
        <Col lg={3} md={6} sm={12} key={estado}>
          <Card 
            className={`my-3 border-primary card-dashboard-mgi ${getClassByState(estado)}`}
            onClick={()=> onFilter(estado)}  
          >
            <Card.Body>
              <h2 className="my-0 text-center">
                {count}
              </h2>
              <div className="d-flex justify-content-center align-items-center">
                {/* <i className="fa fa-star dashboard-icons mr-2"></i> */}
                <span className="h5 mb-0">{estado}</span>
              </div>
              <ProgressBar 
                now={progress} 
                label={`${progress.toFixed(2)}%`} 
                className='mt-3 border-primary'
                style={{maxWidth:'90%', height:'25px', margin:'0 auto', border: '1px solid rgba(0, 0, 0, 0.2)'}}
              />
            </Card.Body>
          </Card>
        </Col>
      );
    })}
    </>
  );
};

export default InitiativesProgress