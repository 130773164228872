import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.light.css";
import React, {useEffect } from "react";
import Users from "./views/Users";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation
} from "react-router-dom";
import "./css/custom.css";
import Projects from "./views/Projects";
import Boards from "./views/Boards";
import EditProject from "./views/EditProject";
import PlanningBoard from "./views/PlanningBoard";
import HvProject from "./views/HvProject";
import Login from "./views/Login";
import HvAgile from "./views/HvAgile";
import Tasks from "./views/Tasks";
import Initiatives from "./views/Initiatives";
import StructuringInitiative from "./components/Initiatives/Structuring";
import InitiativesBudge from "./components/Initiatives/InitiativesBudge";
import DashboardDetailView from "./components/Initiatives/DashboardDetailView";
import Tags from "./views/Tags";
import Productivity from "./views/Productivity";
/* import { Component } from "react"; */
import EditTemplate from "./views/EditTemplate";
import SprintBoard from "./views/SprintBoard";
import ScheduleTraditional from "./views/ScheduleTraditional";
import KanbanBoard from "./views/KanbanBoard";
import Helps from "./views/Helps";
import Landing from "./views/Landing";
import TraditionalFiles from "./views/TraditionalFiles";
import ReactGA from "react-ga4"
import ModuleRisks from "./views/ModuleRisks";
import CreateForm from "./components/ModuleRisk/CreateForm";
import GanttTest from "./components/GanttDx/GanttTest";
import { licenseKey } from "./devextreme-license";
import config from "devextreme/core/config";

import Assessment from "./components/Initiatives/Assessment";

config({
  licenseKey
})

/* import WelcomeModal from "./components/WelcomeModal"; */

const PrivateRoute = ({ component: Component, ...rest }) => {
  const auth = localStorage.getItem("tokenuserid");
  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? <Component {...props} /> : <Redirect to={{ pathname: "/" }} />
      }
    />
  );
};

const IsLogged = ({ component: Component, ...rest }) => {
  const auth = localStorage.getItem("tokenuserid");
  return (
    <Route
      {...rest}
      render={(props) =>
        auth ? (
          <Redirect to={{ pathname: "/tasks" }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};


const usePageViews = () => {
  let location = useLocation();
  
  useEffect(()=> {
    if(!window.GA_INITIALIZED){
       ReactGA.initialize("G-P63XMR3JJK")
       window.GA_INITIALIZED = true
    }
    let titlePageName = location.pathname?.split('/')
    let titlePage = titlePageName[1]
    ReactGA.set({page: location.pathname})
    ReactGA.send({hitType:"pageview", page : location.pathname, title:titlePage})

  },[location])
}

function App() {
  usePageViews()
  return (
    <div className="App">
    {/*   <Router> */}
        <Switch>
          <PrivateRoute path="/resources" component={Users} />
          <PrivateRoute path="/projects/:idView?" component={Projects} />
          <PrivateRoute path="/landing" component={Landing} />
          <PrivateRoute path="/boards" component={Boards} />
          <PrivateRoute path="/project/:uid" component={EditProject} />
          <PrivateRoute path="/project" component={EditProject} />
          <PrivateRoute path="/template/:uid" component={EditTemplate} />
          <PrivateRoute path="/template" component={EditTemplate} />
          <PrivateRoute path="/schedule/:uid" component={ScheduleTraditional} />
          <PrivateRoute path="/risks" component={ModuleRisks} />
          <PrivateRoute path="/risk-create-form/:projectId/:action" component={CreateForm} />
          <PrivateRoute path="/planning-board/:uid" component={PlanningBoard} />
          <PrivateRoute path="/sprint-board/:uid" component={SprintBoard} />
          <PrivateRoute path="/kanban-board/:projectId/:sprintId" component={KanbanBoard} />
          <PrivateRoute path="/hv-project/:uid" component={HvProject} />
          <PrivateRoute path="/files-project/:uid" component={TraditionalFiles} />
          <PrivateRoute path="/hv-agile/:uid" component={HvAgile} />
          <PrivateRoute path="/tasks" component={Tasks} />
          <PrivateRoute path="/initiatives/" component={Initiatives} />
          <PrivateRoute path="/initiative/:idInitiative" component={StructuringInitiative} />
          <PrivateRoute path="/configstrategy/:idViewConfig?" component={Initiatives} />
          <PrivateRoute path="/initiativebudge/:idViewConfig?" component={InitiativesBudge} />
          <PrivateRoute path="/DetailView/:idInitiative?" component={DashboardDetailView} />
          <PrivateRoute path="/InitiativesDetails/:idInitiative?" component={DashboardDetailView} />
          <PrivateRoute path="/InitiativeAssessment/:idInitiative?" component={Assessment} />
          <PrivateRoute path="/tags/:uid" component={Tags} />
          <PrivateRoute path="/productivity" component={Productivity} />
          <PrivateRoute path="/gantt" component={GanttTest} />
          <PrivateRoute path="/helps" component={Helps} />
          <IsLogged path="/" component={Login} />
          {/* <Route path="/">
            <Login />
          </Route> */}
        </Switch>
     {/*  </Router> */}
    </div>
  );
}

export default App;
