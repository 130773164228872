import React from "react";
import { Col, Row } from "react-bootstrap";
import { PieChart } from "devextreme-react";
import PieGraph from "./components/PieGraph";
import CharBarForPosition from "./components/CharBarForPosition";

const ResourcesGraph = ({ users, tasksByResources }) => {
  return (
    <div>
      <Row>
        <Col style={{ maxHeight: "350px" }} md={4}>
          <PieGraph
            type="doughnut"
            title="Cantidad de Recursos por Rol"
            data={users}
            series="region"
          />
        </Col>
        <Col md={8}>
          <CharBarForPosition
            type="position"
            data={users}
            title="Cantidad de Recursos por Cargo"
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col style={{ maxHeight: "350px" }} md={4}>
          <PieGraph
            type="pie"
            title="Usuarios con Actividades"
            data={users}
            series="region"
          />
        </Col>
        <Col md={8}>
          <CharBarForPosition
            type="tasks"
            data={tasksByResources}
            title="Top 10 Cantidad de Actividades por Recurso"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ResourcesGraph;
